import { Card, CardHeader, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { ClusterTableRow } from './ClusterTableRow';
import { useGetClustersVersionsQuery } from '../../../services/clustersApi';
import { Cluster } from '../../../utils/cluster-utils';
import { CardTitleWithLoadingIndicator } from '../../Common/CardTitleWithLoadingIndicator';
import { Scrollbar } from '../../Common/Scrollbar';

type ClusterListTableProps = {
  clusters: Cluster[];
  updatingList: boolean;
  title: string;
  actions?: React.ReactNode;
  linkToEnv?: boolean;
};

export const ClustersListTable = ({
  clusters,
  updatingList,
  title,
  actions,
  linkToEnv = true,
}: ClusterListTableProps) => {
  let latestVersion = 'latest';
  const { data, error, isLoading } = useGetClustersVersionsQuery({ kind: 'latest' });
  if (!isLoading && !error && data) {
    latestVersion = data[0].version;
  }
  return (
    <Card>
      <CardHeader
        action={actions}
        title={<CardTitleWithLoadingIndicator title={title} isLoading={updatingList} />}
        id="active-clusters-title"
      />
      <Scrollbar>
        <Table aria-labelledby="active-clusters-title">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell width={'25%'}>Cluster</TableCell>
              <TableCell>Configuration</TableCell>
              <TableCell>Provider</TableCell>
              <TableCell sx={{ minWidth: 200 }}>Status</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clusters.map((cluster) => (
              <ClusterTableRow key={cluster.id} cluster={cluster} linkToEnv={linkToEnv} latestVersion={latestVersion} />
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </Card>
  );
};
