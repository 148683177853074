// eslint-disable-next-line no-restricted-imports
import { useTheme } from '@mui/material';
import { ComponentProps, ReactNode } from 'react';
import { Link } from '../../router/Link';

export const CloudLink = (
  props: {
    children: ReactNode;
    color?: string;
    target?: '_blank';
  } & ComponentProps<typeof Link> /* this is a workaround to allow passing props to the Link component */,
) => {
  const theme = useTheme();

  return (
    <Link
      style={{ color: theme.palette.text.primary, textDecoration: 'underline' }}
      to="/"
      role={undefined}
      // {...props} are left to the end for overriding purposes.
      {...props}
    />
  );
};
