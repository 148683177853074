import { useCallback } from 'react';
import { BASE_URL, WEB_STORAGE_PREFIX } from '../../../utils/constants';
import { attempt } from '../../../utils/func-utils';
import redirectUrls from '../../../utils/marketplace-urls.json';
import { PaymentProviderName } from '../PaymentProviders';

export const MARKETPLACE_RETURN_TO_STORAGE_KEY = `${WEB_STORAGE_PREFIX}marketplaceReturnTo`;

export const PAYMENT_INFO_SECTION_ELEMENT_ID = 'payment-info-section';

export const useCreateMarketplaceRedirect = () =>
  useCallback((provider: Exclude<PaymentProviderName, 'stripe'>) => {
    attempt(() => {
      const returnTo = window.location.href.replace(BASE_URL, '');
      window.sessionStorage.setItem(MARKETPLACE_RETURN_TO_STORAGE_KEY, returnTo);
    });
    window.location.assign(redirectUrls[provider]);
  }, []);
