import CreditCardIcon from '@mui/icons-material/CreditCard';
import { Grid, Skeleton, Typography } from '@mui/material';
import capitalize from 'lodash/capitalize';
import { PaymentConnectedStripeActions } from './PaymentConnectedStripeActions';
import { PaymentProvidersMarketplace } from './PaymentProviders';
import { useGetPaymentMethodQuery } from '../../services/paymentApi';
import { ASTERISK_OPERATOR } from '../../utils/constants';
import { ErrorBox } from '../Common/ErrorBox';

export const PaymentConnectedStripe = ({
  accountId,
  paymentInformationId,
}: {
  accountId: string;
  paymentInformationId: string;
}) => {
  const { data, isLoading, isError, refetch } = useGetPaymentMethodQuery({ accountId });

  if (isLoading) {
    return <Skeleton variant="rounded" height={52} />;
  }
  if (isError) {
    return <ErrorBox msg="There was a problem loading your payment method." retry={refetch} />;
  }
  if (!data) {
    return null;
  }
  return (
    <Grid container={true} spacing={4} rowSpacing={3} alignItems="center">
      <Grid item={true}>
        <PaymentProvidersMarketplace provider="stripe" />
      </Grid>
      <Grid item={true} data-sentry-mask={true}>
        <Grid container={true} spacing={2} alignItems="center">
          <Grid item={true}>
            <CreditCardIcon fontSize="medium" color="primary" sx={{ display: 'block' }} />
          </Grid>
          <Grid item={true}>
            <Typography variant="body2">{capitalize(data.card.brand)}</Typography>
          </Grid>
          <Grid item={true}>
            <Typography variant="body2" sx={{ whiteSpace: 'pre' }}>
              {`${ASTERISK_OPERATOR.repeat(4)} `.repeat(3)} {data.card.last4}
            </Typography>
          </Grid>
          <Grid item={true}>
            <Typography variant="body2">{`Expires: ${data.card.exp_month} / ${data.card.exp_year}`}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true}>
        <PaymentConnectedStripeActions accountId={accountId} paymentInformationId={paymentInformationId} />
      </Grid>
    </Grid>
  );
};
