import { Code } from '@mui/icons-material';
import { Button, Card, Dialog, IconButton, Typography } from '@mui/material';
import { ReactNode, createElement, useState } from 'react';
import { Cluster } from '../../utils/cluster-utils';
import { ClusterQueryCodeExample } from '../Clusters/ClusterQueryCodeExample';

const BUTTON_TEXT = 'Show code example';

export const CodeSnippetButton = ({
  cluster,
  type = 'span',
  children = BUTTON_TEXT,
  ...other
}: {
  cluster: Cluster;
  type?: keyof JSX.IntrinsicElements | 'icon' | 'text';
  children?: ReactNode;
  px?: number;
}) => {
  const [codeExampleOpen, setCodeExampleOpen] = useState(false);

  return (
    <>
      {type === 'button' && (
        <Button
          variant="contained"
          title={BUTTON_TEXT}
          endIcon={<Code fontSize="small" />}
          onClick={() => {
            setCodeExampleOpen(true);
          }}
          role="button"
          {...other}
        >
          {BUTTON_TEXT}
        </Button>
      )}

      {type === 'icon' && (
        // @ts-expect-error variant does not exists on IconButton
        <IconButton
          variant="contained"
          title={BUTTON_TEXT}
          onClick={() => {
            setCodeExampleOpen(true);
          }}
          role="button"
          {...other}
        >
          <Code fontSize="small" />
        </IconButton>
      )}

      {type === 'text' && (
        <Typography
          variant="body2"
          title={BUTTON_TEXT}
          component="span"
          onClick={() => {
            setCodeExampleOpen(true);
          }}
          sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
          role="button"
          {...other}
        >
          {BUTTON_TEXT}
        </Typography>
      )}
      {type !== 'button' &&
        type !== 'icon' &&
        type !== 'text' &&
        createElement(
          type,
          {
            title: BUTTON_TEXT,
            onClick: () => {
              setCodeExampleOpen(true);
            },
            ...other,
          },
          children,
        )}
      <Dialog
        onClose={() => {
          setCodeExampleOpen(false);
        }}
        fullWidth={true}
        maxWidth={'lg'}
        open={codeExampleOpen}
      >
        <Card>
          <ClusterQueryCodeExample clusters={[cluster]} />
        </Card>
      </Dialog>
    </>
  );
};
