import { Box, Card, CardHeader } from '@mui/material';
import { PageFrame } from '../Authenticated/PageFrame';

export const NotFound = () => (
  <PageFrame>
    <Box pt={10} display="flex" justifyContent="center">
      <Card variant="outlined">
        <CardHeader title="Sorry we couldn't find what you were looking for" />
      </Card>
    </Box>
  </PageFrame>
);
