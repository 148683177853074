import hoistNonReactStatics from 'hoist-non-react-statics';
import { Component, ComponentType, ErrorInfo, FunctionComponent, ReactElement, ReactNode, isValidElement } from 'react';
import { attempt } from '../../../utils/func-utils';

export const UNKNOWN_COMPONENT = 'unknown';

export type FallbackRender = (errorData: { error: Error; componentStack: string; resetError(): void }) => ReactElement;

export type ErrorBoundaryProps = {
  children?: ReactNode | (() => ReactNode);
  fallback?: ReactElement | FallbackRender;
  /** Called when the error boundary encounters an error */
  onError?(error: Error, componentStack: string): void;
  /** Called on componentDidMount() */
  onMount?(): void;
  /** Called if resetError() is called from the fallback render props function  */
  onReset?(error: Error | null, componentStack: string | null): void;
  /** Called on componentWillUnmount() */
  onUnmount?(error: Error | null, componentStack: string | null): void;
  /** Called before the error is captured by Sentry, allows for you to add tags or context using the scope */
  beforeCapture?(error: Error | null, componentStack: string | null): void;
};

type ErrorBoundaryState =
  | {
      componentStack: null;
      error: null;
    }
  | {
      componentStack: ErrorInfo['componentStack'];
      error: Error;
    };

const INITIAL_STATE = {
  componentStack: null,
  error: null,
};

function isErrorLike(obj: unknown): obj is Error {
  switch (Object.prototype.toString.call(obj)) {
    case '[object Error]':
    case '[object Exception]':
    case '[object DOMException]':
      return true;
    default:
      return attempt(
        () => obj instanceof Error,
        () => false,
      );
  }
}
function setCause(error: Error, cause: Error): void {
  const seenErrors = new WeakMap<Error, boolean>();

  function recurse(error: Error, cause: Error): void {
    // If we've already seen the error, there is a recursive loop somewhere in the error's
    // cause chain. Let's just bail out then to prevent a stack overflow.
    if (seenErrors.has(error)) {
      return;
    }
    if (error.cause) {
      seenErrors.set(error, true);
      return recurse(error.cause as Error, cause);
    }
    error.cause = cause;
  }

  recurse(error, cause);
}

/**
 * A ErrorBoundary component that logs errors to Sentry. Requires React >= 16.
 * NOTE: If you are a Sentry user, and you are seeing this stack frame, it means the
 * Sentry React SDK ErrorBoundary caught an error invoking your application code. This
 * is expected behavior and NOT indicative of a bug with the Sentry React SDK.
 */
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public override state: ErrorBoundaryState = INITIAL_STATE;

  public override componentDidCatch(error: Error & { cause?: Error }, { componentStack }: ErrorInfo): void {
    const { beforeCapture, onError } = this.props;
    // Although `componentDidCatch` is typed to accept an `Error` object, it can also be invoked
    // with non-error objects. This is why we need to check if the error is an error-like object.
    // See: https://github.com/getsentry/sentry-javascript/issues/6167
    if (isErrorLike(error)) {
      const errorBoundaryError = new Error(error.message);
      errorBoundaryError.name = `React ErrorBoundary ${error.name}`;
      errorBoundaryError.stack = componentStack ?? '';

      // Using the `LinkedErrors` integration to link the errors together.
      setCause(error, errorBoundaryError);
    }
    if (beforeCapture) {
      beforeCapture(error, componentStack ?? null);
    }
    if (onError && componentStack) {
      onError(error, componentStack);
    }
    // componentDidCatch is used over getDerivedStateFromError
    // so that componentStack is accessible through state.
    this.setState({ error, componentStack });
  }

  public override componentDidMount(): void {
    const { onMount } = this.props;
    if (onMount) {
      onMount();
    }
  }

  public override componentWillUnmount(): void {
    const { error, componentStack } = this.state;
    const { onUnmount } = this.props;
    if (onUnmount) {
      onUnmount(error, componentStack ?? null);
    }
  }

  public resetErrorBoundary: () => void = () => {
    const { onReset } = this.props;
    const { error, componentStack } = this.state;
    if (onReset) {
      onReset(error, componentStack ?? null);
    }
    this.setState(INITIAL_STATE);
  };

  public override render(): ReactNode {
    const { fallback, children } = this.props;
    const state = this.state;
    if (state.error) {
      let element: ReactElement | undefined = undefined;
      if (typeof fallback === 'function') {
        element = fallback({
          error: state.error,
          componentStack: state.componentStack ?? '',
          resetError: this.resetErrorBoundary,
        });
      } else {
        element = fallback;
      }
      if (isValidElement(element)) {
        return element;
      }
      // Fail gracefully if no fallback provided or is not valid
      return null;
    }
    if (typeof children === 'function') {
      return children();
    }
    return children;
  }
}

function withErrorBoundary<P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>,
  errorBoundaryOptions: ErrorBoundaryProps,
): FunctionComponent<P> {
  const Wrapped: FunctionComponent<P> = (props: P) => (
    <ErrorBoundary {...errorBoundaryOptions}>
      <WrappedComponent {...props} />
    </ErrorBoundary>
  );
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const componentDisplayName = WrappedComponent.displayName || WrappedComponent.name || UNKNOWN_COMPONENT;
  Wrapped.displayName = `errorBoundary(${componentDisplayName})`;
  // Copy over static methods from Wrapped component to Profiler HOC
  // See: https://reactjs.org/docs/higher-order-components.html#static-methods-must-be-copied-over
  hoistNonReactStatics(Wrapped, WrappedComponent);
  return Wrapped;
}

export { ErrorBoundary, withErrorBoundary };
