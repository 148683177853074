import { Container, Typography } from '@mui/material';

export function FeatureUnderMaintenance() {
  return (
    <Container maxWidth="lg">
      <Typography align="center" variant="h6">
        Down for maintenance
      </Typography>
      <Typography align="center" color="textSecondary" sx={{ mt: 0.5 }} variant="subtitle2">
        This feature is under maintenance, we apologize for the inconvenience. Please check back later.
      </Typography>
    </Container>
  );
}
