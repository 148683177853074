import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useSnackbar } from '../../hooks/use-qdrant-snackbar';
import { isFetchMutationError } from '../../services/helpers';
import { useCreateProgrammaticAccessKeyMutation } from '../../services/iamApi';
import { getUserMessage } from '../../utils/errorHandlingUtils';
import { CopyToClipboardText } from '../Common/CopyToClipboardText';

type Props = {
  accountId: string;
  onClose: () => void;
  open: boolean;
};

export const CreateProgrammaticAccessKeyDialog = ({ accountId, onClose, open }: Props) => {
  const [createProgrammaticAccessKey, { isLoading: isCreatingKey, data }] = useCreateProgrammaticAccessKeyMutation();
  const apiKey = data?.token;
  const { enqueueSnackbar } = useSnackbar();

  const handleCreateKey = useCallback(
    async (accountId: string) => {
      const result = await createProgrammaticAccessKey({ account_id: accountId }).unwrap();
      if (isFetchMutationError(result)) {
        enqueueSnackbar(getUserMessage(result.error), { variant: 'error' });
      }
    },
    [createProgrammaticAccessKey, enqueueSnackbar],
  );

  useEffect(() => {
    if (open) {
      void handleCreateKey(accountId);
    }
  }, [accountId, handleCreateKey, open]);

  const handleClose = () => {
    if (!isCreatingKey) {
      onClose();
    }
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="lg">
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h4" color={(theme) => theme.palette.secondary.main} mt={2}>
            Your Qdrant Cloud API Key
          </Typography>
          <IconButton onClick={handleClose} color="error">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Box pl={1} pt={2} mb={3}>
            <Typography component="code" variant="body2">
              This key gives you access to Qdrant Public API, so please make sure to store it safely.
            </Typography>
          </Box>
          <Alert variant="outlined" severity="error">
            <Typography variant="body2">
              This token can't be accessed any more in full length after closing this dialog
            </Typography>
          </Alert>
          <Box pb={1} mt={3}>
            <Box pl={1} width="100%" p={1} display="flex">
              {apiKey && <CopyToClipboardText text={apiKey} useCodeStyle={true} />}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
