import { Suspense, lazy } from 'react';

const LazyComponent = lazy(() =>
  import(/* webpackChunkName: "billing-cycles" */ './BillingCycles').then(({ BillingCycles }) => ({
    default: BillingCycles,
  })),
);

export const LazyBillingCycles = ({ accountId }: { accountId: string }) => (
  <Suspense>
    <LazyComponent accountId={accountId} />
  </Suspense>
);
