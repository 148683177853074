import { Box, Typography } from '@mui/material';
import { useNavigate, createRoute } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import { Route as AccountRootRoute } from './_account';
import { AuthenticatedDashboard } from '../components/Authenticated/AuthenticatedDashboard';
import { PageFrame } from '../components/Authenticated/PageFrame';
import { NewsItemList } from '../components/News/NewsItemList';
import { HybridCloudBanner } from '../components/Overview/HybridCloudBanner';
import { OverviewBanner } from '../components/Overview/OverviewBanner';
import { OverviewClustersWidget } from '../components/Overview/OverviewClustersWidget';
// eslint-disable-next-line max-len
import { OverviewWelcomeStepper as OverviewWelcomeStepperHorizontal } from '../components/Overview/WelcomeStepper/Horizontal/OverviewWelcomeStepper';
// eslint-disable-next-line max-len
import { WelcomeStepperProvider } from '../components/Overview/WelcomeStepper/Horizontal/WelcomeStepperProvider';
import { OverviewWelcomeStepper } from '../components/Overview/WelcomeStepper/OverviewWelcomeStepper';
import { PendingRequests } from '../components/Profile/PendingRequests';
import { useAuthenticatedData } from '../contexts/authenticated-data-context';
import { Settings } from '../contexts/settings-context';
import { useFeatureFlags } from '../hooks/use-feature-flags';
import { useSettings } from '../hooks/use-settings';
import { useAuth } from '../router/utils';
import { useGetClustersByAccountIdQuery } from '../services/clustersApi';
import { ApiKeys, useGetApiKeysByAccountIdQuery } from '../services/iamApi';
import { Cluster } from '../utils/cluster-utils';

/*
 * This is a temporary flag to switch between the horizontal and vertical design of the welcome stepper.
 * This will be removed and used as a feature flag in the future.
 */
const isHorizontalDesign = true;

export const Route = createRoute({
  meta: () => [
    {
      title: 'Overview',
    },
  ],
  getParentRoute: () => AccountRootRoute,
  path: 'overview',
  component: AccountOverviewComponent,
});

function getGreetingMessage() {
  const hour = new Date().getHours();
  if (4 < hour && hour <= 12) {
    return 'Good morning';
  } else if (12 < hour && hour <= 17) {
    return 'Good afternoon';
  } else {
    return 'Good evening';
  }
}

function AccountOverviewComponent() {
  const { settings, saveSettings } = useSettings();
  const { account } = useAuthenticatedData();
  const { user } = useAuth();
  const {
    hybrid_cloud_enabled: hybridCloudEnabled,
    isLoading: loadingFeatureFlags,
    isError: errorLoadingFeatureFlags,
  } = useFeatureFlags();
  const accountId = account.id;

  const { data: clusters, isLoading: clustersLoading } = useGetClustersByAccountIdQuery({ accountId });
  const { data: apiKeys, isLoading: apiKeysLoading } = useGetApiKeysByAccountIdQuery({ account_id: accountId });

  const userGreetings = 'given_name' in user ? `${getGreetingMessage()} ${user.given_name ?? ''}` : undefined;
  const isDataLoading = clustersLoading || apiKeysLoading || !clusters || !apiKeys;

  const getBanners = (settings: Settings) => ({
    ...settings.banners,
  });
  const [banners, setBanners] = useState(getBanners(settings));

  useEffect(() => {
    setBanners(getBanners(settings));
  }, [settings]);

  const handleDismissBanner = (id: keyof Settings['banners']) => {
    saveSettings({
      banners: { ...banners, [id]: false },
    });
  };

  /* Welcome stepper */
  const [stepperInProgress, setStepperInProgress] = useState(true);
  const [activeStep, setActiveStep] = useState<number | null>(null);

  useEffect(() => {
    if (!isDataLoading) {
      let tempActiveStep = 0;
      const tempStepperInProgress =
        (settings.isWelcomeStepperInProgress !== undefined && settings.isWelcomeStepperInProgress) || false;

      if (tempStepperInProgress) {
        if (clusters.length === 0) {
          tempActiveStep = 0;
        } else if (apiKeys.length === 0 || !hasOwnApiKey(clusters[0], apiKeys)) {
          tempActiveStep = 1;
        } else {
          tempActiveStep = 2;
        }
      }

      setActiveStep(tempActiveStep);
      setStepperInProgress(tempStepperInProgress);
    }
  }, [clusters, apiKeys, isDataLoading, settings]);

  const navigate = useNavigate();

  function hasOwnApiKey(cluster: Cluster, apiKeys?: ApiKeys) {
    return apiKeys?.some((apiKey) => apiKey.cluster_id_list?.includes(cluster.id));
  }

  useEffect(() => {
    if (settings.isWelcomeStepperInProgress === undefined) {
      if (clusters?.length === 0 || apiKeys?.length === 0) {
        saveSettings({
          ...settings,
          isWelcomeStepperInProgress: true,
        });
      }
    }
  }, [clusters, apiKeys, saveSettings, settings]);

  const handleStepperComplete = () => {
    setStepperInProgress(false);
    saveSettings({
      isWelcomeStepperInProgress: false,
    });
    void navigate({ to: '/accounts/$accountId/clusters', params: { accountId } });
  };

  const loadedFeatureFlags = !loadingFeatureFlags && !errorLoadingFeatureFlags;
  const shouldRenderOverviewBanner =
    loadedFeatureFlags && !hybridCloudEnabled && !clustersLoading && !stepperInProgress && banners.welcome;
  const shouldRenderHybridCloudBanner = loadedFeatureFlags && hybridCloudEnabled && banners.hybridCloudLaunch;

  return (
    <AuthenticatedDashboard>
      <PageFrame title={userGreetings}>
        <Box mb={4}>
          {!clustersLoading && <PendingRequests onlyShowInvites={true} />}
          {shouldRenderOverviewBanner && (
            <OverviewBanner onDismiss={() => handleDismissBanner('welcome')} sx={{ mb: 2 }} />
          )}
          {shouldRenderHybridCloudBanner && (
            <HybridCloudBanner onDismiss={() => handleDismissBanner('hybridCloudLaunch')} sx={{ mb: 3 }} />
          )}
          {!clustersLoading && stepperInProgress && activeStep !== null && (
            <>
              {isHorizontalDesign ? (
                <WelcomeStepperProvider>
                  <OverviewWelcomeStepperHorizontal
                    apiKeys={apiKeys}
                    activeStep={activeStep}
                    onComplete={handleStepperComplete}
                  />
                </WelcomeStepperProvider>
              ) : (
                <OverviewWelcomeStepper
                  clusters={clusters}
                  apiKeys={apiKeys}
                  accountId={accountId}
                  activeStep={activeStep}
                  onComplete={handleStepperComplete}
                  mb={5}
                />
              )}
            </>
          )}
          {!clustersLoading && clusters && clusters.length > 0 && (
            <OverviewClustersWidget clusters={clusters} isLoading={clustersLoading} />
          )}
        </Box>
        {/* Show the news & update session only when the welcome stepper flow is completed */}
        {!settings.isWelcomeStepperInProgress && (
          <Box>
            <Typography variant="h5" component="h2" mb={2}>
              News and Updates
            </Typography>
            <NewsItemList />
          </Box>
        )}
      </PageFrame>
    </AuthenticatedDashboard>
  );
}
