/* eslint-disable max-len */
import { useRouteContext } from '@tanstack/react-router';
import { z, ZodType } from 'zod';
import { MAX_NODES, MIN_NODES } from '../components/Clusters/ClusterSetup/ClusterConfiguration/ClusterNodesControl';
import { getDefaultRegion } from '../components/Clusters/ClusterSetup/ClusterRegionList';
import { ClusterTemplateType } from '../components/Clusters/ClusterSetup/ClusterTemplateList';
import { CLOUD_PROVIDER_MAP, FREE_TIER_CLOUD_PROVIDER } from '../components/Clusters/constants';
import { CloudProvider, CloudRegion } from '../services/clustersApi';

/**
 * Returns the current authentication context from the router state.
 */
export const useAuth = () =>
  useRouteContext({
    from: '__root__',
    select: ({ auth }) => auth,
  });

function fallback<T>(value: T): ZodType<T> {
  return z.any().transform(() => value);
}

const template = ['free', 'standard'] as const satisfies readonly ClusterTemplateType[];
const cloudProviders = [
  CLOUD_PROVIDER_MAP.AWS,
  CLOUD_PROVIDER_MAP.GCP,
  CLOUD_PROVIDER_MAP.AZURE,
  CLOUD_PROVIDER_MAP.PRIVATE,
] as const satisfies readonly CloudProvider[];

export const clusterSetupSearchSchema = z.object({
  pid: z.string().optional(),
  nodes: z.coerce
    .number()
    .min(MIN_NODES)
    .optional()
    .or(fallback(MIN_NODES))
    .pipe(z.number().max(MAX_NODES).optional().or(fallback(MAX_NODES))),
  disk: z.coerce.number().min(0).optional().or(fallback(0)),
});

export type ClusterSetupSearchParams = z.infer<typeof clusterSetupSearchSchema>;

export const clusterCreateSearchSchema = z
  .object({
    name: z.string().optional(),
    template: z.enum(template).catch(template[1]),
    provider: z.enum(cloudProviders).catch(FREE_TIER_CLOUD_PROVIDER),
    region: z
      .string()
      .catch(undefined as unknown as string)
      .transform((region) => region as CloudRegion),
    hybridCloudEnv: z.string().optional(),
  })
  .transform(({ provider, region, ...rest }) => {
    const result = {
      provider,
      region: getDefaultRegion(provider, region),
      ...rest,
    };
    if (provider !== CLOUD_PROVIDER_MAP.PRIVATE) {
      const { hybridCloudEnv, ...rest } = result;
      return rest as typeof result;
    }
    return result;
  });

export type ClusterCreateSearchParams = z.infer<typeof clusterCreateSearchSchema>;

const clusterCreateSearchDefaultParams: Partial<ClusterCreateSearchParams> = {
  template: 'standard',
};

/**
 * Parses and validates the provided search parameters for cluster creation.
 *
 * @param {string} [params.template='standard'] - The template type for the cluster. Defaults to 'standard'.
 */
export function clusterCreateSearch(params = clusterCreateSearchDefaultParams): ClusterCreateSearchParams {
  return clusterCreateSearchSchema.parse({ ...clusterCreateSearchDefaultParams, ...params });
}

export const rootSearchSchema = z.object({
  sugerEntitlementId: z.string().optional(),
  aerr: z.string().optional(),
  sso_connection: z.string().optional(),
  sso_login_hint: z.string().optional(),
});

export const authenticatedSearchSchema = z.object({
  session_id: z.string().optional(),
});

export const hybridCloudSearchSchema = z.object({
  showCleanUpCommands: z.literal<boolean>(true).optional().catch(undefined),
});
