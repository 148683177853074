import { Grid, Typography } from '@mui/material';
import { PaymentProviderName, PaymentProvidersMarketplace } from './PaymentProviders';

export const PaymentConnectedMarketplace = ({
  provider,
  marketplaceAccountId,
}: {
  provider: Exclude<PaymentProviderName, 'stripe'>;
  marketplaceAccountId: string | null;
}) => (
  <Grid container={true} spacing={4} rowSpacing={3} alignItems="center">
    <Grid item={true}>
      <PaymentProvidersMarketplace provider={provider} />
    </Grid>
    <Grid item={true} data-sentry-mask={true}>
      <Typography variant="body2">
        Billed by {provider.toUpperCase()} for account ID: {marketplaceAccountId}.
      </Typography>
    </Grid>
  </Grid>
);
