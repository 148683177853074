import { Box, SxProps } from '@mui/material';
import { BackupSchedules } from './BackupSchedules';
import { BackupsTable } from './BackupsTable';
import { AVAILABLE_PROVIDERS_FOR_BACKUP } from './constants';
import { Cluster, isClusterFreeTier } from '../../../utils/cluster-utils';

type BackupsProps = {
  defaultCluster?: Cluster;
  loadingClusters?: boolean;
  availableClusters?: Cluster[];
  sx?: SxProps;
};

export function Backups({ defaultCluster, availableClusters, loadingClusters, sx }: BackupsProps) {
  const hasClusters = availableClusters && availableClusters.length > 0;
  const availableClustersForBackups = hasClusters
    ? availableClusters.filter(
        (cluster) => !isClusterFreeTier(cluster) && AVAILABLE_PROVIDERS_FOR_BACKUP.includes(cluster.cloud_provider),
      )
    : undefined;
  return (
    <Box sx={{ ...sx }}>
      <BackupSchedules
        defaultCluster={defaultCluster}
        availableClusters={availableClustersForBackups}
        loadingClusters={loadingClusters}
      />
      <BackupsTable
        defaultCluster={defaultCluster}
        availableClusters={availableClustersForBackups}
        loadingClusters={loadingClusters}
        sx={{ mt: 3 }}
      />
    </Box>
  );
}
