import PropTypes from 'prop-types';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

export const MAX_DAYS_IN_MONTH = 28;

export const DayOfMonthSelect = ({ dayOfMonth, setDayOfMonth }) => {
  return (
    <FormControl fullWidth={true} data-testid="schedules-day-of-month-select">
      <InputLabel id="dayOfMonthLabel">Day of Month</InputLabel>
      <Select
        id="dayOfMonth"
        labelId="dayOfMonthLabel"
        label="Day of Month"
        value={dayOfMonth}
        onChange={(event, newValue) => {
          setDayOfMonth(newValue.props.value);
        }}
        defaultValue={1}
        fullWidth={true}
      >
        {Array.from({ length: MAX_DAYS_IN_MONTH }, (_, n) => {
          const day = n + 1;
          return (
            <MenuItem value={day} key={`day-of-month-${day}`}>
              {day}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

DayOfMonthSelect.propTypes = {
  dayOfMonth: PropTypes.number.isRequired,
  setDayOfMonth: PropTypes.func.isRequired,
};

export default DayOfMonthSelect;
