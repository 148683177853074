import { Box, Button, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useNavigate } from '@tanstack/react-router';
import { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { DASHBOARD_LAYOUT_GAP, AuthenticatedDashboardEmpty } from './AuthenticatedDashboard';
import { Link } from '../../router/Link';
import { isErrorWithDetail } from '../../services/helpers';
import { iamApi } from '../../services/iamApi';
import { AccountMissingError } from '../../utils/account-utils';

const AccountRedirectErrorBoundaryRender = ({
  children,
  error,
  resetError,
}: {
  children: ReactNode;
  error: AccountMissingError;
  resetError: () => void;
}) => {
  const navigate = useNavigate();
  const accountId = error.defaultAccountId;

  if (!accountId) {
    throw new Error('AccountRedirectErrorBoundaryRender: path param $accountId is required');
  }

  useEffect(() => {
    void navigate({ to: '/accounts/$accountId/overview', params: { accountId } });
    resetError();
  }, [accountId, navigate, resetError]);

  return children;
};

export const AccountRetrievalErrorBoundary = ({
  children,
  beforeCapture,
}: {
  children: ReactNode;
  beforeCapture?: Sentry.ErrorBoundaryProps['beforeCapture'];
}) => {
  const dispatch = useDispatch();

  return (
    <Sentry.ErrorBoundary
      beforeCapture={beforeCapture}
      // eslint-disable-next-line @typescript-eslint/unbound-method
      fallback={function AccountRequiredErrorBoundaryRender({ error, resetError }) {
        if (error instanceof AccountMissingError && error.defaultAccountId) {
          return (
            <AccountRedirectErrorBoundaryRender resetError={resetError} error={error}>
              <AuthenticatedDashboardEmpty />
            </AccountRedirectErrorBoundaryRender>
          );
        }
        return (
          <AuthenticatedDashboardEmpty>
            <Box
              display="flex"
              gap="8px"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              height={`calc(100vh - ${DASHBOARD_LAYOUT_GAP})`}
            >
              <Typography variant="h5" component="p" sx={{ textAlign: 'center', mb: 2 }}>
                Oops! It seems there was an issue during your login process.
                <br />
                We apologize for the inconvenience.
              </Typography>
              {isErrorWithDetail(error) && (
                <Typography variant="body2" component="p" sx={{ textAlign: 'center', mb: 2 }}>
                  <b>Error details:</b> {error.detail}
                </Typography>
              )}
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    dispatch(iamApi.util.resetApiState()); // reset IAM api state
                    resetError(); // re-renders children
                  }}
                >
                  Try again
                </Button>
                or
                <Button variant="outlined" component={Link} to="/logout">
                  Logout
                </Button>
              </Box>
            </Box>
          </AuthenticatedDashboardEmpty>
        );
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
