import { Button, ButtonProps } from '@mui/material';
import { useNavigate } from '@tanstack/react-router';
import { memo, useCallback } from 'react';
import { useTrackInteractionEvent } from '../../../hooks/use-event-tracking';
import { Plus } from '../../../icons/plus';
import { clusterCreateSearch } from '../../../router/utils';
import { CloudProvider, CloudRegion } from '../../../services/clustersApi';

type ClusterCreateButtonProps = {
  accountId: string;
  disabled: boolean;
  provider?: CloudProvider;
  region?: CloudRegion;
  hybridCloudEnvId?: string;
} & Pick<ButtonProps, 'variant'>;

export const ClusterCreateButton = memo(function ClusterCreateButton({
  accountId,
  disabled,
  variant = 'contained',
  provider,
  region,
  hybridCloudEnvId,
}: ClusterCreateButtonProps) {
  const trackCreateClusterClick = useTrackInteractionEvent({
    location: 'Clusters Dashboard',
    label: 'Create',
    action: 'clicked',
  });
  const navigate = useNavigate();
  const handleOpen = useCallback(async () => {
    trackCreateClusterClick();
    await navigate({
      to: '/accounts/$accountId/clusters/create',
      params: { accountId },
      search: clusterCreateSearch({ provider, region, hybridCloudEnv: hybridCloudEnvId }),
    });
  }, [trackCreateClusterClick, navigate, accountId, provider, region, hybridCloudEnvId]);

  return (
    <Button
      startIcon={<Plus fontSize="small" />}
      color="primary"
      onClick={handleOpen}
      disabled={disabled}
      variant={variant}
    >
      Create
    </Button>
  );
});
