import { differenceInSeconds } from 'date-fns';
import {
  PrivateRegionComponentPhase,
  PrivateRegionStatusOutput,
  LogLevels,
  PrivateRegion,
} from '../../utils/cluster-utils';
import { getFormattedPastTimeInterval } from '../../utils/helpers';

export const DEFAULT_PHASE = 'Unknown';

/**
 * Defines how often we should poll for data.
 */
export const DEFAULT_REFRESH_INTERVAL = 30_000;

const TWO_MINUTES_IN_SECS = 60 * 2;

type FilteredLogLevels = NonNullable<LogLevels>;
export const LOG_LEVELS: FilteredLogLevels[] = ['DEBUG', 'INFO', 'WARN', 'ERROR'];

export type PrivateRegionStatusData = {
  kubernetesVersion: string;
  operatorVersion: string;
  agentVersion: string;
  prometheusVersion: string;
  qdrantNodeExporterVersion: string;
  operatorPodState: PrivateRegionComponentPhase;
  agentPodState: PrivateRegionComponentPhase;
  prometheusPodState: PrivateRegionComponentPhase;
  qdrantNodeExporterPodState: PrivateRegionComponentPhase;
  modifiedAt?: string | null;
  phase?: PrivateRegionComponentPhase;
};

/**
 * Returns the color of the status pill based on the status value.
 */
export const getStatusColor = (status?: PrivateRegionComponentPhase) => {
  switch (status) {
    case 'Ready':
      return 'success';
    case 'NotReady':
      return 'error';
    case 'Unknown':
      return 'warning';
    default:
      return 'inactive';
  }
};

export const getPastTimeInterval = (strDate?: string | null): string => {
  if (!strDate) {
    return '-';
  }

  try {
    const date = new Date(strDate);
    return `${getFormattedPastTimeInterval(date)} ago`;
  } catch {
    return '-';
  }
};

/**
 * Checks if the last status update is older than 2 minutes. (No status => false).
 */
export const isOutdatedStatusData = (status?: PrivateRegionStatusOutput | null): boolean => {
  if (!status) {
    return false;
  }

  return (
    status.modifiedAt != null && differenceInSeconds(new Date(), new Date(status.modifiedAt)) > TWO_MINUTES_IN_SECS
  );
};

export const isRegionAvailable = (region: PrivateRegion) => Boolean(region.status?.readyForClusterCreation);

export const getStatusData = (status?: PrivateRegionStatusOutput | null): PrivateRegionStatusData => {
  const operatorData = status?.helmReleases.find(({ name }) => name === 'qdrant-operator');
  const agentData = status?.helmReleases.find(({ name }) => name === 'qdrant-cloud-agent');
  const prometheusData = status?.helmReleases.find(({ name }) => name === 'qdrant-prometheus');
  const qdrantNodeExporterData = status?.helmReleases.find(({ name }) => name === 'qdrant-node-exporter');
  return {
    kubernetesVersion: status?.k8sVersion ?? '-',
    operatorVersion: operatorData?.version ?? '-',
    agentVersion: agentData?.version ?? '-',
    prometheusVersion: prometheusData?.version ?? '-',
    qdrantNodeExporterVersion: qdrantNodeExporterData?.version ?? '-',
    operatorPodState: !operatorData?.phase ? DEFAULT_PHASE : operatorData.phase,
    agentPodState: !agentData?.phase ? DEFAULT_PHASE : agentData.phase,
    prometheusPodState: !prometheusData?.phase ? DEFAULT_PHASE : prometheusData.phase,
    qdrantNodeExporterPodState: !qdrantNodeExporterData?.phase ? DEFAULT_PHASE : qdrantNodeExporterData.phase,
    modifiedAt: status?.modifiedAt,
    phase: status?.phase === 'FailedToSync' ? 'NotReady' : status?.phase,
  };
};
