import SingleNodeIcon from '@mui/icons-material/ViewComfy';
import { Box } from '@mui/material';
import { Cluster } from '../../utils/cluster-utils';

export const ClusterNodeState = ({ cluster }: { cluster: Cluster }) => (
  <Box>
    {cluster?.state &&
      cluster?.state?.nodes &&
      Object.entries(cluster?.state?.nodes).map(([id, node]) => (
        <SingleNodeIcon color={node?.state?.Ready === 'True' ? 'success' : 'error'} key={id} />
      ))}
    {cluster?.state &&
      cluster?.state?.nodes &&
      Object.entries(cluster?.state?.nodes).length < Number(cluster.configuration?.num_nodes) &&
      [
        ...Array.from(
          {
            length: Number(cluster.configuration?.num_nodes) - Object.entries(cluster.state?.nodes).length,
          },
          (_, i) => i,
        ),
      ].map((k) => <SingleNodeIcon color="error" key={k} />)}
  </Box>
);
