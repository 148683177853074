import { Close } from '@mui/icons-material';
import { Box, Button, Card, IconButton, Link, SxProps, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { MouseEvent } from 'react';

export const OverviewBanner = ({
  onDismiss,
  sx,
}: {
  onDismiss: (_event: MouseEvent<HTMLButtonElement>) => void;
  sx: SxProps;
}) => (
  <Card
    sx={{
      position: 'relative',
      alignItems: 'center',
      backgroundColor: 'primary.main',
      color: 'primary.contrastText',
      display: 'flex',
      flexDirection: {
        xs: 'column',
        md: 'row',
      },
      p: 4,
      ...sx,
    }}
  >
    <Box
      sx={{
        mr: 4,
        width: 200,
        height: 200,
        '& img': {
          height: 200,
          width: 'auto',
        },
      }}
    >
      <img alt="" src="/documentation.png" />
    </Box>
    <div>
      <Typography color="inherit" sx={{ mt: 2 }} variant="h4">
        Welcome to the Qdrant Cloud platform
      </Typography>
      <Typography color="inherit" sx={{ mt: 1 }} variant="subtitle2">
        <Box>Take the first step towards cloud mastery – start reading our documentation now!</Box>
        <Box>Join our Discord server in case of any questions.</Box>
      </Typography>
      <Box sx={{ mt: 2 }} display={'flex'}>
        <Link href="https://qdrant.tech/documentation/cloud/" target="_blank" underline={'none'}>
          <Button color="secondary" variant="contained" sx={{ mr: 1 }}>
            Read documentation
          </Button>
        </Link>
        <Link href="https://qdrant.to/discord" target="_blank" underline={'none'}>
          <Button color="secondary" variant="contained">
            Join Community
          </Button>
        </Link>
      </Box>
    </div>
    <Box sx={(theme) => ({ position: 'absolute', right: theme.spacing(2), top: 0, mt: 2 })}>
      <IconButton
        title="Dismiss"
        onClick={onDismiss}
        sx={(theme) => ({
          backgroundColor: alpha(theme.palette.background.paper, 0.1),
          color: 'primary.contrastText',
          '&:hover': {
            backgroundColor: alpha(theme.palette.background.paper, 0.2),
          },
        })}
      >
        <Close />
      </IconButton>
    </Box>
  </Card>
);
