import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Alert, Button, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import DoneIcon from '@mui/icons-material/Done';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useGetInvitesForUserQuery, useUpdateInviteStatusMutation } from '../../services/iamApi';
import { ErrorBox } from '../Common/ErrorBox';
import { INVITE_STATUS } from '../../utils/constants/invites';
import { useAuthenticatedData } from '../../contexts/authenticated-data-context';
import { isFetchMutationError } from '../../services/helpers';
import { useSnackbar } from '../../hooks/use-qdrant-snackbar';

const ACCEPT_INVITE = 'accept';
const DECLINE_INVITE = 'decline';

const InviteRequest = ({ invite, respondToInvite, loadingRequest }) => {
  const { account_name: accountName, sent_by: inviter, id } = invite;
  const loadingThisRequest = loadingRequest?.inviteId === id;

  return (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
      <Alert
        role="alert"
        aria-label="Invite request"
        aria-live="polite"
        sx={{
          width: '100%',
          '&>.MuiAlert-action': {
            padding: 0,
            alignItems: 'center',
          },
        }}
        iconMapping={{
          info: <GroupAddIcon fontSize="inherit" />,
        }}
        variant="filled"
        severity="info"
        action={
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
              size="small"
              color="inherit"
              startIcon={
                loadingThisRequest && loadingRequest.action === ACCEPT_INVITE ? (
                  <CircularProgress size={16} />
                ) : (
                  <DoneIcon />
                )
              }
              onClick={() => {
                respondToInvite({
                  inviteId: invite.id,
                  status: INVITE_STATUS.ACCEPTED,
                });
              }}
              disabled={Boolean(loadingRequest)}
            >
              Accept
            </Button>
            <Button
              size="small"
              color="inherit"
              startIcon={
                loadingThisRequest && loadingRequest.action === DECLINE_INVITE ? (
                  <CircularProgress size={16} />
                ) : (
                  <CloseIcon />
                )
              }
              onClick={() => {
                respondToInvite({
                  inviteId: invite.id,
                  status: INVITE_STATUS.DECLINED,
                });
              }}
              disabled={Boolean(loadingRequest)}
            >
              Decline
            </Button>
          </Box>
        }
      >
        {`${inviter} has invited you to join ${accountName}. Do you want to join?`}
      </Alert>
    </Box>
  );
};

export const PendingRequests = ({ onlyShowInvites = false }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useAuthenticatedData();
  const {
    data: invites,
    isLoading: loadingInvites,
    isError: errorLoadingInvites,
    refetch: refetchInvites,
    isFetching,
  } = useGetInvitesForUserQuery({ userId: userInfo.id });

  const [updateInviteStatus] = useUpdateInviteStatusMutation();
  const [loadingRequest, setLoadingRequest] = useState(null);

  const hasPendingInvites = invites && invites.length > 0;
  const InvitesIcon = hasPendingInvites ? NotificationsIcon : NotificationsNoneIcon;

  const respondToInvite = useCallback(
    async ({ inviteId, status }) => {
      if (loadingRequest !== null) {
        return;
      }
      const invitationAccepted = status === INVITE_STATUS.ACCEPTED;
      const invitationDeclined = status === INVITE_STATUS.DECLINED;
      setLoadingRequest(
        invitationAccepted ? { inviteId, action: ACCEPT_INVITE } : { inviteId, action: DECLINE_INVITE },
      );

      const result = await updateInviteStatus({ userId: userInfo.id, inviteId, status });
      if (isFetchMutationError(result)) {
        // Invite doesn't exist anymore
        if (result.error.status === 409) {
          enqueueSnackbar('Invite has expired.', { variant: 'error' });
          refetchInvites();
        } else {
          enqueueSnackbar('There was a problem accepting the invite. Try again later.', { variant: 'error' });
        }
      } else {
        if (invitationAccepted) {
          enqueueSnackbar('You have been successfully added to the new account.', { variant: 'success' });
        } else if (invitationDeclined) {
          enqueueSnackbar('You have declined the invite.', { variant: 'info' });
        }
      }
      setLoadingRequest(null);
    },
    [updateInviteStatus, enqueueSnackbar, userInfo, loadingRequest, refetchInvites],
  );

  const getPendingRequestsBody = () => {
    if (loadingInvites) {
      return <CircularProgress size={20} />;
    }

    if (onlyShowInvites && (errorLoadingInvites || !hasPendingInvites)) {
      return null;
    }

    if (errorLoadingInvites) {
      return <ErrorBox retry={refetchInvites} />;
    }

    if (!hasPendingInvites) {
      return (
        <Typography color="textSecondary" variant="body2" sx={{ marginLeft: '4px' }}>
          There are no pending requests.
        </Typography>
      );
    }

    return (
      <>
        {invites.map((invite) => (
          <InviteRequest
            invite={invite}
            respondToInvite={respondToInvite}
            key={invite.id}
            loadingRequest={loadingRequest || isFetching}
          />
        ))}
      </>
    );
  };

  return (
    <>
      {!onlyShowInvites && (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            mt: 3,
          }}
        >
          <InvitesIcon color={hasPendingInvites ? 'text.primary' : 'textSecondary'} fontSize="small" />
          <Typography color={hasPendingInvites ? 'text.primary' : 'textSecondary'} sx={{ pl: 1 }} variant="subtitle1">
            Pending Requests
          </Typography>
        </Box>
      )}
      <Box sx={{ mt: 2, mb: 3, display: 'flex', flexDirection: 'column', gap: 1 }}>{getPendingRequestsBody()}</Box>
    </>
  );
};

PendingRequests.propTypes = {
  onlyShowInvites: PropTypes.bool,
};
