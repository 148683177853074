import { useNavigate } from '@tanstack/react-router';
import { useEffect, useState, createElement, useRef } from 'react';
import { useSnackbar } from './use-qdrant-snackbar';
import {
  SugerAlertMessageIdAlreadyInUse,
  SugerAlertMessagePaymentAddedPendingSupportAction,
  SugerAlertMessagePaymentAddedWithBookingMigration,
} from '../components/Billing/BillingAlertMessages';
import {
  MARKETPLACE_RETURN_TO_STORAGE_KEY,
  PAYMENT_INFO_SECTION_ELEMENT_ID,
} from '../components/Billing/hooks/use-create-marketplace-redirect';
import { useGlobalAlert } from '../components/Common/GlobalAlert';
import { router } from '../router';
import { SUGER_ENTITLEMENT_ID_COOKIE_KEY } from '../routes/__root';
import { isFetchApiError, parseFetchError } from '../services/helpers';
import { useAddMarketplaceTokenMutation } from '../services/paymentApi';
import { Cookies } from '../utils/cookies';
import { logException } from '../utils/error-utils';
import { getUserMessage } from '../utils/errorHandlingUtils';
import { attempt } from '../utils/func-utils';

export const useMarketplaceReturnTo = (accountId: string) => {
  const [parsedLocation] = useState(() =>
    attempt(
      () => {
        const returnTo = window.sessionStorage.getItem(MARKETPLACE_RETURN_TO_STORAGE_KEY);
        if (!returnTo) {
          throw new Error('No redirect URL found in session storage');
        }
        window.sessionStorage.removeItem(MARKETPLACE_RETURN_TO_STORAGE_KEY);
        return router.buildLocation({
          to: returnTo.replace(/(#.+)/, '').padStart(1, '/'),
          hash: PAYMENT_INFO_SECTION_ELEMENT_ID,
        });
      },
      () => router.buildLocation({ to: '/accounts/$accountId/billing', params: { accountId } }),
    ),
  );
  return parsedLocation;
};

/*
 * - Customer that has Stripe connected
 *   - Cluster create dialogue
 *     - everything is enabled
 *     - GCP is the default
 *   - Cluster upgrades
 *     - all allowed
 * - Customer that has AWS connected
 *   - Cluster create dialogue
 *     - Only GCP free tier is enabled
 *     - AWS is default
 *   - Cluster upgrades
 *     - disabled for GCP clusters
 */
export const useSugerRegistration = (accountId: string) => {
  const { enqueueSnackbar } = useSnackbar();
  const { open: openGlobalAlert } = useGlobalAlert();
  const [addMarketplaceToken] = useAddMarketplaceTokenMutation();
  const marketplaceTokenAdded = useRef(false);
  const navigate = useNavigate();
  const marketplaceReturnTo = useMarketplaceReturnTo(accountId);

  useEffect(() => {
    /*
     * Prevent multiple calls to addMarketplaceToken.
     * This can happen if the user changes its account, or their profile is updated.
     */
    if (marketplaceTokenAdded.current) {
      return;
    }
    const sugerEntitlementId =
      Cookies.get(SUGER_ENTITLEMENT_ID_COOKIE_KEY) ?? /** @deprecated 8.8.2024 */ Cookies.get('suger_entitlement_id');
    // Check if the user has a Suger entitlement id in the cookie
    if (typeof sugerEntitlementId === 'string') {
      // Set ref to true to prevent multiple calls to addMarketplaceToken
      marketplaceTokenAdded.current = true;
      (async () => {
        // Take user to the return-to page (default to billing page if not set)
        await navigate({ to: marketplaceReturnTo.href });
        try {
          const data = await addMarketplaceToken({ accountId, sugerEntitlementId }).unwrap();
          let successMsg = 'Successfully set up your new payment connection';
          if (data.migration.cloud_billing_name) {
            successMsg += ` with ${data.migration.cloud_billing_name}`;
          }
          enqueueSnackbar(successMsg, { variant: 'success' });
          if (data.migration.cluster_booking_ids.length > 0) {
            openGlobalAlert({
              title: 'Dear user',
              description: createElement(SugerAlertMessagePaymentAddedWithBookingMigration, {
                clusterBookingIds: data.migration.cluster_booking_ids,
              }),
            });
          }
        } catch (err) {
          const error = parseFetchError(err);
          if (isFetchApiError(error)) {
            switch (error.code) {
              case 'E1031': // Existing entitlement id by this account
              case 'E1042': // Entitlement already taken by a different account
                openGlobalAlert({
                  title: 'Dear user',
                  description: createElement(SugerAlertMessageIdAlreadyInUse),
                });
                break;
              case 'E1044':
                openGlobalAlert({
                  title: 'Dear user',
                  description: createElement(SugerAlertMessagePaymentAddedPendingSupportAction),
                });
                break;
              default:
            }
            enqueueSnackbar('Unfortunately your payment setup failed!', { variant: 'error' });
          } else {
            enqueueSnackbar(getUserMessage(error), { variant: 'error' });
            logException(error, {
              user: { accountId },
              extra: { sugerEntitlementId },
            });
          }
        } finally {
          Cookies.remove(SUGER_ENTITLEMENT_ID_COOKIE_KEY);
          /** @deprecated 8.8.2024 */
          Cookies.remove('suger_entitlement_id');
        }
      })();
    }
  }, [accountId, addMarketplaceToken, enqueueSnackbar, marketplaceReturnTo, navigate, openGlobalAlert]);
};
