import BackupIcon from '@mui/icons-material/Backup';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, CircularProgress, MenuItem, Popover, Typography } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';

import { RESOURCE_TYPE_SNAPSHOT } from './constants';
import { useConfirmationAction } from '../../../hooks/use-confirmation-action';
import { useSnackbar } from '../../../hooks/use-qdrant-snackbar';
import { useGetResourceOptionsQuery } from '../../../services/bookingApi';
import { useCreateInstantBackupMutation } from '../../../services/clustersApi';
import { isFetchMutationError } from '../../../services/helpers';
import { Backup, Cluster, isClusterFreeTier, isClusterHybridCloud } from '../../../utils/cluster-utils';
import { ConfirmationDialog } from '../../Common/ConfirmationDialog';
import { AVG_DAYS_PER_MONTH, HOURS_IN_A_DAY, MILLICENTS_TO_DOLLAR_OR_EURO_FACTOR } from '../constants';

type ClusterPopoverProps = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  open: boolean;
  clusters: Cluster[];
  onSelect: (cluster: Cluster) => void;
};

const ClustersPopover = (props: ClusterPopoverProps) => {
  const { anchorEl, onClose, open, clusters, onSelect, ...other } = props;

  const handleChange = (cluster: Cluster) => {
    onSelect(cluster);
    onClose();
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
      keepMounted={true}
      onClose={onClose}
      open={open}
      slotProps={{ paper: { sx: { minWidth: anchorEl?.clientWidth, py: 1 } } }}
      transitionDuration={0}
      {...other}
    >
      <Typography sx={{ py: 1, px: 2, fontWeight: 'bold' }}>Select a Cluster</Typography>
      {clusters.map((cluster) => (
        <MenuItem key={cluster.name} onClick={() => handleChange(cluster)}>
          {cluster.name}
        </MenuItem>
      ))}
    </Popover>
  );
};

type InstantBackupButtonProps = {
  defaultCluster?: Cluster;
  availableClusters?: Cluster[];
  onCreateBackup?: (newBackup: Backup) => void;
  accountId: string;
  disabled?: boolean;
};

export const InstantBackupButton = ({
  defaultCluster,
  availableClusters,
  onCreateBackup,
  accountId,
  disabled,
}: InstantBackupButtonProps) => {
  const anchorRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [openClusterList, setOpenClusterList] = useState(false);
  const [createInstantBackup, { isLoading: creatingBackup }] = useCreateInstantBackupMutation();
  const [selectedCluster, setSelectedCluster] = useState<Cluster>();
  const [estimationPriceShown, setEstimationPriceShown] = useState(false);
  const { confirmAction, showConfirmation, hideConfirmation } = useConfirmationAction();

  const {
    data: resourceOpts,
    isLoading: loadingResourceOpts,
    isFetching: isFetchingResourceOpts,
  } = useGetResourceOptionsQuery(
    {
      account_id: accountId,
      provider: selectedCluster?.cloud_provider,
      region: selectedCluster?.cloud_region,
      resource_type: RESOURCE_TYPE_SNAPSHOT,
    },
    { skip: !selectedCluster },
  );
  const retrievingResourceOpts = loadingResourceOpts || isFetchingResourceOpts;
  const snapshotResourceOpts = resourceOpts?.find(({ resource_type }) => resource_type === RESOURCE_TYPE_SNAPSHOT);

  const isDisabled = disabled || (!defaultCluster && !availableClusters?.length);

  const handleCreateInstantBackup = useCallback(
    async (clusterId?: string) => {
      if (!clusterId || creatingBackup || retrievingResourceOpts) {
        return;
      }
      const result = await createInstantBackup({ accountId, clusterId });
      if (isFetchMutationError(result)) {
        enqueueSnackbar('There was a problem requesting the backup', { variant: 'error' });
      } else {
        enqueueSnackbar('Backup requested successfully', { variant: 'success' });
        onCreateBackup?.(result.data);
      }
    },
    [accountId, createInstantBackup, creatingBackup, enqueueSnackbar, onCreateBackup, retrievingResourceOpts],
  );

  const handleClose = useCallback(() => {
    hideConfirmation();
    setEstimationPriceShown(false);
  }, [hideConfirmation]);

  useEffect(() => {
    if (!isClusterHybridCloud(selectedCluster) && confirmAction && snapshotResourceOpts && !estimationPriceShown) {
      setEstimationPriceShown(true);
      showConfirmation(
        snapshotResourceOpts.unit_int_price_per_hour
          ? {
              ...confirmAction,
              warning: [
                'Based on',
                defaultCluster ? 'this' : 'the selected',
                "cluster's cloud provider and region, the backup storage price is",
                (
                  (snapshotResourceOpts.unit_int_price_per_hour / MILLICENTS_TO_DOLLAR_OR_EURO_FACTOR) *
                  AVG_DAYS_PER_MONTH *
                  HOURS_IN_A_DAY
                ).toFixed(5),
                'per GB per month (estimating 30.5 days a month).',
              ].join(' '),
            }
          : confirmAction,
      );
    }
  }, [confirmAction, snapshotResourceOpts, defaultCluster, selectedCluster, showConfirmation, estimationPriceShown]);

  const singleClusterBackupHandler = (cluster: Cluster) => {
    setSelectedCluster(cluster);
    showConfirmation({
      actionName: 'Create',
      title: 'Create a Backup',
      content: `Are you sure you want to create a backup for '${cluster.name}'?`,
      actionHandler: () => handleCreateInstantBackup(cluster.id),
      withConfirm: false,
      severity: 'info',
    });
  };

  const multipleClusterBackupHandler = () => setOpenClusterList(true);

  return (
    <>
      <Button
        ref={anchorRef}
        variant="contained"
        aria-label="settings"
        startIcon={
          creatingBackup || retrievingResourceOpts ? <CircularProgress size={16} /> : <BackupIcon fontSize="small" />
        }
        endIcon={!defaultCluster && <ExpandMoreIcon fontSize="small" />}
        onClick={!defaultCluster ? multipleClusterBackupHandler : () => singleClusterBackupHandler(defaultCluster)}
        disabled={isDisabled || creatingBackup || retrievingResourceOpts}
      >
        Backup now
      </Button>

      {!defaultCluster && (
        <ClustersPopover
          anchorEl={anchorRef.current}
          clusters={(availableClusters ?? []).filter((cluster) => !isClusterFreeTier(cluster))}
          onClose={() => setOpenClusterList(false)}
          onSelect={singleClusterBackupHandler}
          open={openClusterList}
        />
      )}
      {confirmAction && snapshotResourceOpts && (
        <ConfirmationDialog open={true} onClose={handleClose} {...confirmAction} />
      )}
    </>
  );
};
