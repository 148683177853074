import React, { createContext, useState, ReactNode, useContext, useEffect } from 'react';
import { useAccountId } from '../../../../contexts/authenticated-data-context';
import { Package, useGetPackagesQuery } from '../../../../services/bookingApi';
import { Cluster, getFreeTierPackage } from '../../../../utils/cluster-utils';
import { IS_PROD } from '../../../../utils/helpers';
import { CLOUD_PROVIDER_CONFIG, FREE_TIER_CLOUD_PROVIDER } from '../../../Clusters/constants';

interface IWelcomeStepperContextProps {
  newCluster: Cluster | null;
  setNewCluster: React.Dispatch<React.SetStateAction<Cluster | null>>;
  clusterName: string;
  setClusterName: React.Dispatch<React.SetStateAction<string>>;
  isClusterCreating: boolean;
  setIsClusterCreating: React.Dispatch<React.SetStateAction<boolean>>;
  innerActiveStep: number;
  setInnerActiveStep: React.Dispatch<React.SetStateAction<number>>;
  complete: boolean;
  handleComplete: (onComplete: () => void) => void;
  apiKey: string;
  setApiKey: React.Dispatch<React.SetStateAction<string>>;
  freeBookingPackage: Package | null;
  setFreeBookingPackage: React.Dispatch<React.SetStateAction<Package | null>>;
  isLoadingBookingData: boolean;
}

export const WelcomeStepperContext = createContext<IWelcomeStepperContextProps | undefined>(undefined);

const env = IS_PROD ? 'production' : 'staging';
const providerConfig = CLOUD_PROVIDER_CONFIG.providers[FREE_TIER_CLOUD_PROVIDER][env];

export const WelcomeStepperProvider = ({ children }: { children: ReactNode }) => {
  const accountId = useAccountId();

  const [newCluster, setNewCluster] = useState<Cluster | null>(null);
  const [clusterName, setClusterName] = useState('');
  const [isClusterCreating, setIsClusterCreating] = useState<boolean>(false);
  const [innerActiveStep, setInnerActiveStep] = useState<number>(0);
  const [complete, setComplete] = useState<boolean>(false);
  const [apiKey, setApiKey] = useState<string>('');
  const [freeBookingPackage, setFreeBookingPackage] = useState<Package | null>(null);

  const handleComplete = (onComplete: () => void) => {
    setComplete(true);
    onComplete();
  };

  const { data: bookingData, isLoading: isLoadingBookingData } = useGetPackagesQuery({
    provider: FREE_TIER_CLOUD_PROVIDER,
    region: providerConfig.defaultRegion,
    account_id: accountId,
  });

  useEffect(() => {
    if (!isLoadingBookingData && bookingData) {
      const isFreeBookingPackage = getFreeTierPackage(bookingData);
      if (isFreeBookingPackage) {
        setFreeBookingPackage(isFreeBookingPackage);
      }
    }
  }, [bookingData, isLoadingBookingData]);

  return (
    <WelcomeStepperContext.Provider
      value={{
        newCluster,
        setNewCluster,
        clusterName,
        setClusterName,
        isClusterCreating,
        setIsClusterCreating,
        innerActiveStep,
        setInnerActiveStep,
        complete,
        handleComplete,
        apiKey,
        setApiKey,
        freeBookingPackage,
        setFreeBookingPackage,
        isLoadingBookingData,
      }}
    >
      {children}
    </WelcomeStepperContext.Provider>
  );
};

// Custom hook to use the context
export const useWelcomeStepperContext = () => {
  const context = useContext(WelcomeStepperContext);
  if (!context) {
    throw new Error('useWelcomeStepperContext must be used within a WelcomeStepperProvider');
  }
  return context;
};
