import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

export const BACKUP_FREQUENCIES = {
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
} as const;
type ObjectValues<T> = T[keyof T];
type BackupFrequency = ObjectValues<typeof BACKUP_FREQUENCIES>;

type BackupFrequencySelectProps = {
  frequency: BackupFrequency;
  setFrequency: (value: BackupFrequency) => void;
};

/**
 * Backup frequency select - dropdown
 * @param {string} frequency - selected frequency value from backupFrequency
 * @param setFrequency
 * @returns {JSX.Element} - MUI FormControl containing a MUI Select
 * @constructor
 */
export const BackupFrequencySelect = ({ frequency, setFrequency }: BackupFrequencySelectProps) => (
  <FormControl fullWidth={true} data-testid="schedule-frequency">
    <InputLabel id="backupFrequencyLabel">Backup Frequency</InputLabel>
    <Select<BackupFrequency>
      id="backupFrequency"
      labelId="backupFrequencyLabel"
      label="Backup Frequency"
      value={frequency}
      onChange={(event) => {
        setFrequency(event.target.value as BackupFrequency);
      }}
      defaultValue={frequency}
      fullWidth={true}
    >
      {Object.values(BACKUP_FREQUENCIES).map((backupFrequency) => (
        <MenuItem value={backupFrequency} key={backupFrequency}>
          {backupFrequency}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
