import { Tooltip, Zoom, styled } from '@mui/material';
import { memo } from 'react';
import { HybridCloudIcon } from '../../../../icons/hybrid-cloud-icon';
import { CloudProvider } from '../../../../services/clustersApi';
import { imageFilters } from '../../../../styles';
import { CLOUD_PROVIDER_IMGS, CLOUD_PROVIDER_MAP } from '../../constants';

const StyledCloudOnPremiseIcon = styled(HybridCloudIcon)(
  ({ theme, fontSize }) => `
    width: ${theme.spacing(fontSize === 'small' ? 3 : 5)};
    height: ${theme.spacing(fontSize === 'small' ? 3 : 5)};
    color: ${theme.palette.primary.main};
  `,
);
const StyledCloudProviderIcon = styled('img', {
  shouldForwardProp(propName) {
    return propName !== 'disabled' && propName !== 'fontSize' && propName !== 'themed';
  },
})<{ themed: boolean; disabled?: boolean; fontSize?: 'medium' | 'small' }>(
  ({ theme, themed, disabled, fontSize }) => `
      width: ${theme.spacing(fontSize === 'small' ? 3 : 5)};
      height: ${theme.spacing(fontSize === 'small' ? 3 : 5)};
      filter: ${imageFilters(theme, disabled, themed).filter};
  `,
);

export const CloudProviderIcon = memo(function CloudProviderIcon({
  provider,
  title,
  fontSize,
  disabled,
}: {
  provider: CloudProvider;
  title?: string;
  fontSize?: 'medium' | 'small';
  disabled?: boolean;
}) {
  const providerImgObj = CLOUD_PROVIDER_IMGS[provider];
  let icon: JSX.Element;
  if (provider === CLOUD_PROVIDER_MAP.PRIVATE) {
    icon = <StyledCloudOnPremiseIcon role="presentation" fontSize={fontSize} />;
  } else {
    icon = (
      <StyledCloudProviderIcon
        role="presentation"
        fontSize={fontSize}
        src={providerImgObj.src}
        themed={providerImgObj.themed}
        disabled={disabled}
      />
    );
  }
  return (
    <Tooltip title={title ?? providerImgObj.tooltip} TransitionComponent={Zoom} arrow={true}>
      {icon}
    </Tooltip>
  );
});
