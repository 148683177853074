import { Grid, Skeleton } from '@mui/material';
import { useEffect } from 'react';
import { CreateCustomClusterCard } from './CreateCustomClusterCard';
import { CreateFreeClusterForm } from './CreateFreeClusterForm';
import { Cluster, isFreeTierCreated } from '../../../../../utils/cluster-utils';
import { useWelcomeStepperContext } from '../WelcomeStepperProvider';

const FORM_CARD_HEIGHT = 298;

export const CreateClusterStep = ({ onNext, clusters }: { onNext: () => void; clusters: Cluster[] }) => {
  const { isClusterCreating } = useWelcomeStepperContext();
  const userHasCluster = clusters.length > 0;

  useEffect(() => {
    if (isClusterCreating) {
      onNext();
    }
  }, [onNext, isClusterCreating]);

  if (!userHasCluster && isClusterCreating) {
    return <Skeleton variant="rounded" height={FORM_CARD_HEIGHT} />;
  }

  return (
    <>
      <Grid
        container={true}
        spacing={3}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {!isFreeTierCreated(clusters) && <CreateFreeClusterForm />}
        <CreateCustomClusterCard />
      </Grid>
    </>
  );
};
