import { Avatar, StepIconProps, SxProps, Theme } from '@mui/material';

export const WelcomeStepIcon: React.FC<
  {
    isLastStepHealthy?: boolean;
    isFirstStepProcessing: boolean;
  } & StepIconProps
> = ({ active, completed, icon, isLastStepHealthy, isFirstStepProcessing }) => {
  const avatarSx: SxProps<Theme> = {
    height: 32,
    width: 32,
    padding: 0,
    ...(active && {
      backgroundColor: 'secondary.main',
      color: 'secondary.contrastText',
    }),
    ...((completed || isLastStepHealthy) && {
      backgroundColor: 'success.main',
      color: 'success.contrastText',
    }),
    ...(isFirstStepProcessing && {
      backgroundColor: 'inProgress.main',
      color: 'inProgress.contrastText',
    }),
  };

  return (
    <Avatar sx={avatarSx} variant="circular">
      {icon}
    </Avatar>
  );
};
