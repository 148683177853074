/* eslint-disable max-len */

export const CODE_SNIPPET_LANGS = {
  curl: 'curl',
  grpcurl: 'grpcurl',
  python: 'python',
  javascript: 'javascript',
  rust: 'rust',
  java: 'java',
  csharp: 'csharp',
  go: 'go',
};
export type CodeSnippetLang = keyof typeof CODE_SNIPPET_LANGS;

// please, always start a snippet code on new line without extra spaces,
// code inside `` will keep all the indentation in the app's view
export function getCodeSnippetTemplates(
  urlPlaceholder: string,
  hostPlaceholder: string,
  token: string,
): Record<CodeSnippetLang, string> {
  return {
    curl: `
curl \\
    -X GET '${urlPlaceholder}:6333' \\
    --header 'api-key: ${token}'
`,

    grpcurl: `
grpcurl \\
    -import-path ./lib/api/src/grpc/proto/ \\
    -proto qdrant.proto -d '{}' \\
    -H "api-key: ${token}" \\
    '${hostPlaceholder}:6334' \\
    qdrant.Qdrant/HealthCheck
`,
    python: `
from qdrant_client import QdrantClient

qdrant_client = QdrantClient(
    url="${urlPlaceholder}:6333", 
    api_key="${token}",
)

print(qdrant_client.get_collections())`,
    javascript: `
import {QdrantClient} from '@qdrant/js-client-rest';

const client = new QdrantClient({
    url: '${urlPlaceholder}:6333',
    apiKey: '${token}',
});

try {
    const result = await client.getCollections();
    console.log('List of collections:', result.collections);
} catch (err) {
    console.error('Could not get collections:', err);
}`,
    rust: `
use qdrant_client::Qdrant;

#[tokio::main]
async fn main() {
  let client = Qdrant::from_url("${urlPlaceholder}:6334")
      .api_key("${token}")
      .build()
      .unwrap();
      
  let collections_list = client.list_collections().await;
  let _ = dbg!(collections_list);
}`,
    java: `
package org.example;

import io.qdrant.client.QdrantClient;
import io.qdrant.client.QdrantGrpcClient;

import java.time.Duration;
import java.util.List;
import java.util.concurrent.ExecutionException;

public class Main {
    public static void main(String[] args) throws ExecutionException, InterruptedException {
        QdrantClient client = new QdrantClient(
            QdrantGrpcClient.newBuilder(
              "${hostPlaceholder}",
              6334,
              true
            )
            .withApiKey("${token}")
            .build()
        );

        List<String> collections = client.listCollectionsAsync(Duration.ofSeconds(5)).get();

        System.out.printf(collections.toString());
    }
}`,
    csharp: `
using Qdrant.Client;

var client = new QdrantClient(
  host: "${hostPlaceholder}",
  https: true,
  apiKey: "${token}"
);

var collections = await client.ListCollectionsAsync();

foreach (var collection in collections) {
    Console.WriteLine(collection);
}`,
    go: `
package main

import (
	"context"
	"crypto/tls"
	"log"
	"time"

	"google.golang.org/grpc"
	"google.golang.org/grpc/credentials"
	"google.golang.org/grpc/metadata"

	pb "github.com/qdrant/go-client/qdrant"
)

func main() {
	addr := "${hostPlaceholder}:6334"

	// Set up a connection to the server.
	config := &tls.Config{}
	conn, err := grpc.NewClient(addr, grpc.WithTransportCredentials(credentials.NewTLS(config)), grpc.WithUnaryInterceptor(interceptor))
	if err != nil {
		log.Fatalf("did not connect: %v", err)
	}
	defer conn.Close()

	ctx, cancel := context.WithTimeout(context.Background(), 10*time.Second)
	defer cancel()

	collectionsClient := pb.NewCollectionsClient(conn)

	// Contact the server and print out its response.
	r, err := collectionsClient.List(ctx, &pb.ListCollectionsRequest{})
	if err != nil {
		log.Fatalf("could not get collections: %v", err)
	}
	log.Printf("List of collections: %s", r.GetCollections())
}

func interceptor(ctx context.Context, method string, req, reply interface{}, cc *grpc.ClientConn, invoker grpc.UnaryInvoker, opts ...grpc.CallOption) error {
	apiKey := "${token}"

	newCtx := metadata.AppendToOutgoingContext(ctx, "api-key", apiKey)

	return invoker(newCtx, method, req, reply, cc, opts...)
}
`,
  };
}
export const getCodeSnippet = function (url: string, host: string, token: string, type: CodeSnippetLang) {
  const codeSnippetTemplates = getCodeSnippetTemplates(url, host, token);
  return (codeSnippetTemplates[type] || '').trim();
};
