import PropTypes from 'prop-types';
import { PlayCircleOutlined } from '@mui/icons-material';
import { getUserMessage } from '../../../utils/errorHandlingUtils';
import { useUnsuspendClusterMutation } from '../../../services/clustersApi';
import { ButtonDangerAction } from '../../Common/ButtonDangerAction';
import { memo, useState } from 'react';
import { useSnackbar } from '../../../hooks/use-qdrant-snackbar';

function ClusterUnsuspendButton({ cluster, accountId }) {
  const { enqueueSnackbar } = useSnackbar();
  const [unsuspendCluster] = useUnsuspendClusterMutation();
  const [isLoading, setIsLoading] = useState(false);

  const handleUnsuspendCluster = async () => {
    setIsLoading(true);
    await unsuspendCluster({ clusterId: cluster.id, accountId })
      .unwrap()
      .catch((err) => {
        setIsLoading(false);
        enqueueSnackbar(getUserMessage(err), { variant: 'error' });
        return false;
      });
    return true;
  };

  return (
    <>
      <ButtonDangerAction
        actionName="Reactivate"
        loadingStateName="Reactivating"
        isLoading={isLoading}
        disabled={!cluster.state?.actionable}
        dialogTitle={'Activate cluster'}
        dialogContent={
          `Your cluster ${cluster.name} was suspended due to being ` +
          'inactive for a long time. Are you sure you want to activate it?'
        }
        dialogWarning={'This can cause payment charges if your cluster is not a free-tier cluster.'}
        handleDangerAction={handleUnsuspendCluster}
        buttonIcon={<PlayCircleOutlined fontSize="small" />}
        targetName={cluster.name}
        buttonColor="success"
      />
    </>
  );
}

// propTypes declaration
ClusterUnsuspendButton.propTypes = {
  cluster: PropTypes.object.isRequired,
  accountId: PropTypes.string.isRequired,
};

export default memo(ClusterUnsuspendButton);
