import { Box, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import { ReactNode, useState, useEffect } from 'react';
import { logException } from '../../../utils/error-utils';
import { attempt } from '../../../utils/func-utils';

export const LAST_CHUNK_LOAD_ERROR_KEY = 'lastChunkLoadError';

function isChunkLoadError(e: unknown): e is Error {
  return (
    e instanceof Error &&
    (e.name.includes('ChunkLoadError') ||
      /* https://github.com/webpack-contrib/mini-css-extract-plugin/issues/346 */
      ('code' in e && typeof e.code === 'string' && e.code.includes('CHUNK_LOAD_FAILED')))
  );
}

export const GlobalErrorBoundary = ({
  children,
  beforeCapture,
}: { children: ReactNode } & Pick<Sentry.ErrorBoundaryProps, 'beforeCapture'>) => {
  const [lastChunkLoadError] = useState(() =>
    // web storage may be undefined/disabled in browser settings
    attempt(
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      () => sessionStorage?.getItem(LAST_CHUNK_LOAD_ERROR_KEY),
      () => null,
    ),
  );

  useEffect(() => {
    if (lastChunkLoadError) {
      Sentry.addBreadcrumb({
        category: 'reload',
        message: 'GlobalErrorBoundary: ChunkLoadError caused page to automatically reload',
        data: { error: lastChunkLoadError },
        level: 'info',
      });
      // Prevent infinite reload loop
      sessionStorage.removeItem(LAST_CHUNK_LOAD_ERROR_KEY);
    }
  }, [lastChunkLoadError]);

  return (
    <Sentry.ErrorBoundary
      fallback={function GlobalErrorFallbackRender({ error }) {
        if (isChunkLoadError(error) && !lastChunkLoadError) {
          if (
            attempt(
              () => {
                sessionStorage.setItem(LAST_CHUNK_LOAD_ERROR_KEY, error.message);
                Sentry.addBreadcrumb({
                  category: 'reload',
                  message: 'GlobalErrorBoundary: ChunkLoadError occurred, reloading the page ...',
                  data: { error: error.message },
                  level: 'info',
                });
                location.reload();
                return true;
              },
              (err) => {
                // Keep track of errors to know how many sessions DO NOT experience an automatic reload.
                logException(err, { tags: { webpack: 'ChunkLoadError' } });
              },
            )
          ) {
            return <></>;
          }
        }
        return (
          <Box
            sx={{ position: 'absolute', width: '100%', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
          >
            <Box sx={{ width: '100%', textAlign: 'center', paddingX: '20px' }}>
              <Typography variant="h5" component="p">
                An error has occurred. Our team is aware of the issue and is working to resolve it.
                <br />
                Please try again later.
              </Typography>
            </Box>
          </Box>
        );
      }}
      beforeCapture={(scope, error, componentStack) => {
        if (isChunkLoadError(error) && !lastChunkLoadError) {
          /*
           * Lower exception to 'info' to track of how many sessions experience a reload when navigating to a
           * section of the app whose dynamically-loading chunk no longer exists due to a re-deployment.
           */
          scope.setLevel('info');
          scope.setTag('webpack', 'ChunkLoadError');
        }
        /*
         * Prop beforeCapture usually passed for testing-purposes as a means to check if a mock was called
         */
        if (beforeCapture) {
          beforeCapture(scope, error, componentStack);
        }
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
