import { Box, CardHeader, Divider, Card, Skeleton } from '@mui/material';
import { NodeSummary } from './NodeSummary';
import { Cluster, useGetClusterMetricsByClusterIdQuery } from '../../../services/clustersApi';
import { ErrorBox } from '../../Common/ErrorBox';
import Slider from '../../Common/Slider';

export function ClusterOverview({
  cluster,
  accountId,
  maxWidth,
}: {
  cluster: Cluster;
  accountId: string;
  maxWidth?: string;
}) {
  const clusterId = cluster.id;
  const {
    data: metrics,
    isLoading,
    isError,
    refetch,
  } = useGetClusterMetricsByClusterIdQuery({
    clusterId,
    accountId,
  });

  if (!isLoading && metrics && metrics.nodes?.length === 0) {
    return (
      <Box
        sx={{
          backgroundColor: 'background.default',
          p: 3,
        }}
      >
        Usage not available
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
      {isLoading ? (
        <Skeleton variant="rounded" height={240} />
      ) : (
        metrics && (
          <Card>
            <CardHeader title="Cluster nodes" />
            <Divider />
            <Slider
              // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
              // @ts-expect-error - migrate <Slider> to TS
              slides={metrics.nodes?.map((node) => (
                // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
                // @ts-expect-error - migrate <Slider> to TS
                <NodeSummary cluster={cluster} nodeMetrics={node} key={node.node_id} />
              ))}
              maxWidth={maxWidth}
            />
          </Card>
        )
      )}
      {!isLoading && isError && <ErrorBox retry={refetch} />}
    </Box>
  );
}
