import { Box } from '@mui/material';
import { createRoute } from '@tanstack/react-router';
import { Suspense, lazy } from 'react';
import { Route as HybridCloudEnvironmentsRoute } from './_hybrid-cloud-environments';
import { AuthenticatedDashboard } from '../components/Authenticated/AuthenticatedDashboard';
import { PageFrame } from '../components/Authenticated/PageFrame';
import { LoadingIndicator } from '../components/Common/LoadingIndicator';
import { MIDDOT_SYMBOL } from '../utils/constants';

export const Route = createRoute({
  meta: () => [
    {
      title: `Hybrid Cloud Environment ${MIDDOT_SYMBOL} Create`,
    },
  ],
  getParentRoute: () => HybridCloudEnvironmentsRoute,
  path: 'create',
  component: HybridCloudCreationComponent,
});

const LazyHybridCloudCreation = lazy(() =>
  import(/* webpackChunkName: "hybrid-cloud-create" */ '../components/PrivateRegions/HybridCloudCreation').then(
    ({ HybridCloudCreation }) => ({
      default: HybridCloudCreation,
    }),
  ),
);

function HybridCloudCreationComponent() {
  return (
    <AuthenticatedDashboard>
      <PageFrame title="Hybrid Cloud">
        <Box sx={{ marginTop: 3 }}>
          <Suspense fallback={<LoadingIndicator />}>
            <LazyHybridCloudCreation />
          </Suspense>
        </Box>
      </PageFrame>
    </AuthenticatedDashboard>
  );
}
