import WarningIcon from '@mui/icons-material/WarningOutlined';
import { Avatar } from '@mui/material';
import { alpha } from '@mui/material/styles';

export const DangerAvatar = () => (
  <Avatar
    sx={{
      backgroundColor: (theme) => alpha(theme.palette.error.main, 0.08),
      color: 'error.main',
      mr: 2,
    }}
  >
    <WarningIcon fontSize="small" />
  </Avatar>
);
