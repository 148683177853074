import PropTypes from 'prop-types';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

export const daysOfWeek = [
  { value: 'Sunday', cronValue: 0 },
  { value: 'Monday', cronValue: 1 },
  { value: 'Tuesday', cronValue: 2 },
  { value: 'Wednesday', cronValue: 3 },
  { value: 'Thursday', cronValue: 4 },
  { value: 'Friday', cronValue: 5 },
  { value: 'Saturday', cronValue: 6 },
];

/**
 * Day of week select - dropdown
 * @param {string} dayOfWeek - selected day of week from state, matching value from daysOfWeek
 * @param setDayOfWeek - function to set the day of week in state
 * @returns {JSX.Element} - MUI FormControl containing a MUI Select
 * @constructor
 */
export const WeekDaySelect = ({ dayOfWeek, setDayOfWeek }) => {
  return (
    <FormControl fullWidth={true} data-testid="schedules-day-of-week-select">
      <InputLabel id="dayOfWeekLabel">Day of Week</InputLabel>
      <Select
        id="dayOfWeek"
        labelId="dayOfWeekLabel"
        label="Day of Week"
        value={dayOfWeek}
        onChange={(event, newValue) => {
          setDayOfWeek(newValue.props.value);
        }}
        defaultValue={daysOfWeek[1].value}
        fullWidth={true}
      >
        {daysOfWeek.map((item) => (
          <MenuItem value={item.value} key={item.value}>
            {item.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

WeekDaySelect.propTypes = {
  dayOfWeek: PropTypes.string.isRequired,
  setDayOfWeek: PropTypes.func.isRequired,
};

export default WeekDaySelect;
