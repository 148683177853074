import { Box, Typography } from '@mui/material';
import { createRoute } from '@tanstack/react-router';
import { Suspense, lazy } from 'react';
import { Route as HybridCloudEnvironmentRoute } from './_hybrid-cloud-environment';
import { AuthenticatedDashboard } from '../components/Authenticated/AuthenticatedDashboard';
import { PageFrame } from '../components/Authenticated/PageFrame';
import { withErrorBoundary } from '../components/Common/ErrorBoundary';
import { LoadingIndicator } from '../components/Common/LoadingIndicator';
import { RedirectToAccountOverview } from '../components/Overview/RedirectToAccountOverview';
import { HybridCloudEnvironmentPreloader } from '../components/PrivateRegions/HybridCloudEnvironmentPreloader';
import { PrivateRegionActionsButton } from '../components/PrivateRegions/PrivateRegionActionsButton';
import { useAccountId } from '../contexts/authenticated-data-context';
import { withPrivilegeRequired } from '../hocs/withPrivilegeRequired';
import { Link } from '../router/Link';

const LazyPrivateRegionDetails = lazy(() =>
  import(/* webpackChunkName: "private-region-details" */ '../components/PrivateRegions/PrivateRegionDetails').then(
    ({ PrivateRegionDetails }) => ({
      default: PrivateRegionDetails,
    }),
  ),
);

const HybridCloudEnvironmentComponent = withErrorBoundary(
  withPrivilegeRequired(function HybridCloudEnvironmentComponent() {
    const accountId = useAccountId();
    const { hybridCloudEnvId } = Route.useParams();

    const breadcrumbs = [
      <Link
        key="1"
        to="/accounts/$accountId/hybrid-cloud"
        params={{ accountId }}
        sx={(theme) => ({ textDecoration: 'none', color: theme.palette.text.primary })}
      >
        Hybrid Cloud Environments
      </Link>,
      <Typography key="2" color="text.primary">
        Hybrid Cloud Environment details
      </Typography>,
    ];
    return (
      <AuthenticatedDashboard>
        <HybridCloudEnvironmentPreloader hybridCloudEnvId={hybridCloudEnvId}>
          <PageFrame
            title="Hybrid Cloud Environments"
            breadcrumbs={breadcrumbs}
            buttons={[<PrivateRegionActionsButton key={0} />]}
          >
            <Box sx={{ marginTop: 3 }}>
              <Suspense fallback={<LoadingIndicator />}>
                <LazyPrivateRegionDetails />
              </Suspense>
            </Box>
          </PageFrame>
        </HybridCloudEnvironmentPreloader>
      </AuthenticatedDashboard>
    );
  }, 'PRIVATE_REGION'),
  {
    fallback: <RedirectToAccountOverview />,
  },
);

export const Route = createRoute({
  meta: () => [
    {
      title: 'Hybrid Cloud Environment',
    },
  ],
  getParentRoute: () => HybridCloudEnvironmentRoute,
  path: '/',
  component: HybridCloudEnvironmentComponent,
});
