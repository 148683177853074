/* eslint-disable max-len, no-restricted-imports, @typescript-eslint/naming-convention, @typescript-eslint/no-non-null-assertion */
import { useAuth0 } from '@auth0/auth0-react';
import { RouterProvider, createRouteMask, createRouter } from '@tanstack/react-router';
import { memo } from 'react';
import { routeTree } from './route-tree';
import { LoadingIndicator } from '../components/Common/LoadingIndicator';
import { NotFound as NotFoundComponent } from '../components/Common/NotFound';
import { AuthContext } from '../routes/__root';

const profileMask = createRouteMask({
  routeTree,
  from: '/accounts/$accountId/profile',
  to: '/profile',
  params: true,
});

export const router = createRouter({
  routeTree,
  routeMasks: [profileMask],
  context: {
    queryClient: undefined,
    auth: {} as AuthContext,
  },
  defaultNotFoundComponent: NotFoundComponent,
  // By default, TanStack Router will show a pending component for loaders that take longer than 1 second to resolve.
  // defaultPendingComponent: () => <LoadingIndicator />,
  // defaultPendingMinMs: 500,
  // defaultPreload: 'intent',
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
  interface HistoryState {
    accountId?: string;
    returnTo?: string;
  }
}

export const Router = memo(function Router() {
  const { isLoading, user, ...auth } = useAuth0();

  if (isLoading) {
    return <LoadingIndicator />;
  }
  return <RouterProvider router={router} context={{ auth: { user: user!, ...auth } }} />;
});
