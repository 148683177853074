import { Box, SxProps, Theme, Typography } from '@mui/material';

/**
 * Creates a MUI Box with an `error-styled` msg and a `Try again` action when retry is provided.
 */
export const ErrorBox = ({
  sx,
  retry,
  msg = 'There was an error loading the data.',
}: {
  sx?: SxProps<Theme>;
  retry?: () => void;
  msg?: string;
}) => (
  <Box sx={{ padding: 1, ...sx }}>
    <Typography sx={(theme) => ({ color: theme.palette.error.main })}>
      {msg}
      {retry && (
        <>
          {' '}
          <span style={{ cursor: 'pointer', fontWeight: 'bold', textDecoration: 'underline' }} onClick={retry}>
            Try again
          </span>
          . Alternatively try reloading the page.
        </>
      )}
    </Typography>
  </Box>
);
