export const INVITE_STATUS = {
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
  PENDING: 'PENDING',
  REVOKED: 'REVOKED',
} as const;

type InviteStatuses = typeof INVITE_STATUS;

export type InviteStatus = InviteStatuses[keyof InviteStatuses];
