import { Box } from '@mui/material';
import { CopyToClipboardText } from '../Common/CopyToClipboardText';

export const ApiKeyCode = ({ apiKey }: { apiKey: string }) => (
  <Box pb={1} data-sentry-mask={true}>
    <Box sx={{ width: '100%', p: 1, display: 'flex' }}>
      <CopyToClipboardText text={apiKey ? apiKey : 'Loading'} useCodeStyle={true} />
    </Box>
  </Box>
);
