import { Cached } from '@mui/icons-material';
import { Box, Button, CircularProgress, ClickAwayListener, Tooltip, Zoom } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useSnackbar } from '../../../hooks/use-qdrant-snackbar';
import { Cluster, useRestartClusterMutation } from '../../../services/clustersApi';
import { parseFetchError } from '../../../services/helpers';
import { getUserMessage } from '../../../utils/errorHandlingUtils';
import { ConfirmationDialogDangerAction } from '../../Common/ConfirmationDialogDangerAction';

const isClusterRestarting = (cluster: Cluster) => cluster.state?.phase === 'Restarting';

export const ClusterRestartButton = ({ cluster, accountId }: { cluster: Cluster; accountId: string }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [isRestarting, setRestarting] = useState(() => isClusterRestarting(cluster));
  const [restartCluster] = useRestartClusterMutation();
  const clusterId = cluster.id;

  useEffect(() => {
    setRestarting(isClusterRestarting(cluster));
  }, [cluster]);

  const handleRestartCluster = useCallback(async () => {
    if (isRestarting) {
      return;
    }
    try {
      await restartCluster({ cluster_id: clusterId, account_id: accountId }).unwrap();
      enqueueSnackbar('Restart triggered successfully', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getUserMessage(parseFetchError(err)), { variant: 'error' });
    }
  }, [accountId, clusterId, enqueueSnackbar, restartCluster, isRestarting]);

  return (
    <>
      <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
        <Tooltip
          PopperProps={{ disablePortal: true }}
          componentsProps={{ tooltip: { style: { top: '-14px' } } }}
          onClose={() => setTooltipOpen(false)}
          open={tooltipOpen}
          title="The cluster is restarting, please wait a few moments"
          leaveDelay={500}
          TransitionComponent={Zoom}
          arrow={true}
        >
          <Box
            onClick={(evt) => {
              if (isRestarting) {
                evt.stopPropagation();
                setTooltipOpen(true);
              }
            }}
          >
            <Button
              onClick={() => setOpen(true)}
              sx={{ minWidth: 136, m: 1 }}
              disabled={isRestarting || !cluster.state?.actionable}
              type="submit"
              variant="outlined"
              color="error"
              endIcon={isRestarting ? <CircularProgress size={20} color="inherit" /> : <Cached fontSize="small" />}
            >
              {`Restart${isRestarting ? 'ing' : ''}`}
            </Button>
          </Box>
        </Tooltip>
      </ClickAwayListener>

      <ConfirmationDialogDangerAction
        open={open}
        onClose={() => setOpen(false)}
        actionTarget={cluster.name}
        title="Restart cluster"
        content="Are you sure you want to restart this cluster?"
        warning="This action will cause a short downtime of your cluster"
        actionName="Restart"
        actionHandler={handleRestartCluster}
      />
    </>
  );
};
