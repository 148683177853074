import PropTypes from 'prop-types';
import {
  Box,
  ClickAwayListener,
  MenuItem,
  TextField,
  Tooltip,
  Zoom,
  ListItemText,
  CircularProgress,
} from '@mui/material';
import { Upgrade } from '@mui/icons-material';
import { useGetClustersVersionsQuery, useUpdateClusterVersionMutation } from '../../../services/clustersApi';
import { getUserMessage } from '../../../utils/errorHandlingUtils';
import { memo, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useTheme } from '@mui/material/styles';
import { ButtonDangerAction } from '../../Common/ButtonDangerAction';
import { useSnackbar } from '../../../hooks/use-qdrant-snackbar';
import { SeverityPill } from '../../Common/SeverityPill';
import { compare, validate } from 'compare-versions';

const ClusterVersionUpdate = ({ accountId, cluster, label }) => {
  const theme = useTheme();
  let latestVersion = 'latest';
  const [currentVersion, setCurrentVersion] = useState(cluster?.state?.version || 'UNKNOWN');
  const [wantedVersion, setWantedVersion] = useState(currentVersion);
  const { enqueueSnackbar } = useSnackbar();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  let isUpdating = false;

  const checkIfUpdating = (cluster) => {
    if (!cluster?.state?.nodes) {
      isUpdating = false;
      return;
    }

    let isCurrentlyUpdating = isUpdating;
    for (const node in cluster.state?.nodes) {
      if (cluster.state?.nodes[node]?.version !== currentVersion) {
        if (!isCurrentlyUpdating) {
          isCurrentlyUpdating = true;
        }
      }
    }
    if (isCurrentlyUpdating !== isUpdating) {
      isUpdating = isCurrentlyUpdating;
    }
  };

  checkIfUpdating(cluster);

  const [updateClusterVersion, { isLoading: isUpdateVersionLoading }] = useUpdateClusterVersionMutation();

  const { data, error, isLoading } = useGetClustersVersionsQuery();
  if (!isLoading && !error && data) {
    latestVersion = data[0].version;
  }

  const upgradeAvailable =
    validate(latestVersion) && validate(currentVersion) && compare(currentVersion, latestVersion, '<');

  const handleTextFieldClick = (e) => {
    // do not let user open select if there is no other versions to select
    // and show tooltip with info
    if (currentVersion === latestVersion || isUpdating) {
      e.stopPropagation();
      setTooltipOpen(true);
    }
  };

  const handleUpdate = async (clusterId, accountId, newVersion) => {
    const { version, ...cluster } = await updateClusterVersion({ clusterId, accountId, version: newVersion })
      .unwrap()
      .catch((e) => {
        console.warn(e);
        enqueueSnackbar(getUserMessage(e), { variant: 'error' });
        return false;
      });
    setCurrentVersion(version);
    checkIfUpdating(cluster);
    return true;
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        m: -1,
      }}
    >
      {!isUpdating && upgradeAvailable && <SeverityPill color="warning">Upgrade available</SeverityPill>}

      <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          componentsProps={{ tooltip: { style: { top: '-30px' } } }}
          onClose={() => setTooltipOpen(false)}
          open={tooltipOpen}
          title={isUpdating ? 'Version updating is in progress' : "You're already on the latest version"}
          leaveDelay={500}
          TransitionComponent={Zoom}
          arrow={true}
        >
          <TextField
            sx={{
              m: 1,
              minWidth: 160,
              '& .Mui-disabled': {
                '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
                  color: theme.palette.text.primary,
                  WebkitTextFillColor: theme.palette.text.primary,
                  border: 0,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: '1px dashed ' + theme.palette.action.disabled,
                },
              },
            }}
            defaultValue={currentVersion}
            value={wantedVersion}
            label={label ? 'Version' : null}
            select={true}
            size="small"
            onChange={(e) => setWantedVersion(e.target.value)}
            onClick={handleTextFieldClick}
            disabled={currentVersion === latestVersion || !cluster.state || isUpdating}
          >
            {/*current version*/}
            <MenuItem value={currentVersion}>
              <ListItemText sx={{ mt: 0, mb: '1px' }}>
                <span>{currentVersion}</span>
                {isUpdating && <CircularProgress size={14} sx={{ ml: 2 }} />}
              </ListItemText>
            </MenuItem>

            {/*latest version*/}
            {currentVersion !== latestVersion && (
              <MenuItem value={latestVersion}>
                <ListItemText sx={{ my: 0, mb: '1px' }}>{latestVersion}</ListItemText>
              </MenuItem>
            )}
          </TextField>
        </Tooltip>
      </ClickAwayListener>

      <ButtonDangerAction
        sx={{ m: 1, mr: 2 }}
        manualConfirmation={true}
        isLoading={isUpdateVersionLoading}
        actionName={'Update'}
        loadingStateName={'Updating'}
        handleDangerAction={() => handleUpdate(cluster.id, accountId, 'latest')}
        buttonIcon={<Upgrade fontSize="small" />}
        targetName={cluster.name}
        dialogTitle={'Updating version'}
        dialogContent={'Updating cluster ' + cluster.name + ' to Qdrant version: ' + wantedVersion}
        dialogWarning={'This action may cause a short downtime.'}
        disabled={currentVersion === wantedVersion || isUpdating || !cluster.state?.actionable}
      />
    </Box>
  );
};

// props validation
ClusterVersionUpdate.propTypes = {
  accountId: PropTypes.string.isRequired,
  cluster: PropTypes.object.isRequired,
  label: PropTypes.bool, // if true, label "Version" will be shown on the top border of the select
};

export default memo(ClusterVersionUpdate);
