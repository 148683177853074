import { styled, Breadcrumbs, Typography } from '@mui/material';
import { createRoute } from '@tanstack/react-router';
import { Suspense, lazy } from 'react';
import { Route as ClustersRoute } from './_clusters';
import { AuthenticatedDashboardAppBar } from '../components/Authenticated/AuthenticatedDashboardNavbar';
import { ClusterCreationEnabled } from '../components/Clusters/ClusterSetup/ClusterCreationEnabled';
import { ClusterLimitReached } from '../components/Clusters/ClusterSetup/ClusterLimitReached';
import { LoadingIndicator } from '../components/Common/LoadingIndicator';
import { useAccountId } from '../contexts/authenticated-data-context';
import { Link } from '../router/Link';
import { clusterCreateSearchSchema, clusterSetupSearchSchema } from '../router/utils';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Create cluster',
    },
  ],
  getParentRoute: () => ClustersRoute,
  path: 'create',
  validateSearch: (search) => clusterCreateSearchSchema.and(clusterSetupSearchSchema).parse(search),
  component: ClusterCreateComponent,
});

const LazyClusterCreate = lazy(() =>
  import(/* webpackChunkName: "cluster-create" */ '../components/Clusters/ClusterSetup/ClusterCreate').then(
    ({ ClusterCreate }) => ({ default: ClusterCreate }),
  ),
);

const StyledHeader = styled(AuthenticatedDashboardAppBar, { shouldForwardProp: (prop) => prop !== 'noSidebar' })`
  flex-direction: row;
  align-items: center;
`;

const StyledBreadcrumbs = styled(Breadcrumbs)(
  ({ theme }) => `
    margin-inline: auto;
    padding: 0 ${theme.spacing(5)};
    min-width: ${theme.breakpoints.values.md}px;
    max-width: ${theme.breakpoints.values.lg}px;
    flex: 1;
  `,
);

const StyledMain = styled('main')(
  ({ theme }) => `
    margin: ${theme.spacing(5)} auto 0;
    padding: ${theme.spacing(8)} ${theme.spacing(5)} 0;
    min-width: ${theme.breakpoints.values.md}px;
    max-width: ${theme.breakpoints.values.lg}px;
    min-height: 100vh;
  `,
);

function ClusterCreateComponent() {
  const accountId = useAccountId();

  return (
    <>
      <StyledHeader noSidebar={true}>
        <StyledBreadcrumbs separator="›" aria-label="breadcrumb">
          <Link key="1" to="/accounts/$accountId/clusters" params={{ accountId }}>
            Clusters
          </Link>
          <Typography key="2" color="text.primary">
            Create new cluster
          </Typography>
        </StyledBreadcrumbs>
      </StyledHeader>
      <StyledMain>
        <ClusterCreationEnabled>
          <ClusterLimitReached>
            <Suspense fallback={<LoadingIndicator />}>
              <LazyClusterCreate accountId={accountId} />
            </Suspense>
          </ClusterLimitReached>
        </ClusterCreationEnabled>
      </StyledMain>
    </>
  );
}
