import { Box, SxProps } from '@mui/material';

/**
 * Warning text for disabled cluster creation
 */
export const ClusterCreationDisabledWarning = ({ sx }: { sx: SxProps }) => (
  <Box sx={{ color: 'error.light', ...sx }}>
    Cluster creation is temporarily disabled - we'll try to resolve this asap
  </Box>
);
