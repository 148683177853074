import DiskIcon from '@mui/icons-material/Album';
import CpuIcon from '@mui/icons-material/Calculate';
import MemoryIcon from '@mui/icons-material/Memory';
import { compare } from 'fast-json-patch';
import { ByteSizeFormatterOptions, humanReadableSizeFormatter } from './Metrics/utils';
import { CLOUD_PROVIDER_IMGS, QdrantConfigurationInputs } from './constants';
import { CloudProvider } from '../../services/clustersApi';
import { ComponentSchema } from '../../utils/api-schema-utils';
import { QdrantConfiguration } from '../../utils/cluster-utils';
import { ImageThemed } from '../Common/ImageThemed';

type ResourceType = ComponentSchema<'ResourceType'> | 'ram_rss';

export function formatResourcesOptions(amount: number, type?: ResourceType, nodes: number = 1) {
  if (nodes > 1) {
    switch (type) {
      case 'cpu':
        return `vCPUs: ${amount / 1000} x ${nodes} = ${(amount / 1000) * nodes}`;
      case 'ram':
      case 'ram_rss':
      case 'disk':
        return `${type.toUpperCase()}: ${amount}GB x ${nodes} = ${amount * nodes}GB`;
      default:
        throw new Error('Invalid resource option type');
    }
  }
  switch (type) {
    case 'cpu':
      return `vCPUs: ${amount / 1000}`;
    case 'ram':
    case 'ram_rss':
    case 'disk':
      return `${type.toUpperCase()}: ${amount}GB`;
    default:
      throw new Error('Invalid resource option type');
  }
}

export function getResourceIcon(type?: ResourceType, color: 'secondary' | 'info' = 'secondary', size = '0.7em') {
  switch (type) {
    case 'cpu':
      return <CpuIcon color={color} sx={{ verticalAlign: 'bottom', width: size, height: size }} />;
    case 'ram':
    case 'ram_rss':
      return <MemoryIcon color={color} sx={{ verticalAlign: 'bottom', width: size, height: size }} />;
    case 'disk':
      return <DiskIcon color={color} sx={{ verticalAlign: 'bottom', width: size, height: size }} />;
    default:
      throw new Error('Invalid resource type');
  }
}

/**
 * Returns img jsx element with cloud provider logo adapted to the theme mode change
 */
export function getProviderImage(cloudProvider: CloudProvider, theme: object) {
  const style = {
    width: '100%',
    height: 'auto',
  };
  const imgData = CLOUD_PROVIDER_IMGS[cloudProvider];

  return imgData.themed ? (
    <ImageThemed src={imgData.src} theme={theme} alt={cloudProvider} style={style} />
  ) : (
    <img alt={cloudProvider} src={imgData.src} style={style} title={cloudProvider} />
  );
}

export function bytesToGigabytes(
  bytes: number,
  { unitType = 'IEC', decimalPlaces = 1 }: ByteSizeFormatterOptions = {},
) {
  if (bytes > Number.MAX_SAFE_INTEGER) {
    throw new RangeError('The number is too big to be represented as a JavaScript number.');
  }
  const unit = unitType === 'SI' ? 1000 : 1024;
  const value = (bytes / unit ** 3).toFixed(decimalPlaces);

  return `${value} GB`;
}

export function transformGigabytesToBytes(gigabytes = 0) {
  return gigabytes * 1024 ** 3;
}

export function transformBytesToGigabytes(bytes = 0) {
  return bytes / 1024 ** 3;
}

type Resource = {
  base?: number;
  extra?: number;
  complimentary?: number;
};

/**
 * Returns the total amount of a resource by adding up each part (base, extra & complimentary).
 */
export function getResourceTotal(resource: Resource) {
  return Object.values(resource).reduce((total, part) => total + part, 0);
}

/**
 * Given a float value, it will format it to the amount of digits passed as `fixedTo`.
 * and will add a suffix (e.g `GB`). If the value is an integer it will return it without decimals (e.g `2 GB`).
 */
export const formatFloatWithSuffix = ({
  fixedTo,
  value = 0,
  suffix,
}: {
  fixedTo: number;
  value?: number;
  suffix?: string;
}) => {
  const readableValue = value % 1 > 0 ? parseFloat(value.toFixed(fixedTo)) : Math.trunc(value);
  let formattedValue = `${readableValue}`;
  if (suffix) {
    formattedValue += ` ${suffix}`;
  }
  return formattedValue;
};

export function formatMetricAmount(type: ResourceType, metric: number): string {
  if (!Number.isFinite(metric)) {
    return 'N/A';
  }
  if (type === 'cpu') {
    return `${parseFloat(metric.toFixed(5))} vCores`;
  }
  return humanReadableSizeFormatter(metric, { decimalPlaces: 2 });
}

/**
 * It creates a QdrantConfiguration json object based on the current configuration
 * to later compare with the cluster's original qdrant config in order to output a
 * list of patches to provide to the API.
 */
export function getQdrantConfigurationPatches(
  data: QdrantConfigurationInputs,
  originalConfig?: QdrantConfiguration | null,
) {
  // Create the new config using existing configuration (in case there is anything set
  // that we are not changing within this form) and replace / remove those that
  // were changed or unset respectively.
  const newConfigObj: QdrantConfiguration = structuredClone(originalConfig) ?? {};

  if (data.apiKeySecretKey || data.readOnlyApiKeySecretKey) {
    if (!newConfigObj.service) {
      newConfigObj.service = {};
    }

    if (data.apiKeySecretKey) {
      newConfigObj.service.api_key = {
        secretKeyRef: {
          key: data.apiKeySecretKey,
          name: data.apiKeySecretName,
        },
      };
    }

    if (data.readOnlyApiKeySecretKey) {
      newConfigObj.service.read_only_api_key = {
        secretKeyRef: {
          key: data.readOnlyApiKeySecretKey,
          name: data.readOnlyApiKeySecretName,
        },
      };
    }
  }

  if (data.tlsCertSecretKey || data.tlsKeySecretKey) {
    if (!newConfigObj.tls) {
      newConfigObj.tls = {};
    }

    if (data.tlsCertSecretKey) {
      newConfigObj.tls.cert = {
        secretKeyRef: {
          key: data.tlsCertSecretKey,
          name: data.tlsCertSecretName,
        },
      };
    }
    if (data.tlsKeySecretKey) {
      newConfigObj.tls.key = {
        secretKeyRef: {
          key: data.tlsKeySecretKey,
          name: data.tlsKeySecretName,
        },
      };
    }
  }

  // Remove the api_key and read_only_api_key if the secret key is not set
  // Also remove the service object if there is no config left.
  if (newConfigObj.service) {
    if (!data.apiKeySecretKey) {
      delete newConfigObj.service.api_key;
    }
    if (!data.readOnlyApiKeySecretKey) {
      delete newConfigObj.service.read_only_api_key;
    }
    if (!Object.keys(newConfigObj.service).length) {
      delete newConfigObj.service;
    }
  }

  if (newConfigObj.tls) {
    if (!data.tlsCertSecretKey) {
      delete newConfigObj.tls.cert;
    }

    if (!data.tlsKeySecretKey) {
      delete newConfigObj.tls.key;
    }

    if (!Object.keys(newConfigObj.tls).length) {
      delete newConfigObj.tls;
    }
  }

  if (data.logLevel) {
    newConfigObj.log_level = data.logLevel;
  } else {
    delete newConfigObj.log_level;
  }

  return compare(originalConfig ?? {}, newConfigObj);
}
