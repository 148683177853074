// eslint-disable-next-line no-restricted-imports
import { useTheme } from '@mui/material/styles';
import { memo } from 'react';
import Chart from 'react-apexcharts';
import { RAM_LABEL } from './utils';

type Item = {
  color: string;
  data: number;
  formattedValue: string;
  total: string;
  label: string;
};

/*
 * Below this value, the radial bar doesn't render any pixel,
 * so we need to create a fake value to render it for the user.
 */
const imperceptibleValue = 0.25;
const createImperceptibleValue = (value: number) =>
  ({
    valueOf() {
      return imperceptibleValue;
    },
    toString() {
      return value;
    },
  }) as unknown as number;

export const NodeUsageSummaryChart = memo(function NodeUsageSummaryChart({ data }: { data: Item[] }) {
  const theme = useTheme();
  const chartOptions: ApexCharts.ApexOptions = {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    colors: data.map((item) => item.color),
    fill: {
      opacity: 1,
    },
    labels: [RAM_LABEL, 'RAM', 'CPU', 'Disk'],
    plotOptions: {
      radialBar: {
        track: {
          background: theme.palette.background.default,
        },
        dataLabels: {
          value: {
            formatter(val) {
              return `${Number(val).toFixed(2)}%`;
            },
          },
        },
      },
    },
    theme: {
      mode: theme.palette.mode,
    },
  };

  return (
    <Chart
      height={300}
      options={chartOptions}
      series={data.map((item) => (item.data < imperceptibleValue ? createImperceptibleValue(item.data) : item.data))}
      type="radialBar"
    />
  );
});
