import { ButtonProps, Tooltip, Zoom } from '@mui/material';
import { useNavigate } from '@tanstack/react-router';
import { ReactElement, cloneElement, useCallback } from 'react';
import { useTrackInteractionEvent } from '../../../hooks/use-event-tracking';
import { clusterCreateSearch } from '../../../router/utils';
import { Cluster } from '../../../services/clustersApi';
import { FREE_TIER_CLOUD_PROVIDER } from '../constants';
import { useClusterScaleConsent } from '../hooks/use-cluster-scale-consent';

export const ClusterScaleAction = ({
  cluster,
  accountId,
  trackingLocation,
  children,
}: {
  cluster: Cluster;
  accountId: string;
  trackingLocation: string;
  children: ReactElement<ButtonProps>;
}) => {
  const navigate = useNavigate();
  const trackScaleClick = useTrackInteractionEvent({
    label: 'Scale',
    action: 'clicked',
  });

  const handleClick = useCallback(() => {
    trackScaleClick({ location: trackingLocation });
    void navigate({
      to: '/accounts/$accountId/clusters/$clusterId/scale',
      params: { accountId, clusterId: cluster.id },
      search: clusterCreateSearch(),
    });
  }, [accountId, cluster.id, trackingLocation, navigate, trackScaleClick]);

  const clusterScaleConsent = useClusterScaleConsent({
    accountId,
    cluster,
    freeTierCloudProvider: FREE_TIER_CLOUD_PROVIDER,
  });

  if (!cluster.configuration?.node_configuration || !cluster.configuration.num_nodes) {
    return null; // TODO: Why is this necessary Bettinelli?
  }

  const button = cloneElement(children, {
    onClick: handleClick,
    disabled: clusterScaleConsent !== 'allowed' || !cluster.state?.actionable,
  });

  if (clusterScaleConsent === 'disallowed') {
    return (
      <Tooltip
        title={
          `Free tier clusters on ${cluster.cloud_provider.toUpperCase()} ` +
          "can't be scaled up when paid by a different marketplace."
        }
        TransitionComponent={Zoom}
        arrow={true}
      >
        {cloneElement(button, {
          component: 'div',
          style: {
            pointerEvents: 'all',
          },
        })}
      </Tooltip>
    );
  }

  return button;
};
