import { styled, AppBar, Box, IconButton, Toolbar } from '@mui/material';
import { memo } from 'react';
import { AccountButton } from './Account/AccountButton';
import { NotificationsButton } from './Notifications/NotificationsButton';
import { Menu as MenuIcon } from '../../icons/menu';
import { AccountSelector } from '../Common/AccountSelector';

export const AuthenticatedDashboardAppBar = styled(AppBar, {
  shouldForwardProp: (propName) => propName !== 'noSidebar',
})<{
  noSidebar?: boolean;
}>(({ theme, noSidebar }) => ({
  backgroundColor: theme.palette.background.paper,
  minHeight: theme.spacing(8),
  ...(!noSidebar && {
    [theme.breakpoints.up('lg')]: {
      left: '280px',
      width: 'calc(100% - 280px)',
    },
  }),
  ...(theme.palette.mode === 'light'
    ? {
        boxShadow: theme.shadows[3],
      }
    : {
        backgroundColor: theme.palette.background.paper,
        borderBottomColor: theme.palette.divider,
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        boxShadow: 'none',
      }),
}));

export const AuthenticatedDashboardNavbar = memo(function AuthenticatedDashboardNavbar({
  onOpenSidebar,
}: {
  onOpenSidebar: () => void;
}) {
  return (
    <AuthenticatedDashboardAppBar>
      <Toolbar
        disableGutters={true}
        sx={{
          left: 0,
          px: 2,
        }}
      >
        <IconButton
          onClick={onOpenSidebar}
          sx={{
            display: {
              xs: 'inline-flex',
              lg: 'none',
            },
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <AccountSelector />
        <Box sx={{ flexGrow: 1 }} />
        <NotificationsButton />
        <AccountButton />
      </Toolbar>
    </AuthenticatedDashboardAppBar>
  );
});
