import CloseIcon from '@mui/icons-material/Close';
import { Portal, Snackbar, Alert, Button, IconButton } from '@mui/material';
import * as Sentry from '@sentry/react';
import { memo, useEffect, useState } from 'react';

export const VersionAlert = memo(function VersionAlert({ imageTag }: { imageTag: string }) {
  const [open, setOpen] = useState(false);
  const [version, setVersion] = useState(imageTag);

  useEffect(() => {
    if (version !== imageTag) {
      setOpen(true);
      setVersion(imageTag);
    }
  }, [version, imageTag]);

  return !open ? null : (
    <Portal>
      <Snackbar open={true}>
        <Alert
          severity="info"
          action={
            <>
              <Button
                size="small"
                onClick={() => {
                  Sentry.addBreadcrumb({
                    category: 'reload',
                    message: 'VersionAlert: REFRESH button clicked to reload the page',
                    level: 'info',
                  });
                  window.location.reload();
                }}
              >
                REFRESH
              </Button>
              <IconButton
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
          sx={{
            '&>.MuiAlert-action': {
              padding: 0,
              marginLeft: 1,
            },
          }}
        >
          A new version of Qdrant Cloud is available
        </Alert>
      </Snackbar>
    </Portal>
  );
});
