import { zodResolver } from '@hookform/resolvers/zod';
import { Box, CircularProgress, Grid, Link, MenuItem, Select, Switch, TextField, Typography } from '@mui/material';
import { FocusEvent, memo, useCallback, useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { z } from 'zod';
import {
  calculatorCreateSearch,
  calculatorSchema,
  getHighestMemoryAvailable,
  getMinimumRequiredMemory,
  getPackageByMemory,
  MAX_DIMENSIONS,
  MAX_REPLICAS,
  MAX_VECTORS,
  Quantization,
  QUANTIZATIONS,
} from './utils';
import { Route as CalculatorRoute } from '../../routes/calculator';
import { useGetPublicPackagesQuery } from '../../services/bookingApi';
import { CloudProvider, CloudRegion } from '../../services/clustersApi';
import { BookingPackageOutput } from '../../utils/booking-utils';
import { ZERO_WIDTH_SPACE } from '../../utils/constants';
import { UnrestrictedClusterProviderList } from '../Clusters/ClusterSetup/ClusterProviderList';
import { ClusterRegionList, getDefaultRegion } from '../Clusters/ClusterSetup/ClusterRegionList';
import { StyledItemHeader } from '../Clusters/ClusterSetup/styled-components';
import { ErrorBox } from '../Common/ErrorBox';

type CalculationSchema = z.infer<typeof calculatorSchema>;

export const CalculatorForm = memo(function CalculatorForm({
  onSubmit,
}: {
  onSubmit: (props: {
    recommendedPackage: BookingPackageOutput;
    nodes: number;
    replicas: number;
    provider: CloudProvider;
    region: CloudRegion;
    requiredMemory: number;
    quantization: Quantization;
  }) => void;
}) {
  const {
    provider: preSelectedProvider,
    region: preSelectedRegion,
    vectors: preselectedVectors,
    dimension: preselectedDimension,
    replicas: preselectedReplicas,
    storageOptimized: preselectedStorageOptimized,
    quantization: preselectedQuantization,
  } = CalculatorRoute.useSearch();

  const [provider, setProvider] = useState<CloudProvider>(preSelectedProvider);
  const [region, setRegion] = useState<CloudRegion>(preSelectedRegion);
  const { data: packages, isFetching, isError, refetch } = useGetPublicPackagesQuery({ provider, region });
  const {
    register,
    control,
    formState: { isValid },
  } = useForm<CalculationSchema>({
    mode: 'all',
    resolver: zodResolver(calculatorSchema),
    defaultValues: {
      vectors: preselectedVectors ?? 0,
      dimension: preselectedDimension ?? 0,
      storageOptimized: preselectedStorageOptimized,
      replicas: preselectedReplicas ?? 1,
      quantization: preselectedQuantization,
    },
  });
  const values = useWatch({ control });

  const navigate = CalculatorRoute.useNavigate();

  useEffect(() => {
    /**
     * TODO - hoist useForm() to parent component and pass values as props to avoid rendering this component.
     * @see https://github.com/qdrant/cloud-pm/issues/1349
     */
    void navigate({ search: calculatorCreateSearch({ provider, region, ...values }) });
  }, [navigate, provider, region, values]);

  const handleFocus = useCallback((evt: FocusEvent<HTMLInputElement>) => {
    if (evt.target.value === '0') {
      evt.target.select();
    }
  }, []);

  const handleRegionChange = useCallback((value: CloudRegion) => {
    setRegion(value);
  }, []);

  const handleProviderChange = useCallback((value: CloudProvider) => {
    setProvider(value);
    setRegion(getDefaultRegion(value));
  }, []);

  const handleSubmit = useCallback(() => {
    const { replicas, vectors, dimension, storageOptimized, quantization } = values;
    if (!isValid || !vectors || !dimension || !replicas || !quantization || isFetching || !packages) {
      return;
    }

    const minimumRequiredMemory = getMinimumRequiredMemory({ vectors, dimension, storageOptimized, quantization });

    const recommendedPackage = getPackageByMemory(minimumRequiredMemory, packages);

    if (!recommendedPackage) {
      return;
    }

    const maxRam = getHighestMemoryAvailable(packages);
    const requiredNodes = Math.ceil(minimumRequiredMemory / maxRam);

    onSubmit({
      recommendedPackage,
      nodes: requiredNodes,
      replicas,
      provider,
      region,
      quantization,
      requiredMemory: minimumRequiredMemory,
    });
  }, [values, onSubmit, packages, provider, region, isFetching, isValid]);

  useEffect(() => {
    handleSubmit();
  }, [region, handleSubmit]);

  return (
    <Box>
      <Typography variant="h4" component="h1" mb={2}>
        Capacity & Pricing Preview
      </Typography>
      <Typography color="textSecondary" variant="body2" sx={{ mb: 2 }}>
        The cluster capacity calculator helps you to estimate the capacity of a cluster and predict the price. Choose
        the provider and region you want to get the estimates on. The calculation is approximate. A more accurate
        calculation depends on additional factors.{' '}
        <Link href="https://qdrant.tech/documentation/cloud/capacity/" target="_blank">
          Read more about capacity and sizing
        </Link>
        .
      </Typography>
      <form onSubmit={handleSubmit} aria-label="Qdrant Cloud Calculator Form">
        <Box sx={{ mb: 3 }}>
          <StyledItemHeader>
            <Typography variant="h6">Provider</Typography>
          </StyledItemHeader>
          <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
            <UnrestrictedClusterProviderList onChange={handleProviderChange} value={provider} />
            {isFetching && <CircularProgress size={20} />}
          </Box>
          {provider !== 'private' && (
            <>
              <StyledItemHeader sx={{ mt: 3 }}>
                <Typography variant="h6" id="region-selector-label">
                  Region
                </Typography>
              </StyledItemHeader>
              <Grid container={true} direction="row" columns={3} flexWrap="nowrap">
                <Grid item={true} xs={2}>
                  <ClusterRegionList
                    ariaLabelledBy="region-selector-label"
                    provider={provider}
                    region={region}
                    onChange={handleRegionChange}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Box>
        {isError && <ErrorBox msg="Failed to load prices" retry={refetch} />}
        <Grid item={true}>
          <Typography variant="h6" id="dense-vectors-label">
            Dense vectors
          </Typography>
          <Typography color="textSecondary" variant="body2">
            Please enter the number of dense vectors you plan to serve and their dimensionality. While the example
            calculation includes some room for payload indexes, the actual memory usage may vary.
          </Typography>
          <br />
          <Grid container={true} spacing={3}>
            <Grid item={true} md={6} xs={12}>
              <Controller
                control={control}
                name="vectors"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    disabled={field.disabled}
                    {...register('vectors')}
                    type="number"
                    label="Number of dense vectors"
                    onFocus={handleFocus}
                    helperText={error?.message ?? ZERO_WIDTH_SPACE}
                    error={Boolean(error)}
                    fullWidth={true}
                    inputProps={{ min: 0, max: MAX_VECTORS }}
                  />
                )}
              />
            </Grid>
            <Grid item={true} md={6} xs={12}>
              <Controller
                control={control}
                name="dimension"
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    disabled={field.disabled}
                    {...register('dimension')}
                    type="number"
                    label="Vector Dimension"
                    onFocus={handleFocus}
                    helperText={error?.message ?? ZERO_WIDTH_SPACE}
                    error={Boolean(error)}
                    fullWidth={true}
                    inputProps={{ min: 0, max: MAX_DIMENSIONS }}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid item={true} mb={2}>
            <Typography variant="h6" id="dense-vectors-label">
              Sparse vectors
            </Typography>
            <Typography color="textSecondary" variant="body2">
              If you plan to use sparse vectors, you will need to add additional memory depending on the amount of
              tokens.{' '}
              <Link href="https://qdrant.tech/documentation/concepts/vectors/#sparse-vectors" target="_blank">
                Learn more
              </Link>
              .
            </Typography>
          </Grid>

          <Grid item={true}>
            <Typography gutterBottom={true} variant="h6">
              Replication
            </Typography>
            <Typography color="textSecondary" variant="body2">
              Qdrant supports replication on collection level. You can achieve higher throughput and ensure high
              availability using replication.{' '}
              <Link href="https://qdrant.tech/documentation/distributed_deployment/#replication" target="_blank">
                Learn more
              </Link>
              .
            </Typography>
            <br />

            <Controller
              control={control}
              name="replicas"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  disabled={field.disabled}
                  {...register('replicas')}
                  type="number"
                  label="Replication Factor"
                  helperText={error?.message ?? ZERO_WIDTH_SPACE}
                  error={Boolean(error)}
                  fullWidth={true}
                  inputProps={{ min: 0, max: MAX_REPLICAS }}
                />
              )}
            />
          </Grid>

          <Grid item={true} mb={2}>
            <Typography gutterBottom={true} variant="h6" id="storage-optimized-label">
              Offloading vectors to disk
            </Typography>
            <Typography color="textSecondary" variant="body2">
              Memmaps can be used to store vectors on disk. Cached in RAM on retrieval. Up to 70% Memory usage
              reduction.{' '}
              <Link href="https://qdrant.tech/documentation/storage/" target="_blank">
                Learn more
              </Link>
              .
            </Typography>

            <Controller
              control={control}
              name="storageOptimized"
              render={({ field }) => (
                <Switch
                  {...register('storageOptimized')}
                  checked={field.value}
                  disabled={field.disabled}
                  edge="start"
                  aria-labelledby="storage-optimized-label"
                />
              )}
            />
          </Grid>

          <Grid item={true} mb={2}>
            <Typography gutterBottom={true} variant="h6" id="quantization-label">
              Quantization
            </Typography>
            <Typography color="textSecondary" variant="body2" sx={{ mb: 2 }}>
              Quantizations can reduce the amount of required RAM and speed up searches by compressing vectors.{' '}
              <Link href="https://qdrant.tech/documentation/guides/quantization/" target="_blank">
                Learn more
              </Link>
              .
            </Typography>

            <Controller
              control={control}
              name="quantization"
              render={({ field }) => (
                <Select
                  {...register('quantization')}
                  disabled={field.disabled}
                  value={field.value}
                  sx={{ minWidth: '110px' }}
                  aria-labelledby="quantization-label"
                  // `combobox` role is, by default, assigned to the first child of the Select component
                  // but the aria-labelledby gets assigned to the wrapper div, so we need to manually set the role
                  role="combobox"
                >
                  {QUANTIZATIONS.map((quantization) => (
                    <MenuItem key={quantization} value={quantization}>
                      {quantization}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  );
});
