import { Box, Skeleton, Typography } from '@mui/material';
import { ResourceType } from '../../../../../utils/booking-utils';
import { toPackageResourcesObject } from '../../../../Clusters/ClusterSetup/helpers';
import { formatResourcesOptions, getResourceIcon } from '../../../../Clusters/helpers';
import { useWelcomeStepperContext } from '../WelcomeStepperProvider';

const ResourceItem = ({ resourceType, resourceValue }: { resourceType: ResourceType; resourceValue: number }) => {
  if (!resourceValue) {
    return null;
  }

  const ResourceIcon = () => getResourceIcon(resourceType, 'info');

  const formattedResourceValue = formatResourcesOptions(resourceValue, resourceType);

  return (
    <Box display="flex" alignItems="center" mr={2}>
      <ResourceIcon />
      <Typography component="span" variant="body2" color="textSecondary" paddingLeft="3px">
        {formattedResourceValue}
      </Typography>
    </Box>
  );
};

export const ResourceIcons = () => {
  const { freeBookingPackage, isLoadingBookingData } = useWelcomeStepperContext();

  if (isLoadingBookingData) {
    return <Skeleton animation={'pulse'} height={24} />;
  }

  if (!freeBookingPackage) {
    return null;
  }

  const resourceObject = toPackageResourcesObject(freeBookingPackage);

  return (
    <Box display="flex" minHeight="24px" lineHeight="24px">
      <Box display="flex" alignItems="flex-start">
        <ResourceItem resourceType={'cpu'} resourceValue={resourceObject.cpu!} />
        <ResourceItem resourceType={'ram'} resourceValue={resourceObject.memory!} />
        <ResourceItem resourceType={'disk'} resourceValue={resourceObject.disk!} />
      </Box>
      <Box display="flex" alignItems="center">
        <Typography component={'span'} variant={'body2'} color="textSecondary">
          x 1 Node
        </Typography>
      </Box>
    </Box>
  );
};
