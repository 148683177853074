/**
 * Returns the user roles for a table cell.
 * @param {
 *  accoutOwner: string;
 *  userEmail: string;
 *  roles: Array<{
 *    id: string;
 *    name: string;
 *  }>
 *  theme: object;
 * }
 * @returns <React.ReactNode>
 */
export const getUserRoles = ({ isAccountOwner, roles, theme }) => (
  <>
    {isAccountOwner && (
      <>
        <span style={{ fontWeight: 'bold', color: theme.palette.primary.main }}>Owner</span>
        <span>, </span>
      </>
    )}
    {roles.map((role, index) => {
      return (
        <span key={`${role.id}-role-${index}`}>
          {role.name}
          {index < roles.length - 1 ? ', ' : ''}
        </span>
      );
    })}
  </>
);
