import { Button } from '@mui/material';
import { useCreateStripeSession } from './hooks/use-create-stripe-session';
import { useAuthenticatedData } from '../../contexts/authenticated-data-context';
import { useSnackbar } from '../../hooks/use-qdrant-snackbar';
import { Pencil } from '../../icons/pencil';
import { Trash } from '../../icons/trash';
import { isFetchApiError, parseFetchError } from '../../services/helpers';
import { useRemovePaymentInformationMutation } from '../../services/paymentApi';

export const PaymentConnectedStripeActions = ({
  accountId,
  paymentInformationId,
}: {
  accountId: string;
  paymentInformationId: string;
}) => {
  const createSessionAndRedirect = useCreateStripeSession({ accountId, shouldRedirect: true });
  const [mutation] = useRemovePaymentInformationMutation();
  const { account } = useAuthenticatedData();
  const { enqueueSnackbar } = useSnackbar();
  // Endpoint not available in production, and restricted to qdrant.com emails
  const canRemovePaymentInformation =
    window.__QDRANT_CLOUD__.env !== 'production' && account.owner_email?.endsWith('@qdrant.com');

  return (
    <>
      <Button variant="text" size="small" startIcon={<Pencil fontSize="small" />} onClick={createSessionAndRedirect}>
        Modify
      </Button>
      {canRemovePaymentInformation && (
        <Button
          color="error"
          variant="text"
          size="small"
          sx={{ ml: 2 }}
          startIcon={<Trash fontSize="small" />}
          onClick={async () => {
            try {
              await mutation({ accountId, paymentInformationId }).unwrap();
            } catch (e) {
              const error = parseFetchError(e);
              if (isFetchApiError(error)) {
                if (error.code === 'E1030') {
                  enqueueSnackbar('Payment information cannot be removed because there are existing clusters.', {
                    variant: 'error',
                  });
                }
              }
              console.error(e);
            }
          }}
        >
          Delete (admin only)
        </Button>
      )}
    </>
  );
};
