import { useLocation, useNavigate } from '@tanstack/react-router';
import { useEffect } from 'react';
import { PAYMENT_INFO_SECTION_ELEMENT_ID } from './use-create-marketplace-redirect';

export const useScrollToPaymentRedirect = () => {
  const navigate = useNavigate();
  const { hash } = useLocation();

  useEffect(() => {
    if (hash === PAYMENT_INFO_SECTION_ELEMENT_ID) {
      const element = document.getElementById(PAYMENT_INFO_SECTION_ELEMENT_ID);
      element?.scrollIntoView({ behavior: 'smooth' });
      void navigate({ hash: undefined, search: (prev) => prev, replace: true });
    }
  }, [navigate, hash]);
};
