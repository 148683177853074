import { Box } from '@mui/material';
import { withErrorBoundary } from '@sentry/react';
import { createRoute } from '@tanstack/react-router';
import { Suspense, lazy } from 'react';
import { Route as HybridCloudEnvironmentsRoute } from './_hybrid-cloud-environments';
import { AuthenticatedDashboard } from '../components/Authenticated/AuthenticatedDashboard';
import { PageFrame } from '../components/Authenticated/PageFrame';
import { LoadingIndicator } from '../components/Common/LoadingIndicator';
import { RedirectToAccountOverview } from '../components/Overview/RedirectToAccountOverview';
import { withPrivilegeRequired } from '../hocs/withPrivilegeRequired';

const LazyHybridCloudEnvironments = lazy(() =>
  import(/* webpackChunkName: "hybrid-cloud-environments" */ '../components/PrivateRegions/PrivateRegionsList').then(
    ({ PrivateRegionsList }) => ({
      default: PrivateRegionsList,
    }),
  ),
);

const HybridCloudEnvironmentsComponent = withErrorBoundary(
  withPrivilegeRequired(function HybridCloudEnvironments() {
    return (
      <AuthenticatedDashboard>
        <PageFrame title="Hybrid Cloud Environments">
          <Box sx={{ marginTop: 3 }}>
            <Suspense fallback={<LoadingIndicator />}>
              <LazyHybridCloudEnvironments />
            </Suspense>
          </Box>
        </PageFrame>
      </AuthenticatedDashboard>
    );
  }, 'PRIVATE_REGION'),
  {
    fallback: <RedirectToAccountOverview />,
  },
);

export const Route = createRoute({
  meta: () => [
    {
      title: 'Hybrid Cloud Environments',
    },
  ],
  getParentRoute: () => HybridCloudEnvironmentsRoute,
  path: '/',
  component: HybridCloudEnvironmentsComponent,
});
