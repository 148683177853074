import { ResourceOptionType } from '../../../services/bookingApi';
import { CLOUD_PROVIDER_MAP } from '../constants';

export const BACKUPS_DATE_FORMAT = 'MMMM d, yyyy h:mm a';

export const AVAILABLE_PROVIDERS_FOR_BACKUP = [
  CLOUD_PROVIDER_MAP.AWS,
  CLOUD_PROVIDER_MAP.GCP,
  CLOUD_PROVIDER_MAP.PRIVATE,
  CLOUD_PROVIDER_MAP.AZURE,
];

export const RESOURCE_TYPE_SNAPSHOT: ResourceOptionType = 'snapshot';
