import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ClickAwayListener, Grow, IconButton, ListItemIcon, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import { ReactElement, useRef, useState } from 'react';

type TableRowActionsProps<T> = {
  actions: {
    name: string;
    getHandler: (data: T) => () => void;
    isDisabled?: boolean | ((data: T) => boolean);
    icon?: ReactElement;
  }[];
  data: T;
  ariaLabel: string;
};

export function TableRowActions<T>({ data, actions, ariaLabel }: TableRowActionsProps<T>) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen((open) => !open);

  return (
    <>
      <IconButton ref={anchorRef} onClick={toggleOpen} aria-label={ariaLabel} aria-describedby="table-row-actions">
        <MoreHorizIcon fontSize="small" />
      </IconButton>
      <Popper anchorEl={anchorRef.current} open={open} transition={true} id="table-row-actions">
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper sx={{ padding: 1 }}>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList id="split-button-menu">
                  {actions.map(({ name, getHandler, isDisabled, icon }) => {
                    const disabled = typeof isDisabled === 'function' ? isDisabled(data) : isDisabled ?? false;
                    const handler = getHandler(data);
                    return (
                      <MenuItem
                        disabled={disabled}
                        key={name}
                        onClick={() => {
                          handler();
                          toggleOpen();
                        }}
                        sx={{ paddingX: 1, display: 'flex', gap: 2 }}
                      >
                        {icon && <ListItemIcon sx={{ marginRight: 0 }}>{icon}</ListItemIcon>}
                        <span>{name}</span>
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
