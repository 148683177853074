import NodesIcon from '@mui/icons-material/AccountTree';
import DiskIcon from '@mui/icons-material/Album';
import AdditionalDiskIcon from '@mui/icons-material/AlbumOutlined';
import CpuIcon from '@mui/icons-material/Calculate';
import QuantizationIcon from '@mui/icons-material/Compress';
import MemoryIcon from '@mui/icons-material/Memory';
import { List, ListItem, TypographyProps } from '@mui/material';
import { Quantization } from '../../../Calculator/utils';
import { ClusterConfiguration } from '../ClusterConfiguration/ClusterConfigurationContext';
import { fromHzToGHz } from '../helpers';
import { StyledListItemIcon, StyledListItemText } from '../styled-components';

export const ClusterDeploymentSummaryConfigurationList = ({
  memory,
  cpu,
  disk,
  additionalDisk,
  complimentaryDisk,
  nodes,
  mediumSize = false,
  quantization,
}: ClusterConfiguration & { mediumSize?: boolean; quantization?: Quantization }) => {
  const cpuGHz = fromHzToGHz(cpu);
  const isMultiNode = nodes > 1;
  const nodesText = `${nodes} Node${isMultiNode ? 's' : ''}`;
  const cpuText = isMultiNode ? `${cpuGHz} vCPU x ${nodesText} = ${fromHzToGHz(cpu * nodes)} vCPU` : `${cpuGHz} vCPU`;
  const memoryText = isMultiNode ? `${memory} GB x ${nodesText} = ${memory * nodes} GB` : `${memory} GB`;
  disk = disk + complimentaryDisk;
  const diskText = isMultiNode ? `${disk} GB x ${nodesText} = ${disk * nodes} GB` : `${disk} GB`;
  const additionalDiskText = isMultiNode
    ? `${additionalDisk} GB x ${nodesText} = ${additionalDisk * nodes} GB`
    : `${additionalDisk} GB`;
  const fontSize = mediumSize ? 'medium' : 'small';
  const stylingProps: { primaryTypographyProps?: TypographyProps; secondaryTypographyProps?: TypographyProps } =
    mediumSize
      ? {
          primaryTypographyProps: { variant: 'h6' },
          secondaryTypographyProps: { variant: 'body1', color: 'text.primary' },
        }
      : {};
  return (
    <List aria-labelledby="cluster-deployment-summary-configuration-title">
      <ListItem disablePadding={true}>
        <StyledListItemIcon>
          <MemoryIcon fontSize={fontSize} role="presentation" />
        </StyledListItemIcon>
        <StyledListItemText
          aria-label="RAM"
          primary="RAM:"
          {...stylingProps}
          secondary={memoryText}
          id="cluster-deployment-summary-configuration-memory"
        />
      </ListItem>
      <ListItem disablePadding={true}>
        <StyledListItemIcon>
          <CpuIcon fontSize={fontSize} role="presentation" />
        </StyledListItemIcon>
        <StyledListItemText
          aria-label="CPU"
          primary="vCPUs:"
          {...stylingProps}
          secondary={cpuText}
          id="cluster-deployment-summary-configuration-cpu"
        />
      </ListItem>
      <ListItem disablePadding={true}>
        <StyledListItemIcon>
          <DiskIcon fontSize={fontSize} role="presentation" />
        </StyledListItemIcon>
        <StyledListItemText
          aria-label="Disk"
          primary="Disk space:"
          {...stylingProps}
          secondary={diskText}
          id="cluster-deployment-summary-configuration-disk"
        />
      </ListItem>
      <ListItem disablePadding={true}>
        <StyledListItemIcon>
          <NodesIcon fontSize={fontSize} role="presentation" />
        </StyledListItemIcon>
        <StyledListItemText
          aria-label="Nodes"
          primary="Nodes:"
          {...stylingProps}
          secondary={nodesText}
          id="cluster-deployment-summary-configuration-nodes"
        />
      </ListItem>
      {additionalDisk > 0 ? (
        <ListItem disablePadding={true}>
          <StyledListItemIcon>
            <AdditionalDiskIcon fontSize={fontSize} role="presentation" />
          </StyledListItemIcon>
          <StyledListItemText
            primary="Additional disk:"
            {...stylingProps}
            secondary={additionalDiskText}
            id="cluster-deployment-summary-configuration-additional-disk"
          />
        </ListItem>
      ) : null}

      {quantization ? (
        <ListItem disablePadding={true}>
          <StyledListItemIcon>
            <QuantizationIcon fontSize={fontSize} role="presentation" />
          </StyledListItemIcon>
          <StyledListItemText
            aria-label="Quantization Summary"
            primary="Quantization:"
            {...stylingProps}
            secondary={quantization}
            id="cluster-deployment-summary-configuration-quantization"
          />
        </ListItem>
      ) : null}
    </List>
  );
};
