import { Link, Typography } from '@mui/material';
import { Link as RouterLink } from '@tanstack/react-router';
import { useAccountId } from '../../../../../contexts/authenticated-data-context';

export const ApiKeyTips = () => {
  const accountId = useAccountId();

  return (
    <>
      <Typography component={'p'} my={1}>
        Read more about access in our{' '}
        <Link
          component={RouterLink}
          to={'https://qdrant.tech/documentation/cloud/cloud-quick-start/#authentication'}
          target={'_blank'}
        >
          documentation
        </Link>
      </Typography>
      <Typography component={'div'}>
        You can manage all your API keys in the{' '}
        <Link component={RouterLink} variant="body1" sx={{ pb: 0.5 }} to={`/accounts/${accountId}/api-keys`}>
          Access section
        </Link>
      </Typography>
    </>
  );
};
