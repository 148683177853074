import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/EditOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Button, MenuItem, Popover } from '@mui/material';
import { useNavigate } from '@tanstack/react-router';
import { useCallback, useRef, useState } from 'react';
import { DeletionDialog } from './DeletionDialog';
import { useHybridCloudEnvironment } from './HybridCloudEnvironmentPreloader/HybridCloudEnvironmentProvider';
import { RotateSecretsDialog } from './RotateSecretsDialog';
import { useAccountId } from '../../contexts/authenticated-data-context';

type Action = {
  label: string;
  handler: () => void;
  icon: React.ReactElement;
};

type ActionsPopoverProps = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  open: boolean;
  actions: Action[];
};

const ActionsPopover = (props: ActionsPopoverProps) => {
  const { anchorEl, onClose, open, actions, ...other } = props;

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
      keepMounted={true}
      onClose={onClose}
      open={open}
      slotProps={{ paper: { sx: { minWidth: anchorEl?.clientWidth, py: 1 } } }}
      transitionDuration={0}
      {...other}
    >
      {actions.map((action, index) => (
        <MenuItem
          key={index}
          onClick={() => {
            action.handler();
            onClose();
          }}
          sx={{ display: 'flex', alignItems: 'start' }}
        >
          <Box sx={{ mr: 1 }}>{action.icon}</Box>
          {action.label}
        </MenuItem>
      ))}
    </Popover>
  );
};

export const PrivateRegionActionsButton = () => {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const accountId = useAccountId();
  const [showDeletionDialog, setShowDeletionDialog] = useState(false);
  const [showRotateSecretsDialog, setShowRotateSecretsDialog] = useState(false);

  const data = useHybridCloudEnvironment();
  const { hybridCloudEnvironment } = data ?? {};

  if (!hybridCloudEnvironment) {
    throw new Error('Missing Hybrid Cloud environment');
  }

  const onSuccessfulDeletion = useCallback(
    () =>
      navigate({
        to: '/accounts/$accountId/hybrid-cloud',
        params: { accountId },
        search: { showCleanUpCommands: true },
      }),
    [navigate, accountId],
  );
  const [openActionsList, setOpenActionsList] = useState(false);

  const displayActions = useCallback(() => setOpenActionsList(true), []);
  const hideActions = useCallback(() => setOpenActionsList(false), []);
  const deleteAction = useCallback(() => setShowDeletionDialog(true), []);
  const RotateSecretsAction = useCallback(() => setShowRotateSecretsDialog(true), []);
  const editAction = useCallback(() => {
    void navigate({
      to: '/accounts/$accountId/hybrid-cloud-environments/$hybridCloudEnvId/edit',
      params: { accountId, hybridCloudEnvId: hybridCloudEnvironment.id },
    });
  }, [navigate, accountId, hybridCloudEnvironment]);

  return (
    <>
      <Button
        ref={anchorRef}
        variant="contained"
        aria-label="private-region-actions"
        endIcon={<ExpandMoreIcon fontSize="small" />}
        onClick={displayActions}
      >
        Actions
      </Button>

      <ActionsPopover
        anchorEl={anchorRef.current}
        onClose={hideActions}
        actions={[
          {
            icon: <EditIcon sx={(theme) => ({ color: theme.palette.primary.main })} />,
            handler: editAction,
            label: 'Edit',
          },
          {
            icon: <DeleteIcon sx={(theme) => ({ color: theme.palette.error.main })} />,
            handler: deleteAction,
            label: 'Delete',
          },
          {
            label: 'Rotate secrets',
            handler: RotateSecretsAction,
            icon: <RefreshIcon sx={(theme) => ({ color: theme.palette.error.main })} />,
          },
        ]}
        open={openActionsList}
      />

      {showDeletionDialog && (
        <DeletionDialog
          onClose={() => setShowDeletionDialog(false)}
          hybridCloudEnvId={hybridCloudEnvironment.id}
          hybridCloudEnvName={hybridCloudEnvironment.name}
          onSuccessfulDeletion={onSuccessfulDeletion}
        />
      )}

      {showRotateSecretsDialog && (
        <RotateSecretsDialog
          onClose={() => setShowRotateSecretsDialog(false)}
          accountId={accountId}
          hybridCloudEnvId={hybridCloudEnvironment.id}
          hybridCloudEnvName={hybridCloudEnvironment.name}
        />
      )}
    </>
  );
};
