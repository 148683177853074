import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {
  Box,
  Card,
  CardHeader,
  Link,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns';
import { useGetInvoicesQuery } from '../../services/paymentApi';
import { formatCents } from '../../utils/helpers';
import { ErrorBox } from '../Common/ErrorBox';
import { Scrollbar } from '../Common/Scrollbar';
import { SeverityPill } from '../Common/SeverityPill';

/**
 * Relevant Stripe invoice statuses.
 */
const INVOICE_STATUS = {
  PAID: 'paid',
  OPEN: 'open',
};

const StyledTableCell = styled(TableCell)(({ align = 'left' }) => ({ textAlign: align }));

const FEEDBACK_MSG_CONTAINER_STYLE = {
  pl: 3,
  pb: 3,
};

const InvoicesList = ({ accountId }: { accountId: string }) => {
  const { data: invoices, isFetching, refetch, isError } = useGetInvoicesQuery({ accountId });

  if (isFetching) {
    return <Skeleton variant="rectangular" height={240} />;
  }

  if (isError) {
    return (
      <Box sx={{ ...FEEDBACK_MSG_CONTAINER_STYLE }}>
        <ErrorBox retry={refetch} />
      </Box>
    );
  }

  if (!invoices || invoices.length === 0) {
    return <Box sx={{ ...FEEDBACK_MSG_CONTAINER_STYLE }}>No invoices yet</Box>;
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <StyledTableCell>Invoice #</StyledTableCell>
          <StyledTableCell>Amount</StyledTableCell>
          <StyledTableCell>Date</StyledTableCell>
          <StyledTableCell align="center">Payment status</StyledTableCell>
          <StyledTableCell align="center">Details</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {invoices
          .filter(({ status }) => [INVOICE_STATUS.OPEN, INVOICE_STATUS.PAID].includes(status))
          // Show latest first (desc order)
          .sort((a, b) => b.created - a.created)
          .map((invoice, index) => {
            const { number, paid, invoice_pdf: url, created, total } = invoice;
            const createdTimestamp = created * 1000;
            return (
              <TableRow hover={true} key={number ?? index}>
                {/* Invoice number */}
                <StyledTableCell data-sentry-mask={true}>{number}</StyledTableCell>
                {/* Total amount */}
                <StyledTableCell data-sentry-mask={true}>{formatCents(total)}</StyledTableCell>
                {/* Due date */}
                <StyledTableCell data-sentry-mask={true}>
                  {createdTimestamp ? format(createdTimestamp, 'd MMM yy') : '-'}
                </StyledTableCell>
                {/* Payment status */}
                <StyledTableCell align="center" data-sentry-mask={true}>
                  <SeverityPill color={paid ? 'success' : 'warning'}>{paid ? 'PAID' : 'PENDING'}</SeverityPill>
                </StyledTableCell>
                {/* Details (PDF link) */}
                <StyledTableCell align="center">
                  <Tooltip title={url ? 'Open invoice' : 'Not available'}>
                    <Link href={url ?? undefined}>
                      {/* Make icon 'look' disabled  */}
                      <PictureAsPdfIcon
                        sx={{
                          color: url ? 'palette.primary' : 'grey',
                          cursor: url ? 'pointer' : 'not-allowed',
                        }}
                      />
                    </Link>
                  </Tooltip>
                </StyledTableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
};

export const Invoices = ({ accountId }: { accountId: string }) => (
  <Card>
    <CardHeader title="Invoices" />
    <Scrollbar>
      <InvoicesList accountId={accountId} />
    </Scrollbar>
  </Card>
);
