import * as Sentry from '@sentry/react';

export let logger: CallableFunction | undefined;

export function logException(
  e: unknown,
  context?: {
    user?: {
      [key: string]: unknown;
      id?: string;
      email?: string;
      username?: string;
      segment?: string;
    };
    level?: 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug';
    extra?: Record<string, unknown>;
    tags?: Record<string, string | number | boolean>;
  },
) {
  if (logger === undefined) {
    if (window.__QDRANT_CLOUD__.env === 'dev' || window.__QDRANT_CLOUD__.env === 'local') {
      logger = console.error;
    } else {
      logger = Sentry.captureException;
    }
  }
  if (context) {
    logger(e, context);
  } else {
    logger(e);
  }
}
