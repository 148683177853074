import CheckIcon from '@mui/icons-material/Check';
import { Grid, styled } from '@mui/material';
import capitalize from 'lodash/capitalize';
import { useState } from 'react';
import { useCreateMarketplaceRedirect } from './hooks/use-create-marketplace-redirect';
import { useCreateStripeSession } from './hooks/use-create-stripe-session';
import { PaymentInformation } from '../../services/paymentApi';
import { typedMemo } from '../../utils/func-utils';
import { ButtonLike } from '../Common/ButtonLike';

const StyledProviderButtonLike = styled(ButtonLike)(
  ({ theme }) => `
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    outline: 1px transparent solid;
    background-color: ${theme.palette.background.paper};
    padding-block: ${theme.spacing(1.25)};
    justify-content: center;
    align-items: center;

    &:hover,
    &:focus {
      outline-color: ${theme.palette.primary.light};
      border-color: ${theme.palette.primary.light};
    }
    &[aria-disabled='true'] {
      background-color: ${theme.palette.background.default};
    }
    &[aria-pressed='true'] {
      color: ${theme.palette.success.main};
      pointer-events: none;
      user-select: none;
      outline-color: ${theme.palette.success.main};
      border-color: ${theme.palette.success.main};
    }
`,
);

const MarketplaceLogoStyled = styled('img')(
  ({ theme }) => `
    margin-inline: ${theme.spacing(2)};
    text-indent: -100vw;
    height: 30px;
    overflow: hidden;
`,
);

type _PaymentProviderName<T extends string> = T extends `suger_${infer S}` ? S : T;

export type PaymentProviderName = _PaymentProviderName<
  Extract<PaymentInformation['type_'], 'suger_aws' | 'suger_gcp' | 'suger_azure' | 'stripe'>
>;

export const PaymentProvidersMarketplace = typedMemo(function PaymentProvidersMarketplace<
  T extends PaymentProviderName,
>({ provider, onClick, disabled }: { provider: T; onClick?: (provider: T) => void; disabled?: boolean }) {
  const active = typeof onClick !== 'function';
  const [loaded, setLoaded] = useState(false);

  return (
    <StyledProviderButtonLike
      ariaLabel={`Setup payment with ${capitalize(provider)}`}
      disabled={disabled}
      pressed={!onClick}
      onClick={() => {
        if (!active) {
          onClick(provider);
        }
      }}
    >
      {active && <CheckIcon color="success" sx={{ ml: 1, mr: loaded ? -1 : 1 }} fontSize="small" />}
      <MarketplaceLogoStyled
        src={provider === 'stripe' ? '/stripe_logo.svg' : `/${provider}_marketplace_logo.svg`}
        alt={capitalize(provider)}
        sx={{ aspectRatio: provider === 'stripe' ? '300 / 143' : '100 / 21' }}
        onLoad={() => {
          setLoaded(true);
        }}
      />
    </StyledProviderButtonLike>
  );
});

export function PaymentProviders({
  accountId,
  marketplaces = ['stripe', 'aws', 'gcp', 'azure'],
}: {
  accountId: string;
  marketplaces?: PaymentProviderName[] | undefined;
}) {
  const createMarketplaceRedirect = useCreateMarketplaceRedirect();
  const createSessionAndRedirect = useCreateStripeSession({ accountId, shouldRedirect: true });

  return (
    <Grid container={true} spacing={2}>
      <Grid item={true}>
        <PaymentProvidersMarketplace
          provider="stripe"
          disabled={!marketplaces.includes('stripe')}
          onClick={createSessionAndRedirect}
        />
      </Grid>
      <Grid item={true}>
        <PaymentProvidersMarketplace
          provider="aws"
          disabled={!marketplaces.includes('aws')}
          onClick={createMarketplaceRedirect}
        />
      </Grid>
      <Grid item={true}>
        <PaymentProvidersMarketplace
          provider="gcp"
          disabled={!marketplaces.includes('gcp')}
          onClick={createMarketplaceRedirect}
        />
      </Grid>
      <Grid item={true}>
        <PaymentProvidersMarketplace
          provider="azure"
          disabled={!marketplaces.includes('azure')}
          onClick={createMarketplaceRedirect}
        />
      </Grid>
    </Grid>
  );
}
