import { useState, useCallback } from 'react';

export type ConfirmActionBase = {
  actionName: string;
  title: string;
  content: string;
  extraConfirmation?: string;
  warning?: string;
};

type ConfirmAction = ConfirmActionBase & {
  actionHandler: (...args: any[]) => Promise<void> | void;
  withConfirm?: boolean;
  severity?: 'error' | 'warning' | 'info';
};

type ConfirmDangerAction = ConfirmActionBase & {
  actionTarget: string;
  actionHandler: (confirm?: boolean) => Promise<void>;
  defaultExtraConfirmation?: boolean;
};

type Action = ConfirmAction | ConfirmDangerAction;
const useConfirmationActionHook = <T extends Action>(initialState?: T) => {
  const [confirmAction, setConfirmAction] = useState<T | null>(initialState ?? null);

  const showConfirmation = useCallback((config: T) => {
    setConfirmAction(config);
  }, []);

  const hideConfirmation = useCallback(() => {
    setConfirmAction(null);
  }, []);

  return {
    confirmAction,
    showConfirmation,
    hideConfirmation,
  };
};

/**
 * To be used with ConfirmationDialog component (See src/components/Common/ConfirmationDialog.tsx), provides the APIs
 * to display and hide the dialog when prompting for an action confirmation.
 */
export const useConfirmationAction = (initialState?: ConfirmAction) => useConfirmationActionHook(initialState);

/**
 * To be used with ConfirmationDangerDialog component (See src/components/Common/ConfirmationDangerDialog.tsx),
 * provides the APIs to display and hide the dialog when prompting for a dangerous action confirmation.
 */
export const useConfirmationDangerAction = (initialState?: ConfirmDangerAction) =>
  useConfirmationActionHook(initialState);
