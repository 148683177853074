import { Box, Button, Typography } from '@mui/material';

export const ApiKeyEmptyState = ({ handleCreateClick }: { handleCreateClick: () => void }) => (
  <Box display="flex" justifyContent="center" alignItems="center" sx={{ p: 3 }}>
    <Box>
      <Box>
        <Typography>You do not have any keys yet, go ahead fantastic vectors are waiting for you!</Typography>
      </Box>
      <Box pt={1} justifyContent={'center'} alignItems="center" display="flex">
        <Button id={'open-cluster-create-dialog'} variant="outlined" onClick={handleCreateClick}>
          Create
        </Button>
      </Box>
    </Box>
  </Box>
);
