import { Button } from '@mui/material';
import { memo, useCallback } from 'react';
import { ConfirmationDialog } from './../Common/ConfirmationDialog';
import { useAuthenticatedData } from '../../contexts/authenticated-data-context';
import { useConfirmationAction } from '../../hooks/use-confirmation-action';
import { useSnackbar } from '../../hooks/use-qdrant-snackbar';
import { isFetchMutationError } from '../../services/helpers';
import { useDeleteAccountMutation } from '../../services/iamApi';
import { DangerZone } from '../DangerZone';

export const DeleteAccountDangerZoneAction = memo(function DeleteAccountDangerZoneAction() {
  const { confirmAction, showConfirmation, hideConfirmation } = useConfirmationAction();
  const [deleteAccount, { isLoading: deletingAccount }] = useDeleteAccountMutation();
  const { enqueueSnackbar } = useSnackbar();

  const {
    userInfo: { email, accounts = [] },
    account,
  } = useAuthenticatedData();

  const isDeletionAllowed = useCallback(() => {
    const ownAccount = accounts?.find((account) => account.owner_email === email);
    return ownAccount?.id === account.id;
  }, [account.id, accounts, email]);

  const deleteAccountHandler = useCallback(
    async (accountId: string) => {
      const result = await deleteAccount({ account_id: accountId });
      if (isFetchMutationError(result)) {
        enqueueSnackbar('There was a problem deleting the account. Try again later.', { variant: 'error' });
      }
    },
    [deleteAccount, enqueueSnackbar],
  );

  const handleAccountDeletion = () => {
    showConfirmation({
      actionName: 'Delete account',
      extraConfirmation:
        'I understand that this will permanently delete my account and all associated data from Qdrant.',
      title: `Delete account`,
      content: `Are you sure you want to delete the account "${account.name}"?`,
      warning:
        'Please note, once deleted, you will not be able to access this account again with the associated email.',
      actionHandler: async (extraConfirmationChecked) => {
        if (extraConfirmationChecked) {
          await deleteAccountHandler(account.id);
        }
      },
    });
  };

  if (!isDeletionAllowed()) {
    return null;
  }
  return (
    <>
      {confirmAction && (
        <ConfirmationDialog
          open={true}
          onClose={hideConfirmation}
          {...confirmAction}
          extraConfirmation={confirmAction.extraConfirmation}
          extraConfirmationRequired={true}
        />
      )}
      <DangerZone
        header="Delete Account"
        description={
          <>
            Permanently delete your account <strong>{account.name}</strong>, of which you are the owner, along with all
            associated data.
          </>
        }
        action={
          <Button variant="outlined" color="error" onClick={handleAccountDeletion} disabled={deletingAccount}>
            Delete account
          </Button>
        }
      />
    </>
  );
});
