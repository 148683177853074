import { createRoute, Navigate, Outlet } from '@tanstack/react-router';
import { Route as HybridCloudEnvironmentsRoute } from './_hybrid-cloud-environments';
import { useAccountId } from '../contexts/authenticated-data-context';
import { useGetHybridCloudEnvQuery } from '../services/clustersApi';
import { isFetchBaseQueryError } from '../services/helpers';

export const Route = createRoute({
  getParentRoute: () => HybridCloudEnvironmentsRoute,
  path: '$hybridCloudEnvId',
  component: HybridCloudEnvironmentComponent,
});

function HybridCloudEnvironmentComponent() {
  const accountId = useAccountId();
  const { hybridCloudEnvId } = Route.useParams();
  const { isError, error } = useGetHybridCloudEnvQuery({
    account_id: accountId,
    hybrid_cloud_env_id: hybridCloudEnvId,
  });

  if (isError && isFetchBaseQueryError(error) && error.status === 404) {
    return <Navigate to="/accounts/$accountId/hybrid-cloud-environments" params={{ accountId }} replace={true} />;
  }
  return <Outlet />;
}
