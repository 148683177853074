import { createRoute, redirect } from '@tanstack/react-router';
import { Route as RootRoute } from './__root';

export const Route = createRoute({
  getParentRoute: () => RootRoute,
  path: '/',
  beforeLoad({ location: { searchStr } }) {
    let redirectTo = 'login';
    if (searchStr) {
      redirectTo += `?${searchStr}`;
    }
    redirect({ to: redirectTo, throw: true });
  },
});
