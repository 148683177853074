import { useEffect, useState } from 'react';

export const useWindowFocus = () => {
  const [isFocused, setFocus] = useState(true);

  useEffect(() => {
    const handleFocus = () => {
      setFocus(true);
    };
    const handleFocusLost = () => {
      setFocus(false);
    };
    const handleVisibilityChange = () => {
      if (window.document.visibilityState === 'visible') {
        handleFocus();
      } else {
        handleFocusLost();
      }
    };

    window.addEventListener('visibilitychange', handleVisibilityChange, false);
    window.addEventListener('focus', handleFocus, false);
    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('focus', handleFocus);
    };
  }, []);

  return isFocused;
};
