import { useNavigate } from '@tanstack/react-router';
import { ReactNode, useEffect } from 'react';
import { useAccountId } from '../../../contexts/authenticated-data-context';
import { useFeatureFlags } from '../../../hooks/use-feature-flags';
import { useSnackbar } from '../../../hooks/use-qdrant-snackbar';
import { LoadingIndicator } from '../../Common/LoadingIndicator';

export const ClusterCreationEnabled = ({ children }: { children: ReactNode }) => {
  const accountId = useAccountId();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { cluster_creation_enabled: clusterCreationEnabled, isError, isLoading } = useFeatureFlags();
  let errorMsg: string | undefined;
  if (isError) {
    errorMsg = 'There was an error loading the data.';
  } else {
    if (!isLoading) {
      if (!clusterCreationEnabled) {
        errorMsg = 'Cluster creation is currently disabled.';
      }
    }
  }
  useEffect(() => {
    if (errorMsg) {
      enqueueSnackbar(errorMsg, { variant: 'warning' });
      void navigate({ to: '/accounts/$accountId/clusters', params: { accountId } });
    }
  }, [errorMsg, enqueueSnackbar, navigate, accountId]);

  if (isLoading || errorMsg) {
    return <LoadingIndicator />;
  }
  return children;
};
