import { Box, Card, Skeleton } from '@mui/material';
// eslint-disable-next-line no-restricted-imports
import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { CHART_HEIGHT, CHART_CARD_HEIGHT, CHART_COLORS, Metric, getChartOptions, METRICS_DETAILS } from './utils';

const getMetricMax = (metricData: [number, number][]): number => {
  const flattenedData = metricData.map(([, value]) => Number(value));
  return Math.ceil(Math.max(...flattenedData) * 1.5) || 50;
};

const CHART_BOX_STYLE = { padding: 1 };

export type MetricValues = [number, number][];
export type Metrics = {
  [Metric.LATENCY]: MetricValues;
  [Metric.RPS]: MetricValues;
};
type RequestsMetricsProps = {
  metrics?: Metrics;
  timeFrame: number;
  xAxisRange: {
    since: number;
    until: number;
  };
  isLoading: boolean;
};

export function RequestsMetricsChart({ metrics, timeFrame, xAxisRange, isLoading }: RequestsMetricsProps) {
  const theme = useTheme();

  const rpsLatencySeries: ApexAxisChartSeries = useMemo(
    () => [
      {
        name: METRICS_DETAILS[Metric.LATENCY].label,
        data: metrics?.[Metric.LATENCY] ?? [],
      },
      {
        name: METRICS_DETAILS[Metric.RPS].label,
        data: metrics?.[Metric.RPS] ?? [],
      },
    ],
    [metrics],
  );

  const rpcLatencyOptions = useMemo(
    () =>
      getChartOptions({
        metrics: [
          { name: Metric.LATENCY, color: CHART_COLORS.RED, max: getMetricMax(metrics?.[Metric.LATENCY] ?? []) },
          { name: Metric.RPS, color: CHART_COLORS.ORANGE, max: getMetricMax(metrics?.[Metric.RPS] ?? []) },
        ],
        timeFrame,
        xAxisRange,
        theme,
      }),
    [timeFrame, xAxisRange, theme, metrics],
  );

  return (
    <Box sx={{ mt: 2, mb: 2 }}>
      <Card sx={{ minHeight: CHART_CARD_HEIGHT }}>
        {isLoading ? (
          <Skeleton variant="rectangular" height={CHART_CARD_HEIGHT} />
        ) : (
          <Box sx={CHART_BOX_STYLE} overflow="hidden">
            <Chart options={rpcLatencyOptions} series={rpsLatencySeries} height={CHART_HEIGHT} />
          </Box>
        )}
      </Card>
    </Box>
  );
}
