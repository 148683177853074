import { Avatar, Box, Card, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { ResourceIcons } from './ResourceIcons';
import { Check as CheckIcon } from '../../../../../icons/check';
import { isClusterHealthy } from '../../../../../utils/cluster-utils';
import { useWelcomeStepperContext } from '../WelcomeStepperProvider';

const HealthyIcon = ({ size }: { size: number }) => (
  <Avatar
    sx={{
      height: size,
      width: size,
      padding: 0,
      backgroundColor: 'success.main',
      color: 'success.contrastText',
      '& svg': {
        width: 8,
      },
    }}
    variant="circular"
  >
    <CheckIcon />
  </Avatar>
);

export const ClusterStatusCard = () => {
  const { newCluster, clusterName, freeBookingPackage } = useWelcomeStepperContext();

  const isHealthy = Boolean(isClusterHealthy(newCluster));
  const statusText = isHealthy ? 'Your Cluster is Healthy' : 'Creating Your Cluster';

  return (
    <Card
      sx={{
        mb: 3,
      }}
    >
      <Box display="flex" justifyContent="space-between" px="40px" py="16px">
        <Box display="flex" flexDirection="column" width="100%">
          <Typography variant="subtitle2" color="textSecondary" display="block" gutterBottom={true}>
            Cluster Name
          </Typography>
          <Box display="flex" justifyContent="space-between" flexWrap="wrap">
            <Typography fontSize={'1.6rem'} fontWeight={600} display="block" gutterBottom={true}>
              {clusterName || newCluster?.name}
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography fontWeight={500} mr={'8px'}>
                {statusText}
              </Typography>
              {isHealthy ? <HealthyIcon size={18} /> : <CircularProgress size={18} />}
            </Box>
          </Box>
          {freeBookingPackage && <ResourceIcons />}
        </Box>
      </Box>
    </Card>
  );
};
