import { Box, Typography } from '@mui/material';
import List from '@mui/material/List';
import { NewsListItem } from './NewsListItem';
import { newsFeed } from './data/news-feed';

export const NewsItemList = () => (
  <Box>
    {newsFeed.length > 0 ? (
      <List>
        {newsFeed.map((newsItem) => (
          <NewsListItem key={newsItem.guid} newsEntry={newsItem} />
        ))}
      </List>
    ) : (
      <Typography>Nothing new so far... We will soon publish something to keep you up to date.</Typography>
    )}
  </Box>
);
