import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { Button, MobileStepper } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

function Slider({ slides, onStepChange, navigationVariant = 'text', maxWidth = '100%' }) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);

  const maxSteps = slides.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  useEffect(() => {
    onStepChange && onStepChange(activeStep);
  }, [activeStep, onStepChange]);

  // When there is only 1 slide return it without all the unnecessary navigation overhead.
  if (slides.length === 1) {
    return slides[0];
  }

  return (
    <>
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant={navigationVariant}
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            Next
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>
        }
      />
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents={true}
        style={{ maxWidth: maxWidth }}
      >
        {slides}
      </SwipeableViews>
    </>
  );
}

// proptypes definition
Slider.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.node).isRequired,
  onStepChange: PropTypes.func,
  maxWidth: PropTypes.string,
};

export default Slider;
