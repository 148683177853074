import { MenuItem, Select } from '@mui/material';
import { memo } from 'react';
import { useClusterConfig, useClusterConfigUpdater, useDefaultClusterConfig } from './ClusterConfigurationContext';

export const MIN_NODES = 1;
export const MAX_NODES = 20;

export const ClusterNodesControl = ({ disabled }: { disabled?: boolean }) => {
  const { nodes } = useClusterConfig();
  const { nodes: defaultNodes } = useDefaultClusterConfig();

  return <ClusterNodesControlInner minValue={defaultNodes} value={nodes} disabled={disabled} />;
};

const ClusterNodesControlInner = memo(function ClusterNodesControlInner({
  value,
  minValue,
  disabled,
}: {
  value: number;
  minValue?: number;
  disabled?: boolean;
}) {
  const startValue = Math.max(MIN_NODES, minValue ?? 0);
  const menuItems = Array.from({ length: MAX_NODES }, (_, i) => i + startValue);
  const update = useClusterConfigUpdater();

  return (
    <Select<number>
      value={value}
      onChange={(event) => {
        const value = event.target.value as number;
        update({ type: 'nodes', value });
      }}
      disabled={disabled}
      labelId="cluster-advanced-configuration-nodes-label"
    >
      {menuItems.map((value) => (
        <MenuItem value={value} key={value}>
          {`${value} node${value > 1 ? 's' : ''}`}
        </MenuItem>
      ))}
    </Select>
  );
});
