import { Box, Breadcrumbs, Container, Grid, Typography } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { PageFooter } from './PageFooter';

const topMenuHeight = '64px';

// this component is used to render the page title and the action buttons
export const PageFrame = ({
  children,
  title,
  buttons,
  hasTopMenu = true,
  breadcrumbs,
}: {
  children: ReactNode;
  title?: string;
  buttons?: ReactElement[];
  hasTopMenu?: boolean;
  breadcrumbs?: ReactElement[];
}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      flexGrow: 1,
      minHeight: hasTopMenu ? `calc(100vh - ${topMenuHeight})` : '100vh',
    }}
  >
    <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
      <Container maxWidth="xl">
        {Boolean(breadcrumbs?.length) && (
          <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ mt: -4, mb: 1 }}>
            {breadcrumbs}
          </Breadcrumbs>
        )}
        {(title || buttons) && (
          <Box sx={{ mb: 4 }}>
            <Grid container={true} justifyContent="space-between" spacing={1}>
              {title && (
                <Grid item={true}>
                  <Typography variant="h4">{title}</Typography>
                </Grid>
              )}
              {/** show action buttons */}
              {buttons && (
                <Grid item={true} display="flex" alignItems="center">
                  {buttons.map((button, index) => (
                    <Box key={index}>{button}</Box>
                  ))}
                </Grid>
              )}
            </Grid>
          </Box>
        )}
        {/** page content */}
        <Box>{children}</Box>
      </Container>
    </Box>
    <PageFooter />
  </Box>
);
