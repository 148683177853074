import { Box, ListItemIcon, ListItemText, styled } from '@mui/material';

export const StyledClusterCreationSectionInfo = styled(Box)(
  ({ theme }) => `
    background: ${theme.palette.background.default};
    color: ${theme.palette.text.secondary};
    padding: ${theme.spacing(2)};
    font-size: ${theme.typography.body2.fontSize!};
    font-weight: ${theme.typography.body2.fontWeight!};
    line-height: ${theme.typography.body2.lineHeight!};
    word-break: break-word;
    ul {
      padding-left: ${theme.spacing(2)};
      margin: 0;
    }
    li {
      margin-top: ${theme.spacing(1)};
    }
  `,
);

export const StyledItemHeader = styled('div')(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    contain: content;
    &:not(:last-of-type) {
      margin-bottom: ${theme.spacing(2)};
    }
  `,
);

export const StyledClusterCreateSection = styled('div')(({ theme }) => {
  const activeStyles = `
    background: ${theme.palette.background.paper};
    box-shadow: ${theme.shadows[2]};
    border: 1px solid ${theme.palette.grey[200]};
`;

  return `
      box-sizing: border-box;
      border-radius: 4px;
      padding: ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(4)};
      ${activeStyles}
      & + & {
        margin-top: ${theme.spacing(5)};
      }

      &.inactive {
        background: transparent;
        box-shadow: none;
        border-color: transparent;
        &:not(.has-data):not(.has-error):hover {
          ${activeStyles}
        }
        &.has-data {
          ${activeStyles}
          border-color: ${theme.palette.success.main};
        }
      }
    `;
});

export const StyledClusterCreateSectionHeading = styled('div')(
  ({ theme }) => `
    column-gap: ${theme.spacing(1)};
    display: grid;
    grid-template-columns: auto 1fr;
    grid-auto-rows: auto;
    align-items: center;
    contain: content;
    grid-template-areas:
        'icon title'
        '. subtitle';
    `,
);

export const StyledClusterCreateSectionContent = styled('div')(
  ({ theme }) => `
    margin-top: ${theme.spacing(2)};
    margin-left: ${theme.spacing(5)};
  `,
);

export const StyledClusterCreateSectionTitle = styled('h5')(
  ({ theme }) => `
    font-size: ${theme.typography.h6.fontSize!};
    font-weight: ${theme.typography.h6.fontWeight!};
    line-height: ${theme.typography.h6.lineHeight!};
    grid-area: title;
    margin: 0;
`,
);

export const StyledClusterCreateSectionSubTitle = styled('h6')(
  ({ theme }) => `
    font-size: ${theme.typography.body1.fontSize!};
    font-weight: ${theme.typography.body1.fontWeight!};
    line-height: ${theme.typography.body1.lineHeight!};
    color: ${theme.palette.text.secondary};
    grid-area: subtitle;
    margin: 0;
`,
);

export const StyledClusterCreateSectionIcon = styled('div')(
  ({ theme }) => `
    grid-area: icon;
    min-width: ${theme.spacing(4)};
    min-height: ${theme.spacing(4)};
    display: flex;
    align-items: center;
    justify-content: center;
  `,
);

export const StyledListItemIcon = styled(ListItemIcon)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const StyledListItemText = styled(ListItemText)`
  display: flex;
  align-items: baseline;
  gap: ${({ theme }) => theme.spacing(1)};
`;
