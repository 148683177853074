import Close from '@mui/icons-material/Close';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  useMediaQuery,
  // eslint-disable-next-line no-restricted-imports
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useSnackbar } from '../../hooks/use-qdrant-snackbar';
import { useGenerateHybridCloudEnvInitialConfigCommandMutation } from '../../services/clustersApi';
import { isFetchMutationError } from '../../services/helpers';
import { CopyToClipboardText } from '../Common/CopyToClipboardText';

const DialogContainer = ({
  children,
  fullScreen,
  onClose,
}: {
  fullScreen: boolean;
  children: React.ReactNode;
  onClose: () => void;
}) => (
  <Dialog
    fullScreen={fullScreen}
    fullWidth={true}
    maxWidth="lg"
    open={true}
    onClose={onClose}
    aria-labelledby="rotate-secrets-dialog-title"
    aria-describedby="rotate-secrets-dialog-description"
  >
    <IconButton sx={{ maxWidth: '48px', alignSelf: 'end', mt: 2, mr: 2 }} onClick={onClose}>
      <Close titleAccess="Close" />
    </IconButton>
    <DialogContent sx={{ px: 3, pb: 3, pt: 0 }}>
      <Box width="100%">{children}</Box>
    </DialogContent>
  </Dialog>
);

export const RotateSecretsDialog = ({
  accountId,
  hybridCloudEnvId,
  hybridCloudEnvName,
  onClose,
}: {
  accountId: string;
  hybridCloudEnvId: string;
  hybridCloudEnvName: string;
  onClose: () => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [command, setCommand] = useState<string>();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  const [generateInitialInstallationCommand, { isLoading }] = useGenerateHybridCloudEnvInitialConfigCommandMutation();
  const generateCommand = async () => {
    const result = await generateInitialInstallationCommand({
      account_id: accountId,
      hybrid_cloud_env_id: hybridCloudEnvId,
    });

    if (isFetchMutationError(result) || !result.data.command) {
      enqueueSnackbar('There was a problem generating the command. Try again later.', { variant: 'error' });
      return false;
    }

    setCommand(result.data.command);
    return true;
  };

  return (
    <DialogContainer fullScreen={fullScreen} onClose={onClose}>
      <Typography variant="h5" sx={{ pb: 3, px: 3 }}>
        Rotating secrets of Hybrid Cloud Environment: {hybridCloudEnvName}
      </Typography>

      <Alert severity="warning">
        If you rotate the secrets, the existing secrets will be invalidated and you will need to update the secrets in
        your Kubernetes cluster. Until you have done this, you won't be able to manage the Hybrid Cloud Environment from
        the Qdrant Cloud console. Your Qdrant database clusters remain fully operational.
      </Alert>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          px: 2,
          pt: 3,
        }}
      >
        <Button color="error" variant="contained" onClick={generateCommand} disabled={isLoading}>
          Rotate secrets
        </Button>
      </Box>

      {command && (
        <>
          <Typography variant="body1" sx={{ mt: 1, mb: 2 }}>
            To apply the new secrets, execute the command below in your Kubernetes cluster.
          </Typography>
          <CopyToClipboardText
            text={command}
            useCodeStyle={true}
            wrapLines={false}
            fileDownloadName={`hybrid-cloud-environment-install-command-${Date.now()}.txt`}
          />
        </>
      )}
    </DialogContainer>
  );
};
