import { createContext, useContext } from 'react';
import { Account, UserInfo } from '../services/iamApi';

export const initialUserInfo = { id: undefined as unknown as string, user_id: '', status: null, accounts: [] };

export const initialAccount = {
  id: '',
  name: '',
  is_billed_manually: false,
  is_business: false,
  is_default: false,
  user_roles: [],
  privileges: [],
};

export const AuthenticatedDataContext = createContext<{
  userInfo: UserInfo;
  account: Account;
  userBucket: number;
}>({
  userInfo: initialUserInfo,
  account: initialAccount,
  userBucket: NaN,
});

export const useAuthenticatedData = () => useContext(AuthenticatedDataContext);

export const useAccountId = () => useAuthenticatedData().account.id;
