import { Alert, Box, Card, CardContent, CardHeader } from '@mui/material';
import { ApiKeyTips } from './ApiKeyTips';
import { ApiKeyCode } from '../../../../Access/ApiKeyCode';
import { useWelcomeStepperContext } from '../WelcomeStepperProvider';

export const ApiKeyResult = () => {
  const { apiKey, newCluster } = useWelcomeStepperContext();

  if (!newCluster) {
    return null;
  }

  return (
    <Card sx={{ py: 1, px: 2, mb: 3 }}>
      <CardHeader sx={{ pb: 2 }} title={`Key for ${newCluster.name} successfully generated`} />
      <CardContent sx={{ pt: 0 }}>
        {apiKey && (
          <>
            <ApiKeyCode apiKey={apiKey} />
            <Box mt={2}>
              <Alert severity="warning">
                <Box>Save the key below in a safe place! You will not be able to see or request it again.</Box>
              </Alert>
            </Box>
          </>
        )}
        <ApiKeyTips />
      </CardContent>
    </Card>
  );
};
