import { Navigate } from '@tanstack/react-router';
import { ReactNode, useEffect, useRef } from 'react';
import { useSnackbar } from '../../../hooks/use-qdrant-snackbar';
import { useGetClusterByIdQuery } from '../../../services/clustersApi';
import { Cluster } from '../../../utils/cluster-utils';
import { FREE_TIER_CLOUD_PROVIDER } from '../constants';
import { useClusterScaleConsent } from '../hooks/use-cluster-scale-consent';

function ClusterActionableCheck({
  accountId,
  cluster,
  children,
}: {
  accountId: string;
  cluster: Cluster;
  children: ReactNode;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const isNotActionable = cluster.state?.actionable === false;

  useEffect(() => {
    if (isNotActionable) {
      enqueueSnackbar('Cluster is busy and cannot be scaled yet', { variant: 'warning', preventDuplicate: true });
    }
  }, [enqueueSnackbar, isNotActionable]);

  if (isNotActionable) {
    return <Navigate to="/accounts/$accountId/clusters/$clusterId" params={{ accountId, clusterId: cluster.id }} />;
  }

  return children;
}

const ClusterScaleConsentCheck = ({
  accountId,
  cluster,
  children,
}: {
  accountId: string;
  cluster: Cluster;
  children: ReactNode;
}) => {
  const clusterScaleConsent = useClusterScaleConsent({
    accountId,
    cluster,
    freeTierCloudProvider: FREE_TIER_CLOUD_PROVIDER,
  });
  const { enqueueSnackbar } = useSnackbar();
  const providerName = cluster.cloud_provider.toUpperCase();

  useEffect(() => {
    if (clusterScaleConsent === 'disallowed') {
      enqueueSnackbar(
        `Free tier clusters on ${providerName} can't be scaled up when paid by a different marketplace.`,
        {
          variant: 'warning',
          preventDuplicate: true,
        },
      );
    }
  }, [clusterScaleConsent, enqueueSnackbar, providerName]);

  if (clusterScaleConsent === 'disallowed') {
    return <Navigate to="/accounts/$accountId/clusters/$clusterId" params={{ accountId, clusterId: cluster.id }} />;
  }

  return children;
};

/**
 * This component is used to check if the cluster is actionable and redirect if it is not.
 * It is used in the ClusterScale component to prevent users from scaling a cluster that is busy.
 */
export const ClusterScalePreCheck = ({
  accountId,
  clusterId,
  children,
}: {
  accountId: string;
  clusterId: string;
  children: ReactNode;
}) => {
  const { data, requestId } = useGetClusterByIdQuery({ accountId, clusterId });
  /**
   * We want to skip the checks if we already have the data in memory. This prevents the mutation request
   * with optimistic updates from retriggering the checks, which lead to an unwanted double redirect.
   * `requestId` only changes if new data is fetched, and for that:
   *   - a) accountId, and/or clusterId parameters have to change,
   *   - b) cache has to be invalidated, which doesn't happen on this page - and if it
   * happens THEN we want to perform the checks again for safety
   */
  const prevRequestId = useRef<string>();
  const skipChecks = requestId === prevRequestId.current;
  // If we still don't have data, or already had data, then skip the checks and just render the children
  if (!data || skipChecks) {
    return children;
  }

  prevRequestId.current = requestId;

  return (
    <ClusterActionableCheck accountId={accountId} cluster={data}>
      <ClusterScaleConsentCheck accountId={accountId} cluster={data}>
        {children}
      </ClusterScaleConsentCheck>
    </ClusterActionableCheck>
  );
};
