import { styled, Palette } from '@mui/material/styles';
import { HTMLAttributes, ReactNode } from 'react';

type PaletteColors = 'primary' | 'secondary' | 'error' | 'info' | 'warning' | 'success' | 'inactive';
type PaletteColor = keyof Pick<Palette, 'primary' | 'secondary' | 'error' | 'info' | 'warning' | 'success'>;

const SeverityPillRoot = styled('span')<{ paletteColor: PaletteColor; small: boolean }>(({
  theme,
  paletteColor,
  small,
}) => {
  const backgroundColor = theme.palette[paletteColor].main;
  const color = theme.palette[paletteColor].contrastText;

  return {
    alignItems: 'center',
    borderRadius: 12,
    color,
    cursor: 'default',
    display: 'inline-flex',
    flexGrow: 0,
    flexShrink: 0,
    gap: 4,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    justifyContent: 'center',
    backgroundColor,
    minWidth: 20,
    whiteSpace: 'nowrap',
    ...(!small
      ? {
          lineHeight: 2,
          fontWeight: 600,
          textTransform: 'uppercase',
          letterSpacing: 0.5,
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
        }
      : {
          lineHeight: 1,
          fontWeight: 500,
          padding: '3px 6px',
          height: 'fit-content',
        }),
  };
});

type SeverityPillProps = {
  children: ReactNode;
  color?: PaletteColors;
  small?: boolean;
};

export const SeverityPill = ({
  color = 'primary',
  small = false,
  children,
  ...other
}: SeverityPillProps & HTMLAttributes<HTMLSpanElement>) => (
  // Fix later by adding inactive to configured theme typings
  <SeverityPillRoot paletteColor={color as Exclude<PaletteColors, 'inactive'>} small={small} {...other}>
    {children}
  </SeverityPillRoot>
);
