import { Skeleton } from '@mui/material';
import { lazy, Suspense } from 'react';
import { CopyToClipboardTextProps } from './CopyToClipboardText';

const LazyComponent = lazy(() =>
  import(/* webpackChunkName: "copy-to-clipboard-text" */ './CopyToClipboardText').then(({ CopyToClipboardText }) => ({
    default: CopyToClipboardText,
  })),
);

export const CopyToClipboardText = (props: CopyToClipboardTextProps) => (
  <Suspense fallback={<Skeleton height={40} width="100%" variant="rectangular" />}>
    <LazyComponent {...props} />
  </Suspense>
);
