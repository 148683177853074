import { useEffect, useRef, useState } from 'react';
import { logException } from './error-utils';
import { useAuthenticatedData } from '../contexts/authenticated-data-context';
import { UserInfo } from '../services/iamApi';

type NumericRange<
  Start extends number,
  End extends number,
  Arr extends unknown[] = [],
  Acc extends number = never,
> = Arr['length'] extends End
  ? Acc | Start | End
  : NumericRange<Start, End, [...Arr, 1], Arr[Start] extends undefined ? Acc : Acc | Arr['length']>;

type BucketCount = NumericRange<1, 100>;

/**
 * Returns a SHA-256 checksum of the string.
 */
async function getChecksum(str: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(str);
  const hash = await window.crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hash));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}

/**
 * Returns a number between 0 and buckets - 1.
 * The number is calculated based on the user ID and it represents the bucket the user is assigned to.
 */
export async function getUserIdBucket(uuid: string, buckets: BucketCount = 100) {
  const checksum = await getChecksum(uuid);
  return Number(BigInt(`0x${checksum}`) % BigInt(Math.min(buckets, 100)));
}

/**
 * Returns the bucket number for the current user.
 * The check for bucket is always buckets - 1, e.g.: 9 for 10 buckets, 3 for 4 buckets, etc.
 * ```ts
 * // Example usage: 4 buckets - 1/4 for control group, 3/4 for experiment group
 * const bucket = useUserIdBucket(4);
 * const isControlGroup = bucket < 1;
 * const isExperimentGroup = bucket >= 1;
 * ```
 */
export function useUserIdBucket(userInfo: UserInfo, buckets?: BucketCount) {
  const promise = getUserIdBucket(userInfo.id, buckets);
  const [bucket, setBucket] = useState<number | null>(null);
  const mountedRef = useRef(true);
  useEffect(() => {
    promise
      .then((bucket) => {
        if (mountedRef.current) {
          setBucket(bucket);
        }
      })
      .catch(logException);
    return () => {
      mountedRef.current = false;
    };
  }, [promise]);
  return bucket;
}

/**
 * Returns `true` if the user bucket falls into the phased rollout.
 *
 * **IMPORTANT**: once a phased rollout has started, never decrease the percentage of users in the phased rollout group.
 * If decreased, some users might be assigned to a different group and the rollout will be compromised.
 *
 * @example
 * const isUserInRolloutPhase = usePhasedRolloutForUser(10);
 * if (isUserInRolloutPhase) {
 *   // show the new feature
 * }
 */
export function usePhasedRolloutForUser(percentage: BucketCount) {
  const { userBucket } = useAuthenticatedData();
  return userBucket < percentage;
}

/**
 * Returns an object with the keys `a`, `b`, `c`, `d`, `e` (depending on the number of buckets)
 * and the value is a boolean indicating if the user is in that group.
 * Returns `undefined` if the bucket is not yet available.
 *
 * **IMPORTANT**: once the bucket is assigned to a user, it should never change. If changed, the user might be assigned
 * to a different bucket and the experiment will be compromised.
 *
 * @example
 * const experimentGroup = useUserIdExperimentGroup(userInfo, 4);
 * if (!experimentGroup) {
 *  return null; // the bucket is not yet available
 * }
 * if (experimentGroup.c) {
 *   // show the new feature
 * }
 */
export function useUserIdExperimentGroup(userInfo: UserInfo, buckets: 2): { a: boolean; b: boolean } | null;
export function useUserIdExperimentGroup(userInfo: UserInfo, buckets: 3): { a: boolean; b: boolean; c: boolean } | null;
export function useUserIdExperimentGroup(
  userInfo: UserInfo,
  buckets: 4,
): { a: boolean; b: boolean; c: boolean; d: boolean } | null;
export function useUserIdExperimentGroup(userInfo: UserInfo, buckets: 2 | 3 | 4) {
  const bucket = useUserIdBucket(userInfo, buckets);
  if (typeof bucket !== 'number') {
    return null;
  }
  switch (buckets) {
    case 2:
      return { a: bucket === 0, b: bucket === 1 };
    case 3:
      return { a: bucket === 0, b: bucket === 1, c: bucket === 2 };
    case 4:
      return { a: bucket === 0, b: bucket === 1, c: bucket === 2, d: bucket === 3 };
  }
}
