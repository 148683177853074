import { ComponentSchema } from './api-schema-utils';
import { BOOKING_PACKAGE_TYPE_FREE } from '../components/Clusters/ClusterSetup/helpers';
import { CLOUD_PROVIDER_MAP } from '../components/Clusters/constants';
import { Package } from '../services/bookingApi';

export type Cluster = ComponentSchema<'ClusterOut'>;
export type PrivateRegion = ComponentSchema<'PydanticPrivateRegionOut'>;
export type PrivateRegionStatusOutput = ComponentSchema<'PydanticPrivateRegionStatus-Output'>;
export type PrivateRegionComponentPhase = ComponentSchema<'PrivateRegionStatusComponentStatusPhase'>;
export type BackupSchedule = ComponentSchema<'PydanticSchedule'>;
export type Backup = ComponentSchema<'PydanticBackup'>;
export type ClusterResources = ComponentSchema<'ClusterOut'>['resources'];
export type QdrantConfiguration = ComponentSchema<'QdrantConfiguration-Output'>;
export type LogLevels = ComponentSchema<'PydanticPrivateRegionConfigIn'>['log_level'];

export function getFreeTierPackage(packages: Package[]) {
  return packages.find((entry) => entry.type === BOOKING_PACKAGE_TYPE_FREE);
}

/**
 * Check if the given cluster is a free tier
 */
export function isClusterFreeTier(cluster?: Cluster) {
  const bookingPackage = cluster?.configuration?.node_configuration.package;
  if (!bookingPackage) {
    return false;
  }
  return bookingPackage.type === BOOKING_PACKAGE_TYPE_FREE;
}

/**
 * Check if user already created a free tier cluster
 */
export function isFreeTierCreated(clusters?: Cluster[]) {
  if (!clusters?.length) {
    return false;
  }
  return clusters.some(isClusterFreeTier);
}

export function getNodeNumber(clusterId: string, nodeId: string): string {
  return nodeId.replace(`qdrant-${clusterId}-`, '');
}

/**
 * Check if the given cluster is a free tier
 */
export const isClusterHybridCloud = (cluster?: Cluster) => cluster?.cloud_provider === CLOUD_PROVIDER_MAP.PRIVATE;

export const getClusterEndpoint = (cluster?: Cluster, fallback = '') => cluster?.state?.endpoint ?? fallback;

export const isClusterHealthy = (cluster: Cluster | null) => cluster?.state?.phase === 'Healthy';

export const findClusterById = (clusterId?: string, clusters?: Cluster[]) => {
  if (!clusters || !clusterId) {
    return undefined;
  }

  return clusters.find((cluster) => cluster.id === clusterId);
};
