import { Link, Typography } from '@mui/material';
import { createRoute } from '@tanstack/react-router';
import { Route as RootRoute } from './__root';
import { PageFrame } from '../components/Authenticated/PageFrame';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Legal',
    },
  ],
  getParentRoute: () => RootRoute,
  path: 'legal',
  component: LegalComponent,
});

function LegalComponent() {
  return (
    <PageFrame title={'Legal information'} hasTopMenu={false}>
      <Typography variant={'h4'}>Credits</Typography>
      <Typography variant={'h6'}>We are using images from:</Typography>
      <Link href="https://www.vecteezy.com/free-vector/login" target="_blank">
        Login Vectors by Vecteezy
      </Link>
    </PageFrame>
  );
}
