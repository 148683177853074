import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Cluster } from '../../../../utils/cluster-utils';

type ClusterSelectProps = {
  selectedCluster?: Cluster;
  availableClusters: Cluster[];
  setCluster: (cluster?: Cluster) => void;
  disabled?: boolean;
};

export function ClusterSelect({ selectedCluster, setCluster, availableClusters, disabled }: ClusterSelectProps) {
  return (
    <FormControl fullWidth={true} data-testid="schedules-cluster-select">
      <InputLabel id="clusterSelectLabel">Cluster</InputLabel>
      <Select<string>
        id="cluster"
        labelId="clusterSelectLabel"
        label="Cluster"
        value={selectedCluster?.id ?? ''}
        onChange={(event) => {
          const newSelectedCluster = availableClusters.find(({ id }) => id === event.target.value);
          setCluster(newSelectedCluster);
        }}
        fullWidth={true}
        disabled={disabled}
      >
        {availableClusters.map((cluster) => (
          <MenuItem value={cluster.id} key={cluster.name}>
            {cluster.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
