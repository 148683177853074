import * as Sentry from '@sentry/react';
import { useEffect, useMemo } from 'react';

export const useSentryUser = (userId?: string, accountId?: string) => {
  // mount while rendering once to identify user before errors potentially happen
  useMemo(() => {
    if (userId || accountId) {
      Sentry.setUser({ id: userId, accountId });
    }
  }, [userId, accountId]);
  // clear the currently set user when unmounting
  useEffect(() => () => Sentry.setUser(null), []);
};
