import { Dispatch, PayloadAction, configureStore, isRejectedWithValue } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { token } from './reducers/token';
import { router } from './router';
import { bookingApi } from './services/bookingApi';
import { clusterApi } from './services/clustersApi';
import { configApi } from './services/configApi';
import { iamApi } from './services/iamApi';
import { paymentApi } from './services/paymentApi';
import { settingsApi } from './services/settingsApi';
import { rtkQueryErrorLogger } from './utils/errorHandlingUtils';
import { attempt } from './utils/func-utils';

/**
 * This RTK middleware checks if the executed action was rejected with a 401 and
 * redirects the user to logout if the condition is met.
 */
type QueryAuthPayload = { status?: number; data?: { detail: string } };

const rtkQueryAuthMiddleware =
  () => (next: Dispatch<PayloadAction<QueryAuthPayload>>) => (action: PayloadAction<QueryAuthPayload>) => {
    if (isRejectedWithValue(action) && action?.payload?.status === 401) {
      if (action.payload.data?.detail) {
        const redirectStr = action.payload.data.detail;
        const redirectSearch = attempt(() => new URL(redirectStr)?.searchParams);
        if (redirectSearch) {
          void router.buildAndCommitLocation({
            to: '/logout',
            search: Object.fromEntries(redirectSearch),
            ignoreBlocker: true,
          });
          return;
        }
      }
      void router.buildAndCommitLocation({
        to: '/logout',
        ignoreBlocker: true,
      });
      return;
    }
    return next(action);
  };

// TODO code-splitting: https://redux.js.org/usage/code-splitting
export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [clusterApi.reducerPath]: clusterApi.reducer,
    [iamApi.reducerPath]: iamApi.reducer,
    [bookingApi.reducerPath]: bookingApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [settingsApi.reducerPath]: settingsApi.reducer,
    [configApi.reducerPath]: configApi.reducer,
    token,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(clusterApi.middleware)
      .concat(iamApi.middleware)
      .concat(bookingApi.middleware)
      .concat(paymentApi.middleware)
      .concat(settingsApi.middleware)
      .concat(configApi.middleware)
      .concat(rtkQueryAuthMiddleware)
      .concat(rtkQueryErrorLogger),
});

export type RootState = ReturnType<typeof store.getState>;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
