import { Box, Button, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';
import { ReactNode } from 'react';
import { DASHBOARD_LAYOUT_GAP, AuthenticatedDashboardEmpty } from './AuthenticatedDashboard';

const styleOverrides = {
  display: 'flex',
  gap: '8px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

const AuthenticatedErrorRender = () => (
  <>
    <Typography variant="h5" component="p" sx={{ textAlign: 'center', mb: 2 }}>
      We're really sorry, but something went wrong. Our team has been notified and is working hard to fix this.
      <br />
      Please try again later. We appreciate your patience and understanding.
    </Typography>
    <Button
      variant="outlined"
      onClick={() => {
        window.location.reload();
      }}
    >
      Try again
    </Button>
  </>
);

const AuthenticatedErrorBoundaryFallbackRoot = () => (
  <AuthenticatedDashboardEmpty>
    <Box sx={{ ...styleOverrides, height: `calc(100vh - ${DASHBOARD_LAYOUT_GAP})` }}>
      <AuthenticatedErrorRender />
    </Box>
  </AuthenticatedDashboardEmpty>
);

const AuthenticatedErrorBoundaryFallback = () => (
  <Box sx={{ ...styleOverrides, height: `calc(100vh - ${DASHBOARD_LAYOUT_GAP})` }}>
    <AuthenticatedErrorRender />
  </Box>
);

/**
 * Error boundary for the private access dashboard.
 * When the `root` prop is `true`, the error is rendererd within an empty dashboard layout. This serves
 * as a fallback for the `AuthenticatedDashboard` component.
 */
export const AuthenticatedErrorBoundary = ({ children, root }: { children: ReactNode; root?: boolean }) => {
  const FallbackComponent = root ? AuthenticatedErrorBoundaryFallbackRoot : AuthenticatedErrorBoundaryFallback;

  return <Sentry.ErrorBoundary fallback={FallbackComponent}>{children}</Sentry.ErrorBoundary>;
};
