import { zodResolver } from '@hookform/resolvers/zod';
import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { memo, useCallback, useState } from 'react';
import { useForm, SubmitHandler, UseFormGetValues } from 'react-hook-form';
import { z } from 'zod';
import { StripePaymentInformation } from './PaymentInformationForm';
import { useSnackbar } from '../../hooks/use-qdrant-snackbar';
import { isFetchApiError, parseFetchError } from '../../services/helpers';
import { useUpdateAccountTaxIdMutation, useDeleteAccountTaxIdMutation } from '../../services/paymentApi';
import { ELLIPSIS_UNICODE_SYMBOL, ZERO_WIDTH_SPACE } from '../../utils/constants';
import { getUserMessage } from '../../utils/errorHandlingUtils';
import { objectHasOwn } from '../../utils/func-utils';
import { StyledItemHeader } from '../Clusters/ClusterSetup/styled-components';
import { ConfirmationDialog } from '../Common/ConfirmationDialog';

const schema = z.object({
  taxId: z
    .string()
    .min(2, 'Tax ID is too short')
    .max(64, 'Tax ID is too long')
    .trim()
    .regex(/^[a-z0-9.\- ]+$/i, 'Invalid tax ID')
    .optional()
    .transform((e) => (e === '' ? undefined : e)),
});

export type FormSchema = z.infer<typeof schema>;

const DialogContent = memo(function DialogContent({ getValues }: { getValues: UseFormGetValues<FormSchema> }) {
  return (
    <>
      Please confirm you entered your data correctly:
      <br />
      {getValues('taxId')}
    </>
  );
});

export const PaymentTaxIdForm = memo(function PaymentTaxIdForm({
  paymentInformation: { id: paymentInformationId, account_id: accountId, vat_id: initialTaxId },
}: {
  paymentInformation: StripePaymentInformation;
}) {
  const [updateAccountTaxId, { isLoading: isUpdating }] = useUpdateAccountTaxIdMutation();
  const [deleteAccountTaxId, { isLoading: isDeleting }] = useDeleteAccountTaxIdMutation();
  const [open, setOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, dirtyFields },
    setError,
    getValues,
    reset,
  } = useForm<FormSchema>({
    mode: 'all',
    resolver: zodResolver(schema),
    defaultValues: { taxId: initialTaxId ?? '' },
  });

  const onSubmit: SubmitHandler<FormSchema> = useCallback(() => {
    setOpen(true);
  }, []);

  const submitAction = useCallback(async () => {
    const { taxId } = getValues();
    if (!taxId) {
      return;
    }
    try {
      const paymentInfo = await updateAccountTaxId({
        tax_id: taxId,
        payment_information_id: paymentInformationId,
        account_id: accountId,
      }).unwrap();
      reset({ taxId: paymentInfo.vat_id ?? undefined }, { keepDefaultValues: false });
      enqueueSnackbar('Successfully updated your tax ID.', { variant: 'success' });
    } catch (err) {
      const error = parseFetchError(err);

      if (isFetchApiError(error)) {
        switch (error.code) {
          case 'E1022':
            setError('taxId', { message: 'Payment details missing or outdated.', type: 'server' });
            break;
          default:
            setError('taxId', {
              message: 'The Tax ID is incorrect, please try again or contact support.',
              type: 'server',
            });
        }
        return;
      }
      enqueueSnackbar(getUserMessage(err), { variant: 'error' } as const);
    }
  }, [getValues, updateAccountTaxId, paymentInformationId, accountId, reset, enqueueSnackbar, setError]);

  const handleDelete = useCallback(() => {
    setConfirmDelete(true);
  }, []);

  const deleteAction = useCallback(async () => {
    try {
      await deleteAccountTaxId({ account_id: accountId, payment_information_id: paymentInformationId }).unwrap();
      reset({ taxId: undefined }, { keepDefaultValues: false });
      enqueueSnackbar('Successfully deleted your tax ID.', { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(getUserMessage(err), { variant: 'error' } as const);
    }
    setConfirmDelete(false);
  }, [accountId, paymentInformationId, deleteAccountTaxId, reset, enqueueSnackbar]);

  let submitText = 'Save Tax ID number';
  if (isUpdating) {
    submitText = `Working ${ELLIPSIS_UNICODE_SYMBOL}`;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <StyledItemHeader>
        <Typography variant="h6" color="primary.main" id="tax-id-heading">
          Tax details
        </Typography>
        <Typography color="text.secondary" id="tax-id-subheading">
          Please introduce a tax ID number belonging to the country of your billing address.
        </Typography>
      </StyledItemHeader>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <TextField
            {...register('taxId')}
            data-sentry-mask={true}
            label="Tax ID number"
            fullWidth={true}
            helperText={errors.taxId?.message ?? ZERO_WIDTH_SPACE}
            error={objectHasOwn(errors, 'taxId')}
            size="small"
          />
        </Grid>
        <Grid item={true} xs={12}>
          <Button
            variant="outlined"
            color="primary"
            type="submit"
            disabled={isUpdating || !isValid || !dirtyFields.taxId}
            endIcon={isUpdating && <CircularProgress size={20} />}
            fullWidth={true}
          >
            {submitText}
          </Button>
        </Grid>
        {initialTaxId && (
          <Grid item={true} xs={12}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleDelete}
              disabled={isDeleting}
              endIcon={isDeleting && <CircularProgress size={20} />}
              fullWidth={true}
            >
              Delete Tax ID
            </Button>
          </Grid>
        )}
      </Grid>
      {open && (
        <ConfirmationDialog
          open={true}
          onClose={() => setOpen(false)}
          title="Tax ID"
          content={<DialogContent getValues={getValues} />}
          actionName="Confirm"
          actionHandler={submitAction}
        />
      )}
      {confirmDelete && (
        <ConfirmationDialog
          open={true}
          onClose={() => setConfirmDelete(false)}
          title="Delete Tax ID"
          content="Are you sure you want to delete the tax ID?"
          actionName="Delete"
          actionHandler={deleteAction}
        />
      )}
    </form>
  );
});
