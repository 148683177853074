import { Typography } from '@mui/material';
import { PaymentConnectedMarketplace } from './PaymentConnectedMarketplace';
import { PaymentConnectedStripe } from './PaymentConnectedStripe';
import { PaymentInformation } from '../../services/paymentApi';

export const PaymentConnected = ({
  accountId,
  paymentInformation,
}: {
  accountId: string;
  paymentInformation: PaymentInformation;
}) => {
  const isBilledBySugerAWS = paymentInformation.type_ === 'suger_aws';
  const isBilledBySugerGCP = paymentInformation.type_ === 'suger_gcp';
  const isBilledBySugerAzure = paymentInformation.type_ === 'suger_azure';
  const isBilledByStripe = paymentInformation.type_ === 'stripe';
  const isBilledCustom = paymentInformation.type_ === 'custom';

  if (isBilledBySugerAWS) {
    return <PaymentConnectedMarketplace provider="aws" marketplaceAccountId={paymentInformation.aws_account_id} />;
  }
  if (isBilledBySugerGCP) {
    return <PaymentConnectedMarketplace provider="gcp" marketplaceAccountId={paymentInformation.gcp_account_id} />;
  }
  if (isBilledBySugerAzure) {
    return <PaymentConnectedMarketplace provider="azure" marketplaceAccountId={paymentInformation.azure_account_id} />;
  }
  if (isBilledCustom) {
    return <Typography variant="body2">Billed based on custom rates.</Typography>;
  }
  if (isBilledByStripe) {
    if (paymentInformation.payment_method_id) {
      return <PaymentConnectedStripe accountId={accountId} paymentInformationId={paymentInformation.id} />;
    }
  }
  return null;
};
