import { ACCOUNT_PRIVILEGES } from './constants/privileges';
import { Account, UserInfo } from '../services/iamApi';

export class AccountMissingError extends Error {
  constructor(readonly userInfo: UserInfo) {
    super();
    this.name = 'AccountMissingError';
    Object.setPrototypeOf(this, AccountMissingError.prototype);
  }

  get defaultAccountId() {
    return this.userInfo.default_account_id ?? this.userInfo.accounts?.[0]?.id;
  }

  override get message() {
    const { email, accounts, user_id, ...info } = this.userInfo;
    return JSON.stringify(info);
  }
}

export function getCurrentAccount(userInfo: UserInfo, accountId?: string) {
  const id = accountId ?? userInfo.default_account_id;
  const predicate = id
    ? (account: Account) => account.id === id
    : (account: Account) => account.owner_email === userInfo.email;

  const account = userInfo.accounts!.find(predicate);
  if (!account) {
    throw new AccountMissingError(userInfo);
  }
  return account;
}

export function accountHasHybridCloudPrivilege(account: Account): boolean {
  return account.privileges?.includes(ACCOUNT_PRIVILEGES.PRIVATE_REGION) ?? false;
}

export function accountHasProgrammaticAccessPrivilege(account: Account): boolean {
  return account.privileges?.includes(ACCOUNT_PRIVILEGES.PROGRAMMATIC_ACCESS) ?? false;
}
