import { ComponentType } from 'react';
import { useAuthenticatedData } from '../contexts/authenticated-data-context';
import { AccountPrivilege } from '../utils/constants/privileges';

export function withPrivilegeRequired<T extends object>(
  WrappedComponent: ComponentType<T>,
  requiredPrivilege: AccountPrivilege,
) {
  const WrapperComponent = (props: T) => {
    const { account } = useAuthenticatedData();
    const hasPrivilege = account.privileges?.includes(requiredPrivilege) ?? false;
    if (!hasPrivilege) {
      throw new Error(`Missing required privilege: ${requiredPrivilege}`);
    }

    return <WrappedComponent {...props} />;
  };

  return WrapperComponent;
}
