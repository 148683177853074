import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useCallback, useState } from 'react';
import { DangerAvatar } from './Avatars';

type ConfirmationDialogDangerActionProps = {
  open?: boolean;
  onClose: () => void;
  actionTarget: string;
  title: string;
  content: string;
  warning?: string;
  actionName: string;
  actionHandler: (confirm?: boolean) => Promise<void>;
  extraConfirmation?: string;
  defaultExtraConfirmation?: boolean;
};
export function ConfirmationDialogDangerAction({
  open = false,
  onClose,
  actionTarget,
  title,
  content,
  warning,
  actionName,
  actionHandler,
  extraConfirmation,
  defaultExtraConfirmation = false,
}: ConfirmationDialogDangerActionProps) {
  const [loadingAction, setLoadingAction] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(false);
  const [extraConfirmationChecked, setExtraConfirmationChecked] = useState(defaultExtraConfirmation);
  const handleActionClick = async () => {
    if (inputValue === actionTarget) {
      setError(false);
      setLoadingAction(true);
      await actionHandler(extraConfirmationChecked);
      setLoadingAction(false);
      setInputValue('');
      onClose();
    } else {
      setError(true);
    }
  };

  const handleClose = useCallback(() => {
    if (!loadingAction) {
      onClose();
    }
  }, [onClose, loadingAction]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      aria-labelledby="cluster-delete-dialog-title"
      aria-describedby="cluster-delete-dialog-description"
    >
      <Box
        sx={{
          display: 'flex',
          p: 3,
        }}
      >
        <DangerAvatar />
        <div>
          <Typography id="cluster-delete-dialog-title" variant="h5">
            {title}
          </Typography>
          <Typography
            id="cluster-delete-dialog-description"
            color="textSecondary"
            sx={{ mt: 1, mb: 2 }}
            variant="body2"
          >
            {content}
          </Typography>
          {extraConfirmation && (
            <FormControlLabel
              label={
                <Typography color="textSecondary" variant="body2">
                  {extraConfirmation}
                </Typography>
              }
              control={
                <Checkbox
                  onChange={(event) => setExtraConfirmationChecked(event.target.checked)}
                  checked={extraConfirmationChecked}
                  size="small"
                />
              }
            />
          )}
        </div>
      </Box>
      {warning && (
        <Box
          sx={{
            display: 'flex',
            backgroundColor: (theme) => alpha(theme.palette.error.main, 0.08),
            py: 2,
            px: 3,
          }}
        >
          <Typography color="error.main" variant="body2">
            {warning}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          py: 2,
          px: 3,
        }}
      >
        <Typography color="textSecondary" variant="body2">
          Please type <b>{actionTarget}</b> to confirm
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          px: 3,
        }}
      >
        <TextField
          error={error}
          required={true}
          fullWidth={true}
          placeholder={actionTarget}
          name="confirmation"
          onChange={(event) => setInputValue(event.target.value)}
          type="text"
          value={inputValue}
          variant="outlined"
          disabled={loadingAction}
          helperText={error ? `Please enter ${actionTarget} to confirm the action` : ' '}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          px: 3,
          py: 1.5,
        }}
      >
        <Button sx={{ mr: 2 }} variant="outlined" onClick={handleClose} disabled={loadingAction}>
          Cancel
        </Button>
        <Button
          sx={{
            backgroundColor: 'error.main',
            '&:hover': {
              backgroundColor: 'error.dark',
            },
          }}
          variant="contained"
          disabled={loadingAction}
          endIcon={loadingAction && <CircularProgress size={14} />}
          onClick={handleActionClick}
        >
          {actionName}
        </Button>
      </Box>
    </Dialog>
  );
}
