import { StripePaymentInformation } from './PaymentInformationForm';
import { Account } from '../../services/iamApi';
import { PaymentInformation } from '../../services/paymentApi';

export const isBillingAddressProvided = (
  paymentInformation: PaymentInformation,
): paymentInformation is StripePaymentInformation =>
  'billing_address' in paymentInformation && paymentInformation.billing_address != null;

export const isBillingInfoProvided = (account: Account, paymentInformation?: PaymentInformation): boolean => {
  if (account.is_billed_manually) {
    return true;
  }
  if (paymentInformation !== undefined) {
    if (
      paymentInformation.type_ === 'suger_aws' ||
      paymentInformation.type_ === 'suger_gcp' ||
      paymentInformation.type_ === 'suger_azure' ||
      paymentInformation.type_ === 'custom'
    ) {
      return true;
    }
    return isBillingAddressProvided(paymentInformation);
  }
  return false;
};

export const isPaymentMethodProvided = (account: Account, paymentInformation?: PaymentInformation): boolean => {
  if (account.is_billed_manually) {
    return true;
  }
  if (paymentInformation != null) {
    if (
      paymentInformation.type_ === 'suger_aws' ||
      paymentInformation.type_ === 'suger_gcp' ||
      paymentInformation.type_ === 'suger_azure' ||
      paymentInformation.type_ === 'custom'
    ) {
      return true;
    }
    return Boolean('payment_method_id' in paymentInformation && paymentInformation.payment_method_id);
  }
  return false;
};

export const isBillingTaxSupportedCountry = (
  paymentInformation: PaymentInformation,
): paymentInformation is StripePaymentInformation =>
  isBillingAddressProvided(paymentInformation) && Boolean(paymentInformation.billing_address?.tax_supported_country);
