import { imageFilters } from '../../styles';

type ImageThemedProps = {
  src: string;
  theme: object;
  alt: string;
  disabled?: boolean;
  style?: object;
};

/**
 * Component for showing an img inverting on the theme mode change to dark
 */
export const ImageThemed = ({ src, theme, alt, disabled = false, style = {}, ...other }: ImageThemedProps) => (
  <img
    alt={alt}
    src={src}
    style={{
      ...style,
      ...imageFilters(theme, disabled),
    }}
    {...other}
  />
);
