import EastIcon from '@mui/icons-material/East';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Grid,
  Link,
  List,
  ListItem,
  Typography,
  ListItemText,
  Skeleton,
} from '@mui/material';
import { useNavigate } from '@tanstack/react-router';
import { useMemo } from 'react';
import { ApiKeyResult } from './ApiKeyResult';
import { useAccountId } from '../../../../../contexts/authenticated-data-context';
import { useTrackInteractionEvent } from '../../../../../hooks/use-event-tracking';
import { isClusterHealthy } from '../../../../../utils/cluster-utils';
import { ClusterQueryCodeExample } from '../../../../Clusters/ClusterQueryCodeExample';
import { getDashboardURL } from '../../../../Clusters/Metrics/utils';
import { useWelcomeStepperContext } from '../WelcomeStepperProvider';

interface IGetStartedStepProps {
  onNext: () => void;
}
const manageClusterCtaLabel = 'Manage your Cluster';
const accessDatabaseCtaLabel = 'Access Database';

const listItems = [
  {
    img: '/cluster-flow-unlock-dashboard-icon.svg',
    text: 'Load Sample Datasets: Explore and quickly access ready-made datasets via the Dashboard.',
    altText: 'Unlock Dashboard Icon',
  },
  {
    img: '/cluster-flow-load-dataset-icon.svg',
    text: 'Load Sample Datasets: Explore and quickly access ready-made datasets via the Dashboard.',
    altText: 'Load Sample Dataset Icon',
  },
  {
    img: '/cluster-flow-explore-tutorials-icon.svg',
    text: 'Explore Tutorials: Learn the Qdrant API with interactive examples in the Dashboard.',
    altText: 'Explore Tutorials Icon',
  },
];

const CardSection = ({
  title,
  listItems,
  buttonLabel,
  buttonAction,
  linkUrl,
  children,
  disabled = false,
  disabledMessage,
}: {
  title: string;
  listItems?: { img: string; text: string; altText: string }[];
  buttonLabel: string;
  buttonAction: () => void;
  linkUrl?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  disabledMessage?: string;
}) => (
  <Card
    sx={{
      px: 5,
      py: 4,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      pointerEvents: disabled ? 'none' : 'auto',
    }}
  >
    <CardHeader sx={{ px: 0, pt: 3, pb: 2, '& span': { fontSize: '1.25em' } }} title={title} />
    {disabled && disabledMessage && (
      <Typography variant="body1" color="textPrimary" mb={2}>
        {disabledMessage}
      </Typography>
    )}
    <CardContent sx={{ px: 0, py: 0, flexGrow: 1 }}>
      {disabled ? (
        <>
          <Skeleton variant="rectangular" height={36} sx={{ mb: 2 }} />
          <Skeleton variant="rectangular" height={36} sx={{ mb: 2 }} />
          <Skeleton variant="rectangular" height={36} sx={{ mb: 2 }} />
        </>
      ) : (
        <>
          {listItems && (
            <List
              sx={{
                p: 0,
                '& li': {
                  p: 0,
                  mb: 4,
                  '& img': { height: 36, mr: 2 },
                  '& div': { m: 0, '& span': { fontSize: '0.875em' } },
                },
              }}
            >
              {listItems.map((item, index) => (
                <ListItem key={index}>
                  <Box component="img" src={item.img} alt={item.altText} />
                  <ListItemText primary={item.text} />
                </ListItem>
              ))}
            </List>
          )}
          {children}
        </>
      )}
    </CardContent>
    <CardActions sx={{ px: 0, pb: 0 }}>
      {linkUrl ? (
        <Link href={linkUrl} target="_blank" tabIndex={disabled ? -1 : 0}>
          <Button variant={'contained'} color={'secondary'} onClick={buttonAction} disabled={disabled}>
            {buttonLabel} <EastIcon sx={{ ml: 1, height: 20 }} />
          </Button>
        </Link>
      ) : (
        <Button variant={'contained'} color={'secondary'} onClick={buttonAction} disabled={disabled}>
          {buttonLabel}
        </Button>
      )}
    </CardActions>
  </Card>
);

export const GetStartedStep = ({ onNext }: IGetStartedStepProps) => {
  const { newCluster, apiKey } = useWelcomeStepperContext();
  const accountId = useAccountId();
  const navigate = useNavigate();

  const trackAccessDatabase = useTrackInteractionEvent({
    label: accessDatabaseCtaLabel,
    location: `${accessDatabaseCtaLabel} Card (Horizontal)`,
    action: 'clicked',
  });

  const trackManageCluster = useTrackInteractionEvent({
    label: manageClusterCtaLabel,
    location: `${manageClusterCtaLabel} Card (Horizontal)`,
    action: 'clicked',
  });

  const dashboardUrl = useMemo(() => getDashboardURL(newCluster!), [newCluster]) ?? '';

  return (
    <>
      <Box component="main">
        <ApiKeyResult />
      </Box>

      <Grid
        container={true}
        spacing={3}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          mb: 6,
        }}
      >
        <Grid item={true} xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardSection
            title="Continue to the Database Dashboard"
            listItems={listItems}
            buttonLabel={accessDatabaseCtaLabel}
            buttonAction={trackAccessDatabase}
            linkUrl={dashboardUrl}
            disabled={!isClusterHealthy(newCluster)}
            disabledMessage="The cluster is not yet ready. Please wait..."
          />
        </Grid>
        <Grid item={true} xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardSection
            title="Use the API via a CLI"
            buttonLabel={manageClusterCtaLabel}
            buttonAction={() => {
              trackManageCluster();
              void navigate({
                to: '/accounts/$accountId/clusters/$clusterId',
                params: { accountId, clusterId: String(newCluster?.id) },
              });
              onNext();
            }}
            disabledMessage="The cluster is not yet ready. Please wait..."
            disabled={!isClusterHealthy(newCluster)}
          >
            <Typography color="textSecondary" component={'p'} sx={{ fontSize: '14px' }}>
              Use this code snippet to access your cluster via a command-line interface:
            </Typography>
            {newCluster && <ClusterQueryCodeExample token={apiKey} clusters={[newCluster]} />}
          </CardSection>
        </Grid>
      </Grid>
    </>
  );
};
