import { createRoute } from '@tanstack/react-router';
import { Route as AccountRoute } from './_account';
import { AuthenticatedDashboard } from '../components/Authenticated/AuthenticatedDashboard';
import { PageFrame } from '../components/Authenticated/PageFrame';
import { PaymentSection } from '../components/Billing/PaymentSection';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Billing',
    },
  ],
  getParentRoute: () => AccountRoute,
  path: 'billing',
  component: BillingComponent,
});

function BillingComponent() {
  return (
    <AuthenticatedDashboard>
      <PageFrame title="Billing Details">
        <PaymentSection />
      </PageFrame>
    </AuthenticatedDashboard>
  );
}
