import { MoreHoriz } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import { MouseEvent, ReactNode, useState } from 'react';

// for better result use as children the components acceptable inside Menu component from MUI
// https://mui.com/material-ui/react-menu/
// but in general it can be any node elements
export function ActionsMenu({ children }: { children: ReactNode }) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="cluster-actions-menu-button"
        aria-label="Cluster actions more"
        aria-controls={open ? 'actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreHoriz />
      </IconButton>

      <Menu
        id="cluster-actions-dropdown-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'cluster-actions-menu-button',
        }}
      >
        {children}
      </Menu>
    </div>
  );
}
