import {
  Backdrop,
  Box,
  Container,
  Typography,
  useMediaQuery,
  // eslint-disable-next-line no-restricted-imports
  useTheme,
} from '@mui/material';
import { Fragment, ReactNode } from 'react';
import { VersionAlert } from './VersionAlert';
import { useOnlineConnection } from '../../hooks/use-online-connection';
import { useWindowFocus } from '../../hooks/use-window-focus';
import { useGetSettingsQuery } from '../../services/settingsApi';

export const Maintenance = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const isFocused = useWindowFocus();
  const isOnline = useOnlineConnection();
  const settingsResponse = useGetSettingsQuery(undefined, {
    refetchOnFocus: true,
    pollingInterval: isFocused && isOnline ? 5_000 : 0,
  });

  if (!settingsResponse.data) {
    return <Fragment key="maintenance">{children}</Fragment>;
  }

  const { maintenance: isMaintenance, tag: imageTag } = settingsResponse.data;

  return (
    <>
      {!isMaintenance ? (
        <Fragment key="maintenance">
          {children}
          <VersionAlert imageTag={imageTag} />
        </Fragment>
      ) : (
        <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 800 }} open={true}>
          <Box
            sx={{
              minWidth: '100vw',
              minHeight: '100vh',
              alignItems: 'center',
              backgroundColor: 'background.paper',
              display: 'flex',
              flexGrow: 1,
              py: '80px',
            }}
          >
            <Container maxWidth="lg">
              <Typography align="center" variant={mobileDevice ? 'h4' : 'h1'}>
                Down for maintenance
              </Typography>
              <Typography align="center" color="textSecondary" sx={{ mt: 0.5 }} variant="subtitle2">
                We are currently down for maintenance, please check back later
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  mt: 6,
                }}
              >
                <Box
                  alt="Under development"
                  component="img"
                  src={`/images/error/error401_${theme.palette.mode}.svg`}
                  sx={{
                    height: 'auto',
                    maxWidth: '100%',
                    width: 400,
                  }}
                />
              </Box>
            </Container>
          </Box>
        </Backdrop>
      )}
    </>
  );
};
