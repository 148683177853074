import { Auth0ContextInterface } from '@auth0/auth0-react';
import { Outlet, createRootRouteWithContext } from '@tanstack/react-router';
import { Suspense, lazy } from 'react';
import { AnalyticsRoot } from '../components/Analytics/AnalyticsProvider';
import { META_TITLE, Meta } from '../router/Meta';
import { rootSearchSchema } from '../router/utils';
import { WEB_STORAGE_PREFIX } from '../utils/constants';
import { Cookies } from '../utils/cookies';

export type AuthContext = Omit<Auth0ContextInterface, 'isLoading' | 'user'> &
  Required<Pick<Auth0ContextInterface, 'user'>>;

type RootRouteContext = {
  queryClient: undefined;
  auth: AuthContext;
};

export const SUGER_ENTITLEMENT_ID_COOKIE_KEY = `${WEB_STORAGE_PREFIX}suger_entitlement_id`;

export const Route = createRootRouteWithContext<RootRouteContext>()({
  meta: () => [
    {
      title: META_TITLE,
    },
  ],
  validateSearch: (search) => rootSearchSchema.parse(search),
  beforeLoad({ search }) {
    if (search.sugerEntitlementId) {
      Cookies.set(SUGER_ENTITLEMENT_ID_COOKIE_KEY, search.sugerEntitlementId);
    }
  },
  component: RootComponent,
});

const DevToolsLazy = lazy(() => import('../router/DevTools').then(({ DevTools }) => ({ default: DevTools })));

function RootComponent() {
  return (
    <>
      <AnalyticsRoot>
        <Meta>
          <Outlet />
        </Meta>
      </AnalyticsRoot>
      {window.__QDRANT_CLOUD__.env === 'dev' && (
        <Suspense>
          <DevToolsLazy />
        </Suspense>
      )}
    </>
  );
}
