import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import { useCallback } from 'react';
import { Cluster } from '../../services/clustersApi';

type Props = {
  accountId: string;
  open: boolean;
  clusters: Cluster[];
  onClose: () => void;
  onSubmit: () => void;
  selectClusters: (clusterIds: string[]) => void;
  selectedClusterIds: string[];
  isCreatingApiKeys: boolean;
  fullScreen: boolean;
};

export const CreateApiKeyDialog = ({
  clusters,
  open,
  onClose,
  onSubmit,
  isCreatingApiKeys,
  fullScreen,
  selectedClusterIds,
  selectClusters,
}: Props) => {
  const submit = useCallback(() => {
    if (selectedClusterIds.length > 0) {
      onSubmit();
    }
  }, [onSubmit, selectedClusterIds]);

  return (
    <Dialog disableEscapeKeyDown={true} open={open} onClose={onClose} fullScreen={fullScreen}>
      <Box display="flex" justifyContent="space-between">
        <DialogTitle variant={'h5'}>Create API key</DialogTitle>
        <DialogActions>
          <Button onClick={onClose} disabled={isCreatingApiKeys}>
            Cancel
          </Button>
          <Button
            disabled={selectedClusterIds.length === 0 || isCreatingApiKeys}
            onClick={submit}
            endIcon={isCreatingApiKeys ? <CircularProgress size={14} /> : undefined}
          >
            Ok
          </Button>
        </DialogActions>
      </Box>
      <DialogContent>
        <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }} mb={5}>
          <Typography variant="h6" id="cluster-selector-label" sx={{ mb: 2 }}>
            Select a cluster
          </Typography>
          <FormControl sx={{ width: '100%' }}>
            <Select
              id="multiple-checkbox"
              multiple={true}
              value={selectedClusterIds}
              input={<OutlinedInput />}
              sx={{ minHeight: 65 }}
              aria-labelledby="cluster-selector-label"
              renderValue={() => {
                const selectedClusters = clusters.reduce<string[]>((clusterNames, cluster) => {
                  if (selectedClusterIds.includes(cluster.id)) {
                    clusterNames.push(cluster.name);
                  }
                  return clusterNames;
                }, []);
                return (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selectedClusters.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                );
              }}
              onChange={(event) => {
                const { value } = event.target;
                const clusterIds = Array.isArray(value) ? value : [value].filter(Boolean);
                selectClusters(clusterIds);
              }}
            >
              <Divider />
              {clusters.map((cluster) => (
                <MenuItem key={cluster.id} value={cluster.id}>
                  <Checkbox checked={selectedClusterIds.includes(cluster.id)} />
                  <ListItemText primary={cluster.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
