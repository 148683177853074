import React, { createContext, useContext } from 'react';
import { PrivateRegion } from '../../../utils/cluster-utils';

type HybridCloudEnvContextType = {
  hybridCloudEnvironment: PrivateRegion;
  fulfilledTimeStamp?: number;
} | null;

const HybridCloudEnvironmentContext = createContext<HybridCloudEnvContextType>(null);

export const useHybridCloudEnvironment = () => useContext(HybridCloudEnvironmentContext);

export const HybridCloudEnvironmentProvider = ({
  hybridCloudEnvironment,
  fulfilledTimeStamp,
  children,
}: {
  hybridCloudEnvironment: PrivateRegion;
  fulfilledTimeStamp?: number;
  children: React.ReactNode;
}) => (
  <HybridCloudEnvironmentContext.Provider value={{ hybridCloudEnvironment, fulfilledTimeStamp }}>
    {children}
  </HybridCloudEnvironmentContext.Provider>
);
