import DarkModeIcon from '@mui/icons-material/Brightness2';
import LightModeIcon from '@mui/icons-material/LightMode';
import { Box, Checkbox, FormControlLabel, ToggleButton, ToggleButtonGroup, Typography, styled } from '@mui/material';
import { CreateThemeSettings } from '../../contexts/settings-context';
import { useSettings } from '../../hooks/use-settings';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
  },
}));

export const UserSettings = () => {
  const { settings, saveSettings } = useSettings();
  const updateUserSettings = (newSettings: Partial<CreateThemeSettings>) => {
    saveSettings(newSettings);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Box>
        <Typography
          color="textSecondary"
          sx={{
            display: 'block',
            mb: 1,
          }}
          variant="overline"
        >
          Appearance
        </Typography>
        <StyledToggleButtonGroup
          value={settings.theme}
          size="small"
          exclusive={true}
          onChange={(_, theme: CreateThemeSettings['theme'] | null) => {
            if (theme !== null) {
              updateUserSettings({ theme });
            }
          }}
        >
          <ToggleButton value="dark" aria-label="Dark">
            <DarkModeIcon />
          </ToggleButton>
          <ToggleButton value="light" aria-label="Light">
            <LightModeIcon />
          </ToggleButton>
        </StyledToggleButtonGroup>
      </Box>
      <Box>
        <Typography
          color="textSecondary"
          sx={{
            display: 'block',
            mb: 1,
          }}
          variant="overline"
        >
          Text sizing
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={settings.responsiveFontSizes}
              name="direction"
              onChange={(event) => updateUserSettings({ responsiveFontSizes: event.target.checked })}
            />
          }
          label="Responsive fonts"
          sx={{ whiteSpace: 'nowrap' }}
        />
      </Box>
    </Box>
  );
};
