import { Box, CircularProgress } from '@mui/material';
import { Cluster } from '../../services/clustersApi';
import { ComponentSchema } from '../../utils/api-schema-utils';
import { SeverityPill } from '../Common/SeverityPill';

export type QdrantClusterState = ComponentSchema<'QdrantClusterState'>;

type NamedClusterPhase = 'active' | 'inactive' | 'failed' | 'busy';

export const getClusterPhase = (state?: QdrantClusterState | null): NamedClusterPhase => {
  if (!state) {
    return 'busy';
  }
  switch (state.phase) {
    case undefined:
    case null:
      return 'inactive';
    case 'Healthy':
      return 'active';
    case 'ManualMaintenance':
      return 'active';
    default:
      if (state.phase.startsWith('Failed')) {
        return 'failed';
      }
      if (!state.actionable) {
        return 'busy';
      }
      return 'inactive';
  }
};

const camelToNaturalCase = (text: string) =>
  text
    .split(/(?=[A-Z])/)
    .join(' ')
    .toLowerCase();

const clusterPhaseToPaletteColor = {
  active: 'success',
  inactive: 'inactive',
  failed: 'error',
  busy: 'warning',
} as const;

export const ClusterState = ({ cluster }: { cluster: Cluster }) => {
  // When cluster is marked for deletion we ignore the actual status and display it as deleting.
  if (cluster.marked_for_deletion_at) {
    return (
      <SeverityPill color="error" role="status" aria-label="Cluster status">
        Deleting
        <CircularProgress size={10} color="inherit" />
      </SeverityPill>
    );
  }

  const phaseKey = getClusterPhase(cluster.state);
  /**
   * When the state is null, the cluster is in progress.
   * In the odd case the phase is `null` or `undefined`, we'll display `Unknown`
   */
  return (
    <SeverityPill color={clusterPhaseToPaletteColor[phaseKey]} role="status" aria-label="Cluster status">
      {camelToNaturalCase(!cluster.state ? 'InProgress' : cluster.state.phase ?? 'Unknown')}
      {cluster.state && (phaseKey === 'active' || phaseKey === 'failed') && (
        <NodesCountByStatus state={cluster.state} status="Ready" />
      )}
      {phaseKey === 'busy' && <CircularProgress size={10} color="inherit" />}
    </SeverityPill>
  );
};

/**
 * Component to display the number of nodes with a given status in format: withStatus/all
 */
function NodesCountByStatus({ state, status }: { state: QdrantClusterState; status: string }) {
  const stateNodes = state.nodes;
  let healthyNodesCount;
  let nodesCount;
  if (!stateNodes) {
    nodesCount = state.replicas;
    healthyNodesCount = state.available_replicas;
  } else {
    const nodes = Object.values(stateNodes);
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    healthyNodesCount = nodes.filter((node) => node?.state?.[status] === 'True').length;
    nodesCount = nodes.length;
  }

  return (
    <Box component="span">
      {healthyNodesCount}/{nodesCount}
    </Box>
  );
}
