import { isRejectedWithValue } from '@reduxjs/toolkit';
import { useSnackbar } from '../hooks/use-qdrant-snackbar';

export const DEFAULT_MESSAGE = 'Sorry, something went wrong.😢 We are working on it. Try to reload the page';
export const NETWORK_OFFLINE_MESSAGE = 'Connection lost. Check your network';
export const SERVER_UNAVAILABLE_MESSAGE =
  'Sorry, our service is temporarily down.😢 Please try again later, we will be back soon.';
export const LIMIT_REACHED_MESSAGE = 'You have reached the limit of clusters number. Please contact us to upgrade.';

const UNAUTHORIZED_ERROR_MESSAGE = 'Looks like your session has expired. Please log in again.';
export const FORBIDDEN_ERROR_MESSAGE =
  'You are not authorized to perform this action. Please contact us if you think it is a mistake.';

let useSnackbarRef;

export function getUserMessage(e) {
  if (!e) {
    return;
  }

  let message;
  const status = e.originalStatus;
  if (!status) {
    return DEFAULT_MESSAGE;
  }

  switch (true) {
    case status === 401:
      message = UNAUTHORIZED_ERROR_MESSAGE;
      break;
    case status === 403 && e.message === 'Limit reached':
      message = LIMIT_REACHED_MESSAGE;
      break;
    case status === 403:
      message = FORBIDDEN_ERROR_MESSAGE;
      break;
    case status >= 400 && status < 500:
      message = DEFAULT_MESSAGE;
      break;
    case status === 503 || status === 504:
      message = SERVER_UNAVAILABLE_MESSAGE;
      break;
    case status >= 500 && status <= 511:
      message = DEFAULT_MESSAGE;
      break;
    default:
      message = DEFAULT_MESSAGE;
  }
  return message;
}

export const createErrorLoggerMiddleware = () => {
  let isNetworkAlertShown = false;
  let lastErrorStatus = null;

  return (_) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action) && window.__QDRANT_CLOUD__.env === 'dev') {
      console.warn(action.error.message, action.payload);
      if (!navigator.onLine && !isNetworkAlertShown) {
        useSnackbarRef.enqueueSnackbar(NETWORK_OFFLINE_MESSAGE, { variant: 'warning' });
        isNetworkAlertShown = true;
      } else if (navigator.onLine && lastErrorStatus !== action.error.originalStatus) {
        useSnackbarRef.enqueueSnackbar(getUserMessage(action.error), { variant: 'error' });
        lastErrorStatus = action.error.originalStatus;
      }
    }

    return next(action);
  };
};

/**
 * Middleware for the store
 * @param api
 * @returns {function(*): function(*): *}
 */
export const rtkQueryErrorLogger = createErrorLoggerMiddleware();

/**
 * This component allows us to call `notistack` outside of React components
 */
export const SnackbarUtilsConfigurator = () => {
  useSnackbarRef = useSnackbar();
  return null;
};
