import { useNavigate } from '@tanstack/react-router';
import { ReactNode, useEffect, useMemo } from 'react';
import { useAuthenticatedData } from '../../../contexts/authenticated-data-context';
import { useSnackbar } from '../../../hooks/use-qdrant-snackbar';
import { useGetClustersByAccountIdQuery } from '../../../services/clustersApi';
import { ACCOUNT_PRIVILEGES, AccountPrivilege } from '../../../utils/constants/privileges';
import { ErrorBox } from '../../Common/ErrorBox';
import { LoadingIndicator } from '../../Common/LoadingIndicator';

const MAX_ALLOWED_CLUSTERS = {
  [ACCOUNT_PRIVILEGES.MAX_NUM_CLUSTERS_20]: 20,
  [ACCOUNT_PRIVILEGES.MAX_NUM_CLUSTERS_30]: 30,
  [ACCOUNT_PRIVILEGES.MAX_NUM_CLUSTERS_50]: 50,
} as const;

const DEFAULT_MAX_ALLOWED_CLUSTERS = 10;

export const getMaxNumClustersAllowed = (privileges: AccountPrivilege[] = []) => {
  const maxNumClustersPrivileges = Object.keys(MAX_ALLOWED_CLUSTERS);
  return privileges.reduce((maxNumClusters, privilege) => {
    if (maxNumClustersPrivileges.includes(privilege)) {
      return Math.max(maxNumClusters, MAX_ALLOWED_CLUSTERS[privilege]);
    }
    return maxNumClusters;
  }, DEFAULT_MAX_ALLOWED_CLUSTERS);
};

export const ClusterLimitReached = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { account } = useAuthenticatedData();
  const accountId = account.id;
  const {
    data: clusters,
    isLoading,
    isError,
    refetch,
  } = useGetClustersByAccountIdQuery({ accountId }, { refetchOnMountOrArgChange: true });

  const maxNumClusters = useMemo(() => getMaxNumClustersAllowed(account.privileges), [account.privileges]);
  const limitReached = Array.isArray(clusters) && clusters.length >= maxNumClusters;

  useEffect(() => {
    if (limitReached) {
      enqueueSnackbar(
        <>
          You have reached the maximum number of clusters allowed for your account.
          <br />
          Please contact support if you wish to increase your cluster limit.
        </>,
        {
          variant: 'warning',
        },
      );
      void navigate({ to: '/accounts/$accountId/clusters', params: { accountId } });
    }
  }, [accountId, enqueueSnackbar, limitReached, navigate]);

  if (isLoading || limitReached) {
    return <LoadingIndicator />;
  }
  if (isError) {
    return <ErrorBox retry={refetch} />;
  }
  return children;
};
