import ArticleIcon from '@mui/icons-material/Article';
import CodeIcon from '@mui/icons-material/Code';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import TerminalIcon from '@mui/icons-material/Terminal';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Box, Link, ListItemIcon, MenuItem, MenuList, TableCell, TableRow, Tooltip } from '@mui/material';
// eslint-disable-next-line no-restricted-imports
import { useTheme } from '@mui/material/styles';
import { useNavigate } from '@tanstack/react-router';
import { compare, validate } from 'compare-versions';
import { useMemo, useCallback, useEffect } from 'react';
import { useAccountId } from '../../../contexts/authenticated-data-context';
import { useSnackbar } from '../../../hooks/use-qdrant-snackbar';
import { HybridCloudIcon } from '../../../icons/hybrid-cloud-icon';
import { useLazyGetHybridCloudEnvQuery, useUnsuspendClusterMutation } from '../../../services/clustersApi';
import { isFetchMutationError } from '../../../services/helpers';
import { Cluster, isClusterFreeTier, isClusterHybridCloud } from '../../../utils/cluster-utils';
import { getUserMessage } from '../../../utils/errorHandlingUtils';
import { ActionsMenu } from '../../Common/ActionsMenu';
import { CodeSnippetButton } from '../../Common/CodeSnippetButton';
import { SeverityPill } from '../../Common/SeverityPill';
import { ClusterScaleAction } from '../ClusterButtons/ClusterScaleAction';
import { ClusterState } from '../ClusterState';
import { ClusterConfigurationWidget } from '../ClusterWidgets';
import { EnvironmentName } from '../EnvironmentName';
import { getDashboardURL } from '../Metrics/utils';
import { CLOUD_PROVIDER_IMGS, CLOUD_PROVIDER_MAP } from '../constants';
import { getProviderImage } from '../helpers';

type ClusterTableRowProps = {
  cluster: Cluster;
  linkToEnv: boolean;
  latestVersion: string;
};

export const ClusterTableRow = ({ cluster, linkToEnv, latestVersion }: ClusterTableRowProps) => {
  const accountId = useAccountId();
  const navigate = useNavigate();

  const currentVersion = cluster.state?.version;

  const theme = useTheme();
  const cloudProviderImg = getProviderImage(cluster.cloud_provider, theme);
  const { enqueueSnackbar } = useSnackbar();
  const [unsuspendCluster, { isLoading: isReactivatingCluster }] = useUnsuspendClusterMutation();
  const [
    getHybridCloudEnv,
    { data: hybridCloudEnv, isError: errorLoadingHybridCloudEnv, isLoading: isLoadingHybridCloudEnv },
  ] = useLazyGetHybridCloudEnvQuery();

  const isHybridCloudCluster = isClusterHybridCloud(cluster);
  useEffect(() => {
    if (isHybridCloudCluster) {
      void getHybridCloudEnv({ account_id: accountId, hybrid_cloud_env_id: cluster.private_region_id! });
    }
  }, [accountId, isHybridCloudCluster, getHybridCloudEnv, cluster.private_region_id]);

  const handleUnsuspendCluster = useCallback(async () => {
    // Calls the api to reactivate the given cluster.
    const response = await unsuspendCluster({ clusterId: cluster.id, accountId });
    if (isFetchMutationError(response)) {
      enqueueSnackbar(getUserMessage(response.error), { variant: 'error' });
    } else {
      enqueueSnackbar('Cluster reactivated successfully', { variant: 'success' });
    }
  }, [cluster, accountId, unsuspendCluster, enqueueSnackbar]);

  const isClusterSuspended =
    cluster.state?.current === 'suspended' || (cluster.state === null && cluster.configuration?.num_nodes === 0);

  const dashboardUrl = useMemo(() => getDashboardURL(cluster), [cluster]);

  const upgradeAvailable =
    currentVersion !== undefined &&
    currentVersion !== null &&
    validate(latestVersion) &&
    validate(currentVersion) &&
    compare(currentVersion, latestVersion, '<');

  return (
    <>
      <TableRow hover={true} key={cluster.id}>
        <TableCell />
        {/* name and provider */}
        <TableCell width={'25%'}>
          <Box
            sx={{
              cursor: 'pointer',
              mr: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'revert',
                gap: '4px',
                alignItems: 'baseline',
                justifyContent: 'space-between',
              }}
            >
              {/* name */}
              <Tooltip title={'See Details'} placement={'top'} arrow={true}>
                <Link
                  display="block"
                  component="button"
                  variant="h6"
                  onClick={() =>
                    navigate({
                      to: '/accounts/$accountId/clusters/$clusterId',
                      params: { accountId, clusterId: cluster.id },
                    })
                  }
                  color="text.primary"
                  underline="hover"
                  textAlign="left"
                  id={`cluster-${cluster.id}`}
                >
                  <span>{cluster.name}</span>
                  <span style={{ position: 'relative', top: '2px', left: '4px' }}>
                    <OpenInNewIcon sx={{ fontSize: 16 }} />
                  </span>
                </Link>
              </Tooltip>
              {isClusterFreeTier(cluster) && (
                <SeverityPill color="primary" small={true}>
                  Free tier
                </SeverityPill>
              )}
            </Box>
          </Box>
        </TableCell>
        <TableCell>
          <ClusterConfigurationWidget cluster={cluster} />
        </TableCell>
        <TableCell>
          {isHybridCloudCluster ? (
            <Tooltip title={CLOUD_PROVIDER_IMGS[CLOUD_PROVIDER_MAP.PRIVATE].tooltip}>
              <HybridCloudIcon fontSize="large" />
            </Tooltip>
          ) : (
            <Box
              sx={{
                alignItems: 'center',
                borderRadius: 1,
                display: 'flex',
                height: 40,
                justifyContent: 'center',
                overflow: 'hidden',
                width: 40,
                p: '0.2rem',
              }}
            >
              {cloudProviderImg}
            </Box>
          )}

          <EnvironmentName
            isError={errorLoadingHybridCloudEnv}
            isLoadingHybridCloudEnv={isLoadingHybridCloudEnv}
            isHybridCloudCluster={isHybridCloudCluster}
            hybridCloudEnv={hybridCloudEnv}
            linkToEnv={linkToEnv}
            regionName={cluster.cloud_region}
          />
        </TableCell>

        {/* status */}
        <TableCell sx={{ minWidth: 200 }}>
          <ClusterState cluster={cluster} />
          {upgradeAvailable && (
            <Box sx={{ mt: 1 }}>
              <SeverityPill color="warning">Upgrade available</SeverityPill>
            </Box>
          )}
        </TableCell>

        {/* actions */}
        <TableCell align="center">
          <ActionsMenu>
            <MenuList sx={{ minWidth: 100, maxWidth: '100%' }}>
              <MenuItem
                onClick={() =>
                  navigate({
                    to: '/accounts/$accountId/clusters/$clusterId',
                    params: { accountId, clusterId: cluster.id },
                  })
                }
              >
                <ListItemIcon>
                  <VisibilityOutlinedIcon />
                </ListItemIcon>
                Details
              </MenuItem>
              {isClusterSuspended && (
                <MenuItem onClick={handleUnsuspendCluster} disabled={isReactivatingCluster}>
                  <ListItemIcon>
                    <PlayCircleOutlinedIcon />
                  </ListItemIcon>
                  Reactivate
                </MenuItem>
              )}
              {dashboardUrl && (
                <MenuItem onClick={() => window.open(dashboardUrl, '_blank')}>
                  <ListItemIcon>
                    <TerminalIcon />
                  </ListItemIcon>
                  Dashboard
                </MenuItem>
              )}

              <MenuItem
                onClick={() =>
                  navigate({
                    to: '/accounts/$accountId/clusters/$clusterId',
                    params: { accountId, clusterId: cluster.id },
                    hash: 'logs',
                  })
                }
              >
                <ListItemIcon>
                  <ArticleIcon />
                </ListItemIcon>
                Logs
              </MenuItem>

              <ClusterScaleAction accountId={accountId} cluster={cluster} trackingLocation="Cluster Dropdown">
                <MenuItem>
                  <ListItemIcon>
                    <ImportExportIcon />
                  </ListItemIcon>
                  Scale
                </MenuItem>
              </ClusterScaleAction>

              <CodeSnippetButton cluster={cluster}>
                <MenuItem>
                  <ListItemIcon>
                    <CodeIcon />
                  </ListItemIcon>
                  Usage Examples
                </MenuItem>
              </CodeSnippetButton>
            </MenuList>
          </ActionsMenu>
        </TableCell>
      </TableRow>
    </>
  );
};
