import { Box, Card, CardMedia, Link, ListItem, Typography } from '@mui/material';
import { NewsEntry } from './data/news-feed';

const getNewsImageSrc = (entry: NewsEntry) =>
  `${entry.link.replace('/articles/', '/articles_data/')}preview/preview.webp`;

const decodeHtml = (html: string) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

export const NewsListItem = ({ newsEntry }: { newsEntry: NewsEntry }) => (
  <ListItem>
    <Card sx={{ width: '100%', display: 'flex', gap: 3, p: 3 }}>
      <Box sx={{ flex: '0 0 auto' }}>
        <CardMedia
          component="img"
          sx={{ width: 151 }}
          image={getNewsImageSrc(newsEntry)}
          alt={newsEntry.title}
          style={{
            height: '100%',
            verticalAlign: 'middle',
            maxHeight: 100,
            minHeight: 100,
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ flex: '1 0 auto' }}>
          <Link variant="h5" color="text.primary" href={newsEntry.link} target="_blank">
            {decodeHtml(newsEntry.title)}
          </Link>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            {decodeHtml(newsEntry.content)}
          </Typography>
        </Box>
      </Box>
    </Card>
  </ListItem>
);
