import { useEffect, useState } from 'react';

export const useOnlineConnection = () => {
  const [isOnline, setOnline] = useState(true);

  useEffect(() => {
    const handleOnline = () => {
      setOnline(true);
    };
    const handleOffline = () => {
      setOnline(false);
    };

    window.addEventListener('online', handleOnline, false);
    window.addEventListener('offline', handleOffline, false);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return isOnline;
};
