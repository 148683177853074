// Support of 'ansi-to-react' for React 18: https://github.com/nteract/ansi-to-react/issues/80#issuecomment-1483164943
import Ansi from '@cocalc/ansi-to-react';
import { Box, Card, CardContent, CircularProgress, Paper, TableContainer, Typography } from '@mui/material';
import { useGetClusterLogsByClusterIdQuery } from '../../services/clustersApi';
import { PrivateRegion } from '../../utils/cluster-utils';
import { CopyToClipboardText } from '../Common/CopyToClipboardText';

type LogsResult = { data: { result: [{ values: [string, string][] }] } };

type ClusterLogsProps = {
  isOnPremiseCluster: boolean;
  clusterId: string;
  accountId: string;
  privateRegion?: PrivateRegion;
};

export function ClusterLogs({ isOnPremiseCluster, clusterId, accountId, privateRegion }: ClusterLogsProps) {
  const queryResult = useGetClusterLogsByClusterIdQuery({
    clusterId,
    accountId,
  });
  const { data, isLoading, isError } = queryResult as {
    data?: LogsResult;
    isLoading: boolean;
    isError: boolean;
  };

  const logValues = data?.data.result[0].values;
  const hasLogs = logValues != null && logValues.length > 0;

  if (isOnPremiseCluster) {
    const command = `kubectl logs -n ${privateRegion?.config?.namespace ?? ''} -l cluster-id=${clusterId}`;
    return (
      <Card sx={{ mt: 3 }}>
        <CardContent>
          <div>You can access the logs of the Qdrant cluster with kubectl:</div>
          <CopyToClipboardText text={command} useCodeStyle={true} wrapLines={false} />
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      {!isError ? (
        <Box>
          <Box pt={2}>
            <Typography variant={'h5'}>Logs</Typography>
          </Box>
          {isLoading && (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="30vh">
              <CircularProgress />
            </Box>
          )}
          {!isLoading && !hasLogs && (
            <Box>
              <Box display="flex" justifyContent="center" alignItems="center" minHeight="30vh">
                <Typography>No logs in the last 72 hours</Typography>
              </Box>
            </Box>
          )}
          {!isLoading && hasLogs && (
            <Box pt={2} mb={2}>
              <Paper variant={'outlined'}>
                <TableContainer
                  sx={(theme) => ({
                    p: 3,
                    fontSize: theme.typography.body2.fontSize,
                  })}
                >
                  {logValues.map(([nanoTimestamp, plainLog]) => (
                    <Box mb={1} key={nanoTimestamp}>
                      <Ansi>{plainLog}</Ansi>
                    </Box>
                  ))}
                </TableContainer>
              </Paper>
            </Box>
          )}
        </Box>
      ) : (
        <Typography variant="body2" sx={{ p: 3 }}>
          Logs are temporarily unavailable
        </Typography>
      )}
    </>
  );
}
