import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Link, SxProps, Tooltip } from '@mui/material';
import { useNavigate } from '@tanstack/react-router';
import { parseHumanReadable } from 'cron-js-parser';
import parser from 'cron-parser';
import { compareAsc, format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { useCallback, useMemo } from 'react';
import { BACKUPS_DATE_FORMAT } from './constants';
import { useConfirmationAction } from '../../../hooks/use-confirmation-action';
import { useSnackbar } from '../../../hooks/use-qdrant-snackbar';
import { useDeleteClusterBackupScheduleMutation } from '../../../services/clustersApi';
import { isFetchMutationError } from '../../../services/helpers';
import { BackupSchedule, Cluster } from '../../../utils/cluster-utils';
import { attempt } from '../../../utils/func-utils';
import { getFormattedRemainingTimeInterval } from '../../../utils/helpers';
import { ConfirmationDialog } from '../../Common/ConfirmationDialog';
import { QdrantTable } from '../../Common/QdrantTable';

type BackupScheduleTableProps = {
  availableClusters?: Cluster[];
  defaultCluster?: Cluster;
  accountId: string;
  backupSchedules?: BackupSchedule[];
  onEditClick: (schedule: BackupSchedule) => void;
  sx?: SxProps;
};

const COLUMNS = [
  { title: 'Cluster', dataKey: 'clusterName' as const },
  { title: 'Schedule', dataKey: 'schedule' as const },
  { title: 'Retention', dataKey: 'retention' as const },
  { title: 'Last Backup', dataKey: 'lastBackup' as const },
  { title: 'Next backup in', dataKey: 'nextBackup' as const },
];

const options = {
  currentDate: new Date().toISOString(),
  utc: true,
};

const lowerCaseFirstLetter = (text: string) => {
  if (!text) {
    return text;
  }

  return text[0].toLowerCase() + text.slice(1);
};

const getRemainingTimeInterval = (date: Date) =>
  attempt(
    () => getFormattedRemainingTimeInterval(date),
    () => '-',
  );

export const BackupScheduleTable = ({
  accountId,
  defaultCluster,
  availableClusters,
  backupSchedules,
  onEditClick,
}: BackupScheduleTableProps) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { confirmAction, showConfirmation, hideConfirmation } = useConfirmationAction();

  const [deleteClusterBackupSchedule, { isLoading: deletingBackup }] = useDeleteClusterBackupScheduleMutation();

  const handleBackupScheduleDelete = useCallback(
    async (scheduleId?: string, clusterId?: string, deleteBackups: boolean = false) => {
      if (!scheduleId || !clusterId) {
        return;
      }

      const result = await deleteClusterBackupSchedule({ clusterId, accountId, scheduleId, deleteBackups });
      if (isFetchMutationError(result)) {
        enqueueSnackbar('There was a problem deleting the schedule', { variant: 'error' });
      } else {
        enqueueSnackbar('Schedule deleted successfully', { variant: 'success' });
      }
    },
    [enqueueSnackbar, deleteClusterBackupSchedule, accountId],
  );

  // If a defaultCluster is provided there is no need to display the `clusterName` column
  // as all schedules belong to it.
  const columns = defaultCluster ? COLUMNS.filter(({ dataKey }) => dataKey !== 'clusterName') : COLUMNS;

  const getClusterName = useCallback(
    (scheduleClusterId?: string): React.ReactNode => {
      if (defaultCluster || !scheduleClusterId) {
        return undefined;
      }
      const cluster = availableClusters?.find(({ id: clusterId }) => clusterId === scheduleClusterId);
      const clusterId = cluster?.id;

      if (clusterId) {
        return (
          <Tooltip title={'See Details'} placement={'top'} arrow={true}>
            <Link
              display="block"
              component="button"
              variant="body1"
              onClick={() =>
                navigate({
                  to: '/accounts/$accountId/clusters/$clusterId',
                  params: { accountId, clusterId },
                  hash: 'backups',
                })
              }
              color="text.primary"
              underline="hover"
              textAlign="left"
            >
              {cluster.name}
            </Link>
          </Tooltip>
        );
      }

      return undefined;
    },
    [availableClusters, defaultCluster, accountId, navigate],
  );

  const rows = useMemo(() => {
    if (!backupSchedules?.length) {
      return [];
    }
    const rows = Array.from(backupSchedules);
    return rows.map((backupSchedule) => {
      const { cron, retention, created_at: createdAt, cluster_id: clusterId } = backupSchedule;
      const interval = parser.parseExpression(cron, options);

      const lastBackup = interval.prev().toDate();
      const nextBackup = interval.next().toDate();
      const lastBackupUTC = toZonedTime(lastBackup, 'UTC');
      const lastBackupFormatted = `${format(lastBackupUTC, BACKUPS_DATE_FORMAT)} (UTC)`;

      const humanReadableScheduleUTC = parseHumanReadable(
        cron,
        { runOnWeekDay: { dayIndex: 0, isLastWeek: false } },
        'en',
      );
      const schedule = `${humanReadableScheduleUTC} (UTC)`;
      const nextBackupIn = getRemainingTimeInterval(nextBackup);
      return {
        schedule,
        retention: `${retention} days`,
        lastBackup: compareAsc(lastBackup, new Date(createdAt)) === -1 ? 'Never' : lastBackupFormatted,
        clusterName: getClusterName(clusterId),
        nextBackup: (
          <Tooltip title={`${format(lastBackupUTC, BACKUPS_DATE_FORMAT)} (UTC)`}>
            <>{nextBackupIn}</>
          </Tooltip>
        ),
        rowData: { ...backupSchedule, schedule },
      };
    });
  }, [backupSchedules, getClusterName]);

  const actions = useMemo(
    () => [
      {
        name: 'Edit',
        getHandler: (data: BackupSchedule) => () => onEditClick(data),
        isDisabled: () => false,
        icon: <EditIcon sx={(theme) => ({ color: theme.palette.primary.main })} />,
      },
      {
        name: 'Delete',
        getHandler: (data: BackupSchedule & { schedule: string }) => () =>
          showConfirmation({
            actionName: 'Delete',
            title: 'Delete Schedule',
            content: `Are you sure you want to delete the schedule set ${lowerCaseFirstLetter(data.schedule)}?`,
            actionHandler: (deleteScheduleBackups: boolean) =>
              handleBackupScheduleDelete(data.id, data.cluster_id, deleteScheduleBackups),
            extraConfirmation: 'Delete backups created by this schedule',
            withConfirm: false,
          }),
        isDisabled: ({ cluster_id: clusterId }: BackupSchedule) => deletingBackup || !clusterId || !accountId,
        icon: <DeleteIcon sx={(theme) => ({ color: theme.palette.error.main })} />,
      },
    ],
    [handleBackupScheduleDelete, accountId, deletingBackup, onEditClick, showConfirmation],
  );

  return (
    <>
      <QdrantTable columns={columns} rows={rows} rowActions={actions} pagination={true} ariaLabel="Backup schedules" />
      {confirmAction && <ConfirmationDialog open={true} onClose={hideConfirmation} {...confirmAction} />}
    </>
  );
};
