import { Typography, Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { ReactNode } from 'react';

export const DangerZone = ({
  header,
  description,
  action,
}: {
  header: ReactNode;
  description: ReactNode;
  action: ReactNode;
}) => (
  <Card>
    <CardHeader
      title="Danger Zone"
      sx={{
        color: 'error.main',
      }}
    />
    <Divider />
    <CardContent>
      <Typography variant="h6" color="error.secondary" gutterBottom={true}>
        {header}
      </Typography>
      <Grid container={true} columns={2} flexWrap="nowrap" gap={2} alignItems="center">
        <Grid item={true} flex="1">
          <Typography variant="body2">{description}</Typography>
        </Grid>
        <Grid item={true} flex="0 0 auto">
          {action}
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);
