import { AuthenticationError, OAuthError, User } from '@auth0/auth0-react';
import { createRoute, redirect, useParams, useRouteContext } from '@tanstack/react-router';
import { Outlet, useNavigate } from '@tanstack/react-router';
import { LibErrorCodes, SpecErrorCodes } from 'auth0-js';
import { FunctionComponent, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route as RootRoute } from './__root';
import { AccountRetrievalErrorBoundary } from '../components/Authenticated/AccountRetrievalErrorBoundary';
import { AuthenticatedErrorBoundary } from '../components/Authenticated/AuthenticatedErrorBoundary';
import { LoadingIndicator } from '../components/Common/LoadingIndicator';
import { AuthenticatedDataContext } from '../contexts/authenticated-data-context';
import { useAnalyticsIdentification } from '../hooks/use-event-tracking';
import { useSentryUser } from '../hooks/use-sentry-user';
import { useSignInPrompts } from '../hooks/use-sign-in-prompts';
import { useSugerRegistration } from '../hooks/use-suger-registration';
import { setToken } from '../reducers/token';
import { authenticatedSearchSchema } from '../router/utils';
import { isFetchApiError, isFetchBaseQueryError, parseFetchError } from '../services/helpers';
import { UserInfo, useCreateAccountMutation, useGetUserInfoQuery } from '../services/iamApi';
import { AccountMissingError, getCurrentAccount } from '../utils/account-utils';
import { useUserIdBucket } from '../utils/analytics-utils';
import { authRedirectUrl } from '../utils/constants';
import { logException } from '../utils/error-utils';

const ONE_HOUR_MS = 3_600_000;

// See: https://github.com/auth0/auth0-react/blob/main/src/utils.tsx#L18
function isOAuthError(err: unknown): err is OAuthError {
  return err != null && typeof err === 'object' && 'error' in err && typeof err.error === 'string';
}

function isAuthenticationError(err: unknown): err is AuthenticationError {
  return err != null && err instanceof AuthenticationError;
}

function buildAccountName(name: string): string {
  return `${name} - Base Account`;
}

const withAuthenticationRequired = <P extends object>(
  Component: FunctionComponent<P & { user: User }>,
): FunctionComponent<P> =>
  function WithAuthenticationRequired(props: P) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
      auth: { user, getAccessTokenSilently },
    } = useRouteContext({ from: '__root__' });
    const [accessTokenLastModified, setAccessTokenLastModified] = useState<number>();

    const setAccessToken = useCallback(
      async ({ ignoreCache }: { ignoreCache: boolean }) => {
        const cacheMode = ignoreCache ? 'off' : 'on';
        try {
          const token = await getAccessTokenSilently({
            cacheMode,
            authorizationParams: {
              redirect_uri: authRedirectUrl,
              audience: window.__QDRANT_CLOUD__.auth0.audience,
              scope: window.__QDRANT_CLOUD__.auth0.scope,
            },
          });
          // Store token in redux store.
          dispatch(setToken(token));
          setAccessTokenLastModified(Date.now());
        } catch (err) {
          const logoutRedirect = (authError?: string) => navigate({ to: '/logout', search: { aerr: authError } });
          // See https://community.auth0.com/t/getaccesstokensilently-throws-error-login-required/52333/4
          if (isOAuthError(err)) {
            const authError = err.error as LibErrorCodes | SpecErrorCodes | 'mfa_required';
            const authErrorDesc = err.error_description;
            switch (authError) {
              case 'login_required' /* https://github.com/auth0/auth0-spa-js/blob/fbe1344/src/Auth0Client.ts#L928 */:
              case 'invalid_token' /* Token expired */:
              case 'consent_required':
                return logoutRedirect(authErrorDesc ?? authError);
              /*
               * Thrown when network requests to the Auth server timeout.
               * It can happen if adquiring a lock between tabs takes too long, see:
               * https://github.com/auth0/auth0-spa-js/blob/fbe1344/src/Auth0Client.ts#L689
               * or if the network request to the Auth server times out, see:
               * https://github.com/auth0/auth0-spa-js/blob/fbe1344/src/Auth0Client.ts#L897C50-L897C75
               * Adcquiring a lock between tabs can take up 50 secs before throwing, so we
               * set `authorizeTimeoutInSeconds` to 10 secs to minimize the chances of this happening.
               */
              case 'timeout':
              case 'request_error':
                return; // do nothing, let the user try again in 1 hour or it will lead to 401 and logout.
              /* The next ones are considered 'recoverable errors': */
              case 'mfa_required' /* https://github.com/auth0/auth0-spa-js/blob/f4f233d/src/http.ts#L145 */:
              case 'interaction_required':
              case 'account_selection_required':
                await logoutRedirect(); // and log the exception below...
                break;
              default:
            }
            switch (authErrorDesc) {
              case 'Login required':
              case 'Invalid authorization code':
                // It's clear what to do here, logout and redirect to login.
                return logoutRedirect(authErrorDesc);
              default:
            }
          }
          // Something we didn't account for happened, send to Sentry and proceed.
          logException(err, {
            level: 'error',
            tags: { context: 'auth', action: 'get-access-token-silently', route: window.location.toString() },
            extra: { cacheMode, error: isOAuthError(err) ? err.error_description : undefined },
          });
        }
      },
      [getAccessTokenSilently, dispatch, navigate],
    );

    useEffect(() => {
      if (!accessTokenLastModified) {
        // Once token is stored in redux store, continue auth flow.
        void setAccessToken({ ignoreCache: false });
        return;
      }
      // Refresh the access token every hour when changing routes.
      if (Date.now() - accessTokenLastModified > ONE_HOUR_MS) {
        void setAccessToken({ ignoreCache: true });
      }
      // Refresh the access token every hour if the user is on the same route.
      const id = setInterval(() => setAccessToken({ ignoreCache: true }), ONE_HOUR_MS);
      // Change of routes causes unmount.
      return () => clearInterval(id);
    }, [accessTokenLastModified, setAccessToken, navigate]);

    if (!accessTokenLastModified) {
      return <LoadingIndicator />;
    }

    return (
      <AccountRetrievalErrorBoundary
        beforeCapture={(scope, error) => {
          if (error instanceof AccountMissingError && error.defaultAccountId) {
            scope.setTag('ignored', true);
            return; // do nothing, a redirection/reset will occur with defaultAccountId.
          }
          if (window.__QDRANT_CLOUD__.env === 'dev' || window.__QDRANT_CLOUD__.env === 'local') {
            console.error(error);
          }
          scope.setTag('context', 'account');
          scope.setLevel('fatal');
        }}
      >
        <Component {...props} user={user} />
      </AccountRetrievalErrorBoundary>
    );
  };

const withAccountRetrieval = <T extends object>(
  Component: FunctionComponent<T & { userInfo: UserInfo }>,
): FunctionComponent<T> =>
  function WithAccountRetrieval(props: T) {
    const { user } = props as T & { user: User };
    const createAccountPayload = useMemo(
      () => (user.name != null && user.sub != null ? { name: buildAccountName(user.name), owner_id: user.sub } : null),
      [user],
    );
    const [createAccount] = useCreateAccountMutation();
    const creatingAccountRef = useRef(false);
    const userInfo = useGetUserInfoQuery();
    const userInfoError = userInfo.error;
    const navigate = useNavigate();
    const logoutRedirect = useCallback(() => {
      void navigate({ to: '/logout' });
    }, [navigate]);
    const [, setRejectedPromise] = useState();

    useSentryUser(userInfo.data?.id);

    useEffect(() => {
      if (!userInfoError || creatingAccountRef.current) {
        // Do nothing if userInfo is available or we're already creating an account.
        return;
      }
      // Error other than 404, send to Sentry and logout.
      if (!isFetchBaseQueryError(userInfoError) || userInfoError.status !== 404) {
        throw parseFetchError(userInfoError);
      }
      // Some things are missing with Auth0 user object, send to Sentry and logout.
      if (createAccountPayload === null) {
        logException(new Error('Properties `name` or `sub` missing in the Auth0 user object'), {
          tags: { context: 'auth', action: 'create-account', route: window.location.toString() },
        });
        return logoutRedirect();
      }
      // Account not found, create it.
      (async () => {
        /*
         * Prevent multiple calls to createAccount. This can happen if the user goes back in the browser history
         * to where a (pushState) redirection occurs - from which currently there are none. This is still a fail-safe.
         */
        creatingAccountRef.current = true;
        try {
          await createAccount(createAccountPayload).unwrap();
        } catch (err) {
          const error = parseFetchError(err);
          if (isFetchApiError(error) && error.code === 'E1001') {
            // Account already exists, this can happen if the user deleted the account.
            return logoutRedirect();
          }
          setRejectedPromise(() => {
            throw error; // triggers error boundary, see: https://stackoverflow.com/a/73823152
          });
        } finally {
          creatingAccountRef.current = false;
        }
      })();
    }, [userInfoError, createAccountPayload, createAccount, navigate, logoutRedirect]);

    if (userInfo.isFetching || userInfoError) {
      return <LoadingIndicator />;
    }
    if (!userInfo.data?.id) {
      // If not available data is found (deleted account) then logout.
      logoutRedirect();
      return null;
    }

    return <Component {...props} userInfo={userInfo.data} />;
  };

const AuthenticatedEvents = memo(function AuthenticatedEvents({
  accountId,
  userId,
}: {
  accountId: string;
  userId: string;
}) {
  useAnalyticsIdentification(userId);
  useSugerRegistration(accountId);
  useSignInPrompts(userId);
  return null;
});

/*
 * This component renders a matched <Route> within the <Outlet> element, without re-rendering.
 * However, when any route param change (particularly accountId), a re-render will occur.
 * If new `userInfo` is available (cache invalidation), it will be fetched in the parent HOC to passed down here.
 */
const AuthenticatedComponent = withAuthenticationRequired(
  withAccountRetrieval(function AuthenticatedComponent({ userInfo }: { userInfo: UserInfo }) {
    // eslint-disable-next-line no-restricted-syntax
    const { accountId: accountIdParam } = useParams({ strict: false });
    const account = getCurrentAccount(userInfo, accountIdParam);
    const accountId = account.id;

    useSentryUser(userInfo.id, accountId);
    const userBucket = useUserIdBucket(userInfo);
    if (userBucket === null) {
      return null;
    }
    return (
      <AuthenticatedDataContext.Provider value={{ userInfo, account, userBucket }}>
        <AuthenticatedErrorBoundary root={true}>
          <Outlet />
          <AuthenticatedEvents accountId={accountId} userId={userInfo.id} />
        </AuthenticatedErrorBoundary>
      </AuthenticatedDataContext.Provider>
    );
  }),
);

export const Route = createRoute({
  getParentRoute: () => RootRoute,
  id: 'authenticated',
  validateSearch: (search) => authenticatedSearchSchema.parse(search),
  beforeLoad({ context: { auth } }) {
    if (auth.error) {
      const isAccessDenied = isAuthenticationError(auth.error) && auth.error.error === 'access_denied';
      // Something went wrong on the auth0 side (or network request failed), send to Sentry and logout.
      logException(auth.error, {
        tags: { context: 'auth', action: 'authenticate', route: window.location.toString() },
        // Access denied can happen if the user fails to be authorized in Auth0.
        level: isAccessDenied ? 'info' : 'fatal',
        extra: {
          error: isAuthenticationError(auth.error) ? auth.error.error_description : undefined,
        },
      });
    }
    if (!auth.isAuthenticated) {
      redirect({
        to: '/login',
        search: isAuthenticationError(auth.error) ? { aerr: auth.error.error_description } : undefined,
        throw: true,
      });
    }
  },
  component: AuthenticatedComponent,
});
