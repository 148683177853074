import { Avatar, IconButton, Menu, Tooltip } from '@mui/material';
import { useState, MouseEvent } from 'react';
import { AccountMenu } from './AccountMenu';
import { useAuth } from '../../../router/utils';

export const AccountButton = () => {
  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="User settings">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2, alignItems: 'center', display: 'flex' }}
          disableRipple={true}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar
            sx={{
              height: 40,
              width: 40,
            }}
            alt={user.name?.length === 0 ? 'Anonymous' : user.name}
            src={user.picture?.length === 0 ? '' : user.picture}
          />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              minWidth: 280,
              maxWidth: 380,
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(100, 116, 139, 0.32))',
              mt: 1,
              ml: 1,
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 26,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transitionDuration={0}
      >
        <AccountMenu />
      </Menu>
    </>
  );
};
