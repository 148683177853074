import { Box, Skeleton } from '@mui/material';
import { createRoute } from '@tanstack/react-router';
import { Route as AccountRoute } from './_account';
import { AuthenticatedDashboard } from '../components/Authenticated/AuthenticatedDashboard';
import { PageFrame } from '../components/Authenticated/PageFrame';
import { Backups } from '../components/Clusters/Backups';
import { ErrorBox } from '../components/Common/ErrorBox';
import { FeatureUnderMaintenance } from '../components/Common/FeatureUnderMaintenance';
import { useAuthenticatedData } from '../contexts/authenticated-data-context';
import { useFeatureFlags } from '../hooks/use-feature-flags';
import { useGetClustersByAccountIdQuery } from '../services/clustersApi';
import { Cluster } from '../utils/cluster-utils';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Backups',
    },
  ],
  getParentRoute: () => AccountRoute,
  path: 'backups',
  component: BackupsComponent,
});

const BackupsPageBody = ({
  clusters,
  refetchClusters,
  backupsHandlingEnabled,
  errorLoadingClusters,
  errorLoadingFeatureFlags,
  loadingClusters,
  loadingFeatureFlags,
}: {
  clusters?: Cluster[];
  refetchClusters: () => void;
  backupsHandlingEnabled?: boolean;
  errorLoadingClusters: boolean;
  errorLoadingFeatureFlags?: boolean;
  loadingClusters: boolean;
  loadingFeatureFlags?: boolean;
}) => {
  if (loadingFeatureFlags) {
    return <Skeleton variant="rectangular" height={240} />;
  }

  if (!backupsHandlingEnabled) {
    return <FeatureUnderMaintenance />;
  }

  if (errorLoadingClusters || errorLoadingFeatureFlags) {
    return <ErrorBox retry={errorLoadingClusters ? refetchClusters : undefined} />;
  }

  return <Backups availableClusters={clusters} loadingClusters={loadingClusters} sx={{ mt: 3 }} />;
};

function BackupsComponent() {
  const { account } = useAuthenticatedData();
  const accountId = account.id;
  const { backups_handling_enabled: backupsHandlingEnabled, isLoading, isError } = useFeatureFlags();
  const {
    data: clusters,
    isError: errorLoadingClusters,
    isLoading: loadingClusters,
    refetch,
  } = useGetClustersByAccountIdQuery({ accountId });

  return (
    <AuthenticatedDashboard>
      <PageFrame title="Backups">
        <Box sx={{ marginTop: 3 }}>
          <BackupsPageBody
            clusters={clusters}
            refetchClusters={refetch}
            loadingClusters={loadingClusters}
            errorLoadingClusters={errorLoadingClusters}
            backupsHandlingEnabled={backupsHandlingEnabled}
            loadingFeatureFlags={isLoading}
            errorLoadingFeatureFlags={isError}
          />
        </Box>
      </PageFrame>
    </AuthenticatedDashboard>
  );
}
