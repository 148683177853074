import { ReactNode, createContext, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';

/*
 * The cookiehub object is injected via our GTM script.
 * Any call to these methods will throw an error if the cookiehub script hasn't loaded.
 * It is recommended to use the state `isLoaded` to conditionally allow calling these methods
 * to avoid timing issues.
 */

/**
 * Facade API mostly for adding types on top of the cookiehub object.
 * @see https://docs.cookiehub.com/advanced/javascript-api#public-methods
 * @see https://docs.cookiehub.com/getting-started/cookie-categories
 */
const cookieHubApi = {
  hasConsented: (category: CookieHubCategory) => window.cookiehub.hasConsented(category),
  openDialog: () => window.cookiehub.openDialog(),
};

const CookieHubContext = createContext({ isLoaded: false, ...cookieHubApi });

export const CookieHubProvider = ({
  children,
}: {
  children: ({ allowedAnalytics }: { allowedAnalytics: boolean }) => ReactNode;
}) => {
  const [isLoaded, setIsLoaded] = useState(() => Boolean(window.cookiehub_gtm?.enabled));
  const [observer] = useState(() => {
    // It's possible that the script is already loaded when this hook is called, due to browser's internal cache.
    if (isLoaded) {
      return null;
    }
    // If the script is not loaded, we need to observe the <head>'s DOM to detect when it is added.
    const observer = new MutationObserver(function mutationCallback(mutationsList) {
      for (const mutation of mutationsList) {
        for (const node of mutation.addedNodes) {
          if (node.nodeName.toLowerCase() === 'script') {
            const scriptNode = node as HTMLScriptElement;
            if (scriptNode.src.includes('.cookiehub.')) {
              // Upon loading, the script will add a global `cookiehub_gtm` object to the window.
              scriptNode.addEventListener('load', function onCookieHubLoaded() {
                setIsLoaded(true);
                observer.disconnect();
              });
            }
          }
        }
      }
    });
    return observer;
  });

  useLayoutEffect(() => {
    observer?.observe(document.head, { childList: true });
    return () => observer?.disconnect();
  }, [observer]);

  const [allowedAnalytics, setAllowedAnalytics] = useState(false);

  useEffect(() => {
    if (isLoaded) {
      setAllowedAnalytics(cookieHubApi.hasConsented('analytics'));
    }
  }, [isLoaded]);

  useEffect(() => {
    function handleAnalyticsChoice() {
      setAllowedAnalytics(cookieHubApi.hasConsented('analytics'));
    }
    document.addEventListener('gtm_cookiehub_analytics_choice', handleAnalyticsChoice);
    return () => document.removeEventListener('gtm_cookiehub_analytics_choice', handleAnalyticsChoice);
  }, []);

  return (
    <CookieHubContext.Provider value={useMemo(() => ({ isLoaded, ...cookieHubApi }), [isLoaded])}>
      {children({ allowedAnalytics })}
    </CookieHubContext.Provider>
  );
};

export const useCookieHub = () => useContext(CookieHubContext);
