import { Box, Card, CardHeader, Skeleton } from '@mui/material';
import { MouseEvent, useMemo } from 'react';
import { MetricsTimeFrameToggleGroup } from './MetricsTimeFrameToggleGroup';
import { RequestsMetricsChart } from './RequestsMetricsChart';
import { Metric, Metrics, TIME_FRAMES } from './utils';
import { useGetClusterMetricsByClusterIdQuery } from '../../../services/clustersApi';
import { ErrorBox } from '../../Common/ErrorBox';

type RequestMetricsProps = {
  clusterId: string;
  accountId: string;
  timeFrameIndex: number;
  onTimeFrameChange: (event: MouseEvent<HTMLElement>, value: number) => void;
};

const BOX_STYLE = { padding: 3 };

export function RequestMetrics({ clusterId, accountId, timeFrameIndex, onTimeFrameChange }: RequestMetricsProps) {
  const xAxisRange = useMemo(() => {
    // Define unix timestamp of now
    const now = Math.trunc(Date.now() / 1000);
    const timeFrameValue = TIME_FRAMES[timeFrameIndex].value;
    return {
      until: now,
      // `since` is defined based on the selected time frame from `now`
      since: now - timeFrameValue,
    };
  }, [timeFrameIndex]);

  const { data, isLoading, isFetching, refetch, isError } = useGetClusterMetricsByClusterIdQuery({
    clusterId,
    accountId,
    queryType: 'time_series',
    since: xAxisRange.since,
    until: xAxisRange.until,
  });
  const metrics = data as Metrics | undefined;

  const getBody = () => {
    if (isLoading) {
      return (
        <Card>
          <Skeleton variant="rounded" height={240} />
        </Card>
      );
    }

    if (isError) {
      return (
        <Card>
          <ErrorBox retry={refetch} />
        </Card>
      );
    }

    const hasRequestsData = metrics?.[Metric.RPS] && metrics[Metric.RPS].length > 0;
    if (!hasRequestsData) {
      return <Box sx={BOX_STYLE}>No request metrics available</Box>;
    }

    return (
      <RequestsMetricsChart
        metrics={metrics}
        isLoading={isFetching}
        xAxisRange={xAxisRange}
        timeFrame={TIME_FRAMES[timeFrameIndex].value}
      />
    );
  };

  return (
    <Box>
      <Card sx={{ marginBottom: 1 }}>
        <CardHeader
          title="Requests"
          action={
            <MetricsTimeFrameToggleGroup
              isFetching={isFetching}
              timeFrameIndex={timeFrameIndex}
              onTimeFrameChange={onTimeFrameChange}
            />
          }
        />
      </Card>
      {getBody()}
    </Box>
  );
}
