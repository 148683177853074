import InfoIcon from '@mui/icons-material/InfoOutlined';
import WarningIcon from '@mui/icons-material/WarningOutlined';
import { Avatar, Box, Button, Checkbox, CircularProgress, Dialog, FormControlLabel, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { ReactNode, useCallback, useState } from 'react';

type ConfirmDialogProps = {
  open?: boolean;
  onClose: () => void;
  title: string;
  content: ReactNode;
  warning?: string;
  severity?: 'warning' | 'info' | 'error';
  actionName: string;
  actionHandler: (confirm?: boolean) => Promise<void> | void;
  extraConfirmation?: string;
  extraConfirmationRequired?: boolean;
  defaultExtraConfirmation?: boolean;
};

export const ConfirmationDialog = ({
  open = false,
  onClose,
  title,
  content,
  warning,
  severity = 'error',
  actionName,
  actionHandler,
  extraConfirmation,
  extraConfirmationRequired,
  defaultExtraConfirmation = false,
}: ConfirmDialogProps) => {
  const [loadingAction, setLoadingAction] = useState(false);
  const [extraConfirmationChecked, setExtraConfirmationChecked] = useState(defaultExtraConfirmation);
  const handleActionClick = useCallback(async () => {
    setLoadingAction(true);
    await actionHandler(extraConfirmationChecked);
    onClose();
    setLoadingAction(false);
  }, [actionHandler, onClose, extraConfirmationChecked]);

  const handleClose = useCallback(() => {
    if (!loadingAction) {
      onClose();
    }
  }, [onClose, loadingAction]);

  return (
    <Dialog open={open} fullWidth={true} role="alertdialog" aria-labelledby="confirmation-dialog-title">
      <Box display="flex" padding={3}>
        {warning && (
          <Avatar
            sx={{
              backgroundColor: (theme) => alpha(theme.palette[severity].main, 0.08),
              color: `${severity}.main`,
              mr: 2,
            }}
          >
            {severity === 'info' ? <InfoIcon fontSize="small" /> : <WarningIcon fontSize="small" />}
          </Avatar>
        )}
        <div>
          <Typography variant="h5" id="confirmation-dialog-title">
            {title}
          </Typography>
          <Typography color="textSecondary" sx={{ mt: 1, mb: 2 }} variant="body1" component="div">
            {content}
          </Typography>
          {extraConfirmation && (
            <FormControlLabel
              label={
                <Typography color="textSecondary" variant="body2">
                  {extraConfirmation}
                </Typography>
              }
              control={
                <Checkbox
                  onChange={(event) => setExtraConfirmationChecked(event.target.checked)}
                  checked={extraConfirmationChecked}
                  size="small"
                />
              }
            />
          )}
        </div>
      </Box>
      {warning && (
        <Box
          sx={{
            display: 'flex',
            backgroundColor: (theme) => alpha(theme.palette[severity].main, 0.08),
            py: 2,
            px: 3,
            mb: 2,
          }}
        >
          <Typography color={`${severity}.main`} variant="body2">
            {warning}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          px: 3,
          py: 1.5,
          gap: 2,
        }}
      >
        <Button sx={{ mr: 2 }} variant="outlined" onClick={handleClose} disabled={loadingAction}>
          Cancel
        </Button>
        <Button
          color={severity}
          variant="contained"
          onClick={handleActionClick}
          disabled={loadingAction || (extraConfirmationRequired && !extraConfirmationChecked)}
          endIcon={loadingAction && <CircularProgress size={14} />}
        >
          {actionName}
        </Button>
      </Box>
    </Dialog>
  );
};

// propType validation
ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  warning: PropTypes.string,
  actionName: PropTypes.string.isRequired,
  actionHandler: PropTypes.func.isRequired,
};
