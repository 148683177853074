import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Typography, CircularProgress, Tooltip, Link } from '@mui/material';
import { useNavigate } from '@tanstack/react-router';
import { useAccountId } from '../../contexts/authenticated-data-context';
import { PrivateRegion } from '../../utils/cluster-utils';

/**
 * Renders the Environment name (be it hybrid or managed cloud) based on certain conditions.
 */
export const EnvironmentName = ({
  isHybridCloudCluster,
  regionName,
  hybridCloudEnv,
  linkToEnv = true,
  isLoadingHybridCloudEnv,
  isError,
}: {
  isHybridCloudCluster: boolean;
  isLoadingHybridCloudEnv: boolean;
  isError: boolean;
  hybridCloudEnv?: PrivateRegion;
  linkToEnv?: boolean;
  regionName: string;
}) => {
  const accountId = useAccountId();
  const navigate = useNavigate();
  if (!isHybridCloudCluster || isError) {
    return <Typography variant="body2">{regionName}</Typography>;
  }

  if (isLoadingHybridCloudEnv) {
    return <CircularProgress size={20} />;
  }

  if (hybridCloudEnv) {
    if (!linkToEnv) {
      return <Typography variant="body2">{hybridCloudEnv.name}</Typography>;
    }

    return (
      <Tooltip title={'See environment details'} placement={'top'} arrow={true}>
        <Link
          display="block"
          component="button"
          variant="body2"
          onClick={() =>
            navigate({
              to: '/accounts/$accountId/hybrid-cloud-environments/$hybridCloudEnvId',
              params: { accountId, hybridCloudEnvId: hybridCloudEnv.id },
            })
          }
          color="text.primary"
          underline="hover"
          textAlign="left"
          id={`hybrid-cloud-env-${hybridCloudEnv.id}`}
        >
          <span>{hybridCloudEnv.name}</span>
          <span style={{ position: 'relative', top: '3px', left: '4px' }}>
            <OpenInNewIcon sx={{ fontSize: 14 }} />
          </span>
        </Link>
      </Tooltip>
    );
  }

  return <Typography variant="body2">{regionName}</Typography>;
};
