import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { Box, Card, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  CHART_CARD_HEIGHT,
  CHART_HEIGHT,
  METRICS_DETAILS,
  Metric,
  RESOURCE_CHART_COLOR,
  getChartOptions,
} from './utils';

const CHART_BOX_STYLE = { padding: 1 };

function NodeMetricsWithTimeSlices({ node, maxResources, timeFrame, xAxisRange, isLoading }) {
  const theme = useTheme();

  const cpuRamSeries = useMemo(
    () => [
      {
        name: METRICS_DETAILS[Metric.CPU].label,
        data: node?.[[Metric.CPU]] || [],
      },
      {
        name: METRICS_DETAILS[Metric.RAM].label,
        data: node?.[Metric.RAM] || [],
      },
      {
        name: METRICS_DETAILS[Metric.RSS].label,
        data: (node?.[Metric.RSS] || []).map(([timestamp, value]) => [timestamp, value]),
      },
    ],
    [node],
  );

  const diskSeries = useMemo(
    () => [
      {
        name: METRICS_DETAILS[Metric.DISK].label,
        data: node?.[Metric.DISK] || [],
      },
    ],
    [node],
  );

  const getChartOptsShort = useCallback(
    (metrics) => getChartOptions({ metrics, timeFrame, xAxisRange, theme }),
    [timeFrame, xAxisRange, theme],
  );

  const cpuRamChartOpts = useMemo(
    () =>
      getChartOptsShort([
        { name: Metric.CPU, color: RESOURCE_CHART_COLOR[Metric.CPU], max: maxResources[Metric.CPU] },
        // We use the RAM's max for both RAM (ram with cache) and RSS (without cache)
        { name: Metric.RAM, color: RESOURCE_CHART_COLOR[Metric.RAM], max: maxResources[Metric.RAM] },
        { name: Metric.RSS, color: RESOURCE_CHART_COLOR[Metric.RSS], max: maxResources[Metric.RAM], hideLeftBar: true },
      ]),
    [getChartOptsShort, maxResources],
  );

  const diskChartOpts = useMemo(() => {
    const max = maxResources[Metric.DISK];
    return getChartOptsShort([
      { name: Metric.DISK, color: RESOURCE_CHART_COLOR[Metric.DISK], max },
      { name: Metric.DISK, color: RESOURCE_CHART_COLOR[Metric.DISK], max },
    ]);
  }, [getChartOptsShort, maxResources]);

  const charts = [
    { options: cpuRamChartOpts, series: cpuRamSeries },
    { options: diskChartOpts, series: diskSeries },
  ];

  return (
    <Box key={node?.node_id}>
      {charts.map(({ options, series }, key) => (
        <Card sx={{ marginBottom: 2, minHeight: CHART_CARD_HEIGHT }} key={key}>
          {isLoading ? (
            <Skeleton variant="rectangular" height={CHART_CARD_HEIGHT} />
          ) : (
            <Box sx={CHART_BOX_STYLE} overflow="hidden">
              <Chart options={options} series={series} height={CHART_HEIGHT} />
            </Box>
          )}
        </Card>
      ))}
    </Box>
  );
}

// PropTypes definition
NodeMetricsWithTimeSlices.propTypes = {
  node: PropTypes.shape({
    node_id: PropTypes.string.isRequired,
    cpu: PropTypes.array.isRequired,
    ram: PropTypes.array.isRequired,
    disk: PropTypes.array.isRequired,
  }).isRequired,
  maxResources: PropTypes.shape({
    cpu: PropTypes.number,
    ram: PropTypes.number,
    disk: PropTypes.number,
  }).isRequired,
  timeFrame: PropTypes.number.isRequired,
  xAxisRange: PropTypes.shape({
    since: PropTypes.number.isRequired,
    until: PropTypes.number.isRequired,
  }),
  isLoading: PropTypes.bool.isRequired,
};

export default NodeMetricsWithTimeSlices;
