import { createRoute, Navigate } from '@tanstack/react-router';
import { Route as AuthenticatedRoute } from './_authenticated';
import { useAccountId } from '../contexts/authenticated-data-context';

export const Route = createRoute({
  getParentRoute: () => AuthenticatedRoute,
  path: 'overview',
  component: OverviewComponent,
});

function OverviewComponent() {
  return <Navigate to="/accounts/$accountId/overview" params={{ accountId: useAccountId() }} replace={true} />;
}
