import { Close } from '@mui/icons-material';
import { Box, Button, IconButton } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useSnackbar } from './use-qdrant-snackbar';
import { useSettings } from './use-settings';
import {
  useGetUserNotificationPreferencesQuery,
  useChangeUserNotificationPreferencesMutation,
} from '../services/iamApi';

/**
 * Used to display prompts on the application level for signed in users.
 */
export const useSignInPrompts = (userId: string) => {
  const { settings, saveSettings } = useSettings();
  const newsletterPrompt = settings.dashboardPromps.newsletter;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const preferences = useGetUserNotificationPreferencesQuery(
    { userId },
    { skip: !newsletterPrompt }, // skip if the user has already dismissed the prompt
  );
  const [changeUserNotificationPreferences] = useChangeUserNotificationPreferencesMutation();

  const snackbarKey = useRef<number>();
  // Attempt to show the user the newsletter prompt
  useEffect(() => {
    if (preferences.data) {
      if (preferences.data.newsletter?.is_enabled && snackbarKey.current) {
        // Show a success message if the user has successfully subscribed to the newsletter
        saveSettings({ dashboardPromps: { newsletter: false } });
        closeSnackbar(snackbarKey.current);
        snackbarKey.current = undefined;
        enqueueSnackbar('You have successfully subscribed to our newsletter!', {
          variant: 'success',
          anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
          autoHideDuration: 3000,
          action: (key) => (
            <IconButton
              color="inherit"
              onClick={() => {
                closeSnackbar(key);
              }}
            >
              <Close />
            </IconButton>
          ),
        });
        return;
      }
      // Has ever been set by the user before? We only want to bother users once.
      const newsletterPreferenceNotSet = preferences.data.newsletter?.is_enabled === undefined;
      if (!newsletterPreferenceNotSet || snackbarKey.current) {
        return;
      }
      const key = Date.now();
      snackbarKey.current = key;
      // Newsletter preference has not been set, so we can show the prompt...
      enqueueSnackbar('Subscribe to our newsletter:', {
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
        autoHideDuration: 1e10,
        key,
        action: () => (
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
              variant="text"
              color="inherit"
              size="small"
              onClick={() => {
                closeSnackbar(key);
                saveSettings({ dashboardPromps: { newsletter: false } });
              }}
            >
              No, thanks.
            </Button>
            <Button
              variant="text"
              color="inherit"
              size="small"
              onClick={() => {
                closeSnackbar(key);
                void changeUserNotificationPreferences({ userId, newsletter: true });
              }}
            >
              Yes, please!
            </Button>
          </Box>
        ),
      });
    }
  }, [saveSettings, enqueueSnackbar, closeSnackbar, changeUserNotificationPreferences, preferences.data, userId]);
};
