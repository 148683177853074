import {
  Box,
  Card,
  CardHeader,
  Skeleton,
  Typography,
  // eslint-disable-next-line no-restricted-imports
  useTheme,
} from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useAuthenticatedData } from '../../contexts/authenticated-data-context';
import { useConfirmationAction } from '../../hooks/use-confirmation-action';
import { useSnackbar } from '../../hooks/use-qdrant-snackbar';
import { isFetchMutationError } from '../../services/helpers';
import {
  AccountUser,
  UserStatus,
  useGetUsersByAccountIdQuery,
  useRemoveUserFromAccountMutation,
} from '../../services/iamApi';
import { getUserRoles } from '../../utils/user';
import { CardTitleWithLoadingIndicator } from '../Common/CardTitleWithLoadingIndicator';
import { ConfirmationDialog } from '../Common/ConfirmationDialog';
import { ErrorBox } from '../Common/ErrorBox';
import { QdrantTable, RowType } from '../Common/QdrantTable';
import { Scrollbar } from '../Common/Scrollbar';
import { SeverityPill } from '../Common/SeverityPill';

const USER_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

const USERS_COLUMNS = [
  { title: 'Email', dataKey: 'email' },
  { title: 'Status', dataKey: 'status' },
  { title: 'Roles', dataKey: 'roles' },
];

const FEEDBACK_MSG_CONTAINER_STYLE = {
  pt: 1,
  pl: 2,
  pb: 2,
};

/**
 * From an invite status it returns the Pill color.
 * @returns {'success' | 'primary' | 'warning' | 'error'}
 */
const getStatusColor = (status?: UserStatus | null) => {
  switch (status) {
    case USER_STATUS.ACTIVE:
      return 'success';
    case USER_STATUS.INACTIVE:
    default:
      return 'inactive';
  }
};

export const Users = () => {
  const { account } = useAuthenticatedData();
  const accountId = account.id;
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const { confirmAction, showConfirmation, hideConfirmation } = useConfirmationAction();

  const {
    data: users,
    isLoading: loadingUsers,
    isFetching: fetchingUsers,
    isError: errorLoadingUsers,
    refetch: refetchUsers,
  } = useGetUsersByAccountIdQuery({ accountId });
  const [removeUser, { isLoading: removingUser }] = useRemoveUserFromAccountMutation();

  const handleRemoveUser = useCallback(
    async ({ id: userId, email }: AccountUser) => {
      // Stop when a request is in process
      if (removingUser) {
        return;
      }

      const result = await removeUser({ accountId, userId });
      if (isFetchMutationError(result)) {
        enqueueSnackbar('There was a problem removing the user. Try again later.', { variant: 'error' });
      } else {
        enqueueSnackbar(`${email!} was removed from the account`, { variant: 'success' });
      }
    },
    [removeUser, removingUser, accountId, enqueueSnackbar],
  );

  const usersRows: RowType<AccountUser>[] = useMemo(() => {
    if (!users) {
      return [];
    }
    const rows = Array.from(users);
    return rows
      .sort((a, b) => a.email?.localeCompare(b.email ?? '') ?? 0)
      .map((user) => {
        const { id, email, status, roles, is_owner: isAccountOwner } = user;
        return {
          id,
          email,
          status: <SeverityPill color={getStatusColor(status)}>{status}</SeverityPill>,
          roles: getUserRoles({ isAccountOwner, theme, roles }),
          rowData: user,
        };
      });
  }, [users, theme]);

  const userRowActions = useMemo(
    () => [
      {
        name: 'Remove user',
        getHandler: (data: AccountUser) => () =>
          showConfirmation({
            actionName: 'Remove',
            title: 'Remove user',
            content: 'By removing the user you are revoking its access to the account.',
            actionHandler: () => handleRemoveUser(data),
            withConfirm: false,
          }),
        isDisabled: ({ is_owner: isOwner }: AccountUser) => removingUser || Boolean(isOwner),
      },
    ],
    [handleRemoveUser, removingUser, showConfirmation],
  );

  const getUsersBody = () => {
    if (loadingUsers) {
      return <Skeleton variant="rectangular" height={240} />;
    }

    if (errorLoadingUsers) {
      return (
        <Box sx={{ ...FEEDBACK_MSG_CONTAINER_STYLE }}>
          <ErrorBox retry={refetchUsers} />
        </Box>
      );
    }

    if (!usersRows.length) {
      return (
        <Box sx={{ ...FEEDBACK_MSG_CONTAINER_STYLE }}>
          <Typography sx={{ mb: 1 }}>You haven't sent any invites yet.</Typography>
        </Box>
      );
    }

    return (
      <Box data-sentry-mask={true}>
        <QdrantTable<AccountUser>
          columns={USERS_COLUMNS}
          rows={usersRows}
          rowActions={userRowActions}
          pagination={true}
          ariaLabel="Users"
        />
      </Box>
    );
  };

  return (
    <Box>
      <Card sx={{ marginTop: 2 }} title={`Users`}>
        <CardHeader
          title={<CardTitleWithLoadingIndicator title={'Users'} isLoading={!loadingUsers && fetchingUsers} />}
        />
        <Scrollbar>{getUsersBody()}</Scrollbar>
      </Card>
      {confirmAction && <ConfirmationDialog open={true} onClose={hideConfirmation} {...confirmAction} />}
    </Box>
  );
};
