import { Box } from '@mui/material';
import { createRoute } from '@tanstack/react-router';
import { Route as AccountRoute } from './_account';
import { Invites } from '../components/Access/Invites';
import { ProgrammaticAccessKeys } from '../components/Access/ProgrammaticAccessKeys';
import { Users } from '../components/Access/Users';
import { AuthenticatedDashboard } from '../components/Authenticated/AuthenticatedDashboard';
import { PageFrame } from '../components/Authenticated/PageFrame';
import { useAuthenticatedData } from '../contexts/authenticated-data-context';
import { accountHasProgrammaticAccessPrivilege } from '../utils/account-utils';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Cloud UI Access',
    },
  ],
  getParentRoute: () => AccountRoute,
  path: 'cloud-access',
  component: DataAccessComponent,
});

function DataAccessComponent() {
  const { account } = useAuthenticatedData();
  const hasProgrammaticAccessPrivilege = accountHasProgrammaticAccessPrivilege(account);

  return (
    <AuthenticatedDashboard>
      <PageFrame title="Cloud UI Access">
        <Box sx={{ marginTop: 3 }}>
          <Invites sx={{ mb: 3, mt: 3 }} />
          <Users />
          {hasProgrammaticAccessPrivilege && <ProgrammaticAccessKeys />}
        </Box>
      </PageFrame>
    </AuthenticatedDashboard>
  );
}
