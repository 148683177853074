import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';
import { OperationByName, OperationSuccessResponse } from '../utils/api-schema-utils';

type GetFeatureFlags = OperationByName<'get_feature_flags_config_feature_flags_get'>;
export type FeatureFlags = OperationSuccessResponse<GetFeatureFlags>;

const BOOKING_API_BASE = '/api/v1/config';

export const configApi = createApi({
  reducerPath: 'configApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BOOKING_API_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['FeatureFlags'],
  endpoints: (builder) => ({
    // Reason: this should be allowed, we couldn't get the eslint rule to work, needs further investigation.
    // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
    getFeatureFlags: builder.query<FeatureFlags, void>({
      query: () => ({
        url: `feature_flags`,
      }),
      providesTags: ['FeatureFlags'],
    }),
  }),
});

export const { useGetFeatureFlagsQuery } = configApi;
export const selectFeatureFlags = configApi.endpoints.getFeatureFlags.select();
