import { ComponentProps, ComponentType, memo } from 'react';

export function attempt<T, U = undefined>(fn: () => T, fallback?: (err: unknown) => U): T | U {
  try {
    return fn();
  } catch (err) {
    if (fallback) {
      return fallback(err);
    }
  }
  return undefined as U;
}

export const objectHasOwn =
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  (Object.hasOwn ??
    function hasOwn(o: object, v: PropertyKey) {
      return Object.prototype.hasOwnProperty.call(o, v);
      // eslint-disable-next-line @typescript-eslint/no-wrapper-object-types
    }) as <T extends Object>(o: T, v: keyof T) => boolean;

type PropsComparator<C extends ComponentType> = (
  prevProps: Readonly<ComponentProps<C>>,
  nextProps: Readonly<ComponentProps<C>>,
) => boolean;

/* eslint-disable @typescript-eslint/no-explicit-any */
export function typedMemo<C extends ComponentType<any>>(Component: C, propsComparator?: PropsComparator<C>) {
  return memo(Component, propsComparator) as any as C;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export type ObjectEntries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];
