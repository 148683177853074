import { Box } from '@mui/material';
import { createRoute } from '@tanstack/react-router';
import { Route as AccountRoute } from './_account';
import { ClusterAccess } from '../components/Access/ClusterAccess';
import { AuthenticatedDashboard } from '../components/Authenticated/AuthenticatedDashboard';
import { PageFrame } from '../components/Authenticated/PageFrame';

export const Route = createRoute({
  meta: () => [
    {
      title: 'Data Access Control',
    },
  ],
  getParentRoute: () => AccountRoute,
  path: 'data-access',
  component: DataAccessComponent,
});

function DataAccessComponent() {
  return (
    <AuthenticatedDashboard>
      <PageFrame title="Data Access Control">
        <Box sx={{ marginTop: 3 }}>
          <ClusterAccess sx={{ mb: 3 }} />
        </Box>
      </PageFrame>
    </AuthenticatedDashboard>
  );
}
