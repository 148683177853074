import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Grid, Link, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useAccountId } from '../../../../../contexts/authenticated-data-context';
import { useTrackInteractionEvent } from '../../../../../hooks/use-event-tracking';
import { useSnackbar } from '../../../../../hooks/use-qdrant-snackbar';
import { useCreateApiKeyMutation } from '../../../../../services/iamApi';
import { getUserMessage } from '../../../../../utils/errorHandlingUtils';
import { useWelcomeStepperContext } from '../WelcomeStepperProvider';

interface IGenerateApiKeyStepProps {
  onNext: () => void;
}

const ctaLabel = 'Generate API Key';

export const GenerateApiKeyStep = ({ onNext }: IGenerateApiKeyStepProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const accountId = useAccountId();
  const { newCluster, setApiKey } = useWelcomeStepperContext();
  const [createApiKey, { isLoading: isGeneratingKey }] = useCreateApiKeyMutation();

  const handleGetApiKey = useCallback(async () => {
    if (newCluster) {
      try {
        const { token } = await createApiKey({
          accountId,
          clusterIDs: [newCluster.id],
        }).unwrap();
        setApiKey(token);
        onNext();
      } catch (err) {
        enqueueSnackbar(getUserMessage(err), { variant: 'error' } as const);
      }
    }
  }, [accountId, createApiKey, newCluster, onNext, setApiKey, enqueueSnackbar]);

  const trackGetAPIKeyClick = useTrackInteractionEvent({
    label: ctaLabel,
    location: `${ctaLabel} Card (Horizontal)`,
    action: 'clicked',
  });

  return (
    <Box sx={{ mb: 6 }}>
      <Grid
        container={true}
        sx={{
          flexDirection: 'column',
          margin: '0 auto',
        }}
      >
        <Grid item={true}>
          <Card
            sx={{
              px: 5,
              py: 4,
            }}
          >
            {newCluster && (
              <CardHeader
                sx={{
                  px: 0,
                  pt: 3,
                  pb: 2,
                  '& span': { fontSize: '1.25em' },
                }}
                title={`Generate an API Key for ${newCluster.name}:`}
              />
            )}

            <CardContent sx={{ px: 0, pt: 0, mt: 0 }}>
              <Typography component={'p'} mb={1}>
                Create an API key in order to access the cluster from within your application.
              </Typography>
              <Box sx={{ my: 3, display: 'flex', gap: 2 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={async () => {
                    trackGetAPIKeyClick();
                    await handleGetApiKey();
                  }}
                  sx={{
                    ...(!newCluster && {
                      pointerEvents: 'none',
                      opacity: 0.2,
                    }),
                    ...(isGeneratingKey && {
                      backgroundColor: 'inProgress.main',
                      color: 'inProgress.contrastText',
                    }),
                  }}
                >
                  {!isGeneratingKey ? (
                    ctaLabel
                  ) : (
                    <>
                      <Typography variant="inherit" sx={{ mr: 2 }}>
                        Generating
                      </Typography>
                      <CircularProgress size={18} color="inherit" />
                    </>
                  )}
                </Button>
              </Box>
              <Typography component={'p'} mb={1}>
                Read more about access in{' '}
                <Link href="/" target="_blank">
                  our documentation
                </Link>
              </Typography>
              <Typography component={'p'} mb={1}>
                You can manage all your API keys in the{' '}
                <Link href="/" target="_blank">
                  Access section
                </Link>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
