import { Outlet, createRoute, useNavigate } from '@tanstack/react-router';
import { useEffect } from 'react';
import { Route as ClustersRoute } from './_clusters';
import { useAccountId } from '../contexts/authenticated-data-context';
import { useGetClusterByIdQuery } from '../services/clustersApi';
import { isFetchApiError, parseFetchError } from '../services/helpers';

export const Route = createRoute({
  getParentRoute: () => ClustersRoute,
  path: '$clusterId',
  component: ClusterComponent,
});

function ClusterComponent() {
  const accountId = useAccountId();
  const { clusterId } = Route.useParams();
  const { error: clusterLoadingError } = useGetClusterByIdQuery({ clusterId, accountId });
  const navigate = useNavigate();

  useEffect(() => {
    if (!clusterLoadingError) {
      return;
    }
    const error = parseFetchError(clusterLoadingError);
    if (isFetchApiError(error) && error.originalStatus === 403) {
      void navigate({ to: '/accounts/$accountId/clusters', params: { accountId }, replace: true });
    }
  }, [clusterLoadingError, navigate, accountId]);

  return <Outlet />;
}
