import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardHeader, Skeleton } from '@mui/material';
import { useGetClusterMetricsByClusterIdQuery } from '../../../services/clustersApi';
import { getResourceTotal, transformGigabytesToBytes } from '../helpers';
import Slider from '../../Common/Slider';
import NodeMetricsWithTimeSlices from './NodeMetricsWithTimeSlices';
import { Metric, TIME_FRAMES } from './utils';
import { ErrorBox } from '../../Common/ErrorBox';
import { MetricsTimeFrameToggleGroup } from './MetricsTimeFrameToggleGroup';

/**
 * Obtains the maximum possible number for the metrics, based on the cluster config.
 * @param {{ resource_option: { name: string; }; amount: number }} resourceConfiguration
 * @returns {{ cpu?: number; disk?: number; ram?: number }} - the converted maxs number if exists.
 */
const getMaxResources = (resources) => {
  const maxCPU = getResourceTotal(resources[Metric.CPU]);
  const maxRAM = getResourceTotal(resources[Metric.RAM]);
  const maxDisk = getResourceTotal(resources[Metric.DISK]);

  return {
    [Metric.CPU]: maxCPU ? maxCPU / 1000 : maxCPU,
    [Metric.DISK]: maxDisk ? transformGigabytesToBytes(maxDisk) : maxDisk,
    [Metric.RAM]: maxRAM ? transformGigabytesToBytes(maxRAM) : maxRAM,
  };
};

const BOX_STYLE = { padding: 3 };

export function ClusterMetrics({ clusterId, accountId, resources, timeFrameIndex, onTimeFrameChange }) {
  const xAxisRange = useMemo(() => {
    // Define unix timestamp of now
    const now = Math.trunc(Date.now() / 1000);
    const timeFrameValue = TIME_FRAMES[timeFrameIndex].value;
    return {
      until: now,
      // `since` is defined based on the selected time frame from `now`
      since: now - timeFrameValue,
    };
  }, [timeFrameIndex]);

  const { data, isLoading, isFetching, refetch, isError } = useGetClusterMetricsByClusterIdQuery({
    clusterId,
    accountId,
    queryType: 'time_series',
    since: xAxisRange.since,
    until: xAxisRange.until,
  });

  const maxResources = useMemo(() => getMaxResources(resources), [resources]);

  const slides = data?.nodes?.map((node) => (
    <NodeMetricsWithTimeSlices
      node={node}
      key={node.node_id}
      isLoading={isFetching}
      maxResources={maxResources}
      xAxisRange={xAxisRange}
      timeFrame={TIME_FRAMES[timeFrameIndex].value}
    />
  ));

  const getBody = () => {
    if (isLoading) {
      return (
        <Card>
          <Skeleton variant="rounded" height={240} />
        </Card>
      );
    }

    if (isError) {
      return (
        <Card>
          <ErrorBox retry={refetch} />
        </Card>
      );
    }

    if (!slides?.length) {
      return <Box sx={BOX_STYLE}>No metrics available</Box>;
    }

    return <Slider slides={slides} />;
  };

  return (
    <Box>
      <Card sx={{ marginBottom: 1 }}>
        <CardHeader
          title="Metrics"
          action={
            <MetricsTimeFrameToggleGroup
              isFetching={isFetching}
              timeFrameIndex={timeFrameIndex}
              onTimeFrameChange={onTimeFrameChange}
            />
          }
        />
      </Card>
      {getBody({ isError, isLoading, slides, refetch, rpsData: data?.rps })}
    </Box>
  );
}

// PropTypes definition
ClusterMetrics.propTypes = {
  clusterId: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
  resources: PropTypes.object.isRequired,
  timeFrameIndex: PropTypes.number.isRequired,
  onTimeFrameChange: PropTypes.func.isRequired,
};
