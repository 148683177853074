import { CloudProvider } from '../../../services/clustersApi';
import { PaymentInformation, useGetPaymentInformationQuery } from '../../../services/paymentApi';
import { Cluster } from '../../../utils/cluster-utils';
import { isClusterFreeTier } from '../../../utils/cluster-utils';

type PaymentType = PaymentInformation['type_'];

function isPaymentTypeMarketplace(paymentType: NonNullable<PaymentType>, cloudProvider?: CloudProvider) {
  return paymentType.startsWith(`suger_${cloudProvider ?? ''}`);
}

/**
 * For the time being, we don't want to allow paid clusters on a non-matching Marketplace payment method.
 * When we introduce multiple payment methods, we will need to revisit this.
 * TODO: this implementation is a hack to disable free tier scaling. It should be refactored.
 * The solution is this ticket: https://github.com/qdrant/cloud-pm/issues/551
 *
 * @param accountId - the account id to check the payment information for
 * @param cluster - the current cluster to check if scaling is allowed
 * @param freeTierCloudProvider - The cloud provider that the free tier is allowed on
 */
export const useClusterScaleConsent = ({
  accountId,
  cluster,
  freeTierCloudProvider,
}: {
  accountId: string;
  cluster: Cluster;
  freeTierCloudProvider: CloudProvider;
}) => {
  const { data } = useGetPaymentInformationQuery({ accountId });
  const paymentType = data?.type_;
  if (!paymentType) {
    return 'indeterminate'; // ... means it's loading or errored, so we don't know if it's allowed or not yet.
  }
  if (!isClusterFreeTier(cluster) || !isPaymentTypeMarketplace(paymentType)) {
    // always allow for paid clusters or non-marketplace payment methods
    return 'allowed';
  }
  if (
    cluster.cloud_provider === freeTierCloudProvider &&
    isPaymentTypeMarketplace(paymentType, cluster.cloud_provider)
  ) {
    // matching cloud provider and marketplace payment method
    return 'allowed';
  }
  return 'disallowed';
};
