import { Box, Grid, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Table from '@mui/material/Table';
// eslint-disable-next-line no-restricted-imports
import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import { NodeUsageSummaryChart } from './NodeUsageSummaryChart';
import { Metric, RAM_LABEL, RESOURCE_CHART_COLOR } from './utils';
import { Cluster, getNodeNumber } from '../../../utils/cluster-utils';
import { CopyToClipboardText } from '../../Common/CopyToClipboardText';
import { formatMetricAmount } from '../helpers';

type NodeMetrics = { node_id: string } & Partial<
  Record<
    Metric.RAM | Metric.RSS | Metric.CPU | Metric.DISK,
    {
      avg: { current: string };
      total: { value: string };
    }
  >
>;

function getGraphDataFromNode(nodeMetrics: NodeMetrics) {
  const series = [];
  if (nodeMetrics[Metric.RAM]) {
    series.push({
      color: RESOURCE_CHART_COLOR[Metric.RAM],
      data: (parseFloat(nodeMetrics[Metric.RAM].avg.current) / parseFloat(nodeMetrics[Metric.RAM].total.value)) * 100,
      formattedValue: formatMetricAmount(Metric.RAM, parseFloat(nodeMetrics[Metric.RAM].avg.current)),
      total: formatMetricAmount(Metric.RAM, parseFloat(nodeMetrics[Metric.RAM].total.value)),
      label: RAM_LABEL,
    });
  }
  if (nodeMetrics[Metric.RSS]) {
    series.push({
      color: RESOURCE_CHART_COLOR[Metric.RSS],
      data: (parseFloat(nodeMetrics[Metric.RSS].avg.current) / parseFloat(nodeMetrics[Metric.RSS].total.value)) * 100,
      formattedValue: formatMetricAmount(Metric.RSS, parseFloat(nodeMetrics[Metric.RSS].avg.current)),
      total: formatMetricAmount(Metric.RSS, parseFloat(nodeMetrics[Metric.RSS].total.value)),
      label: 'RAM',
    });
  }
  if (nodeMetrics[Metric.CPU]) {
    series.push({
      color: RESOURCE_CHART_COLOR[Metric.CPU],
      data: (parseFloat(nodeMetrics[Metric.CPU].avg.current) / parseFloat(nodeMetrics[Metric.CPU].total.value)) * 100,
      formattedValue: formatMetricAmount(Metric.CPU, parseFloat(nodeMetrics[Metric.CPU].avg.current)),
      total: formatMetricAmount(Metric.CPU, parseFloat(nodeMetrics[Metric.CPU].total.value)),
      label: 'CPU',
    });
  }
  if (nodeMetrics[Metric.DISK]) {
    series.push({
      color: RESOURCE_CHART_COLOR[Metric.DISK],
      data: (parseFloat(nodeMetrics[Metric.DISK].avg.current) / parseFloat(nodeMetrics[Metric.DISK].total.value)) * 100,
      formattedValue: formatMetricAmount(Metric.DISK, parseFloat(nodeMetrics[Metric.DISK].avg.current)),
      total: formatMetricAmount(Metric.DISK, parseFloat(nodeMetrics[Metric.DISK].total.value)),
      label: 'Disk',
    });
  }
  return { series };
}

export const NodeSummary = ({ cluster, nodeMetrics }: { cluster: Cluster; nodeMetrics: NodeMetrics }) => {
  const theme = useTheme();
  const data = useMemo(() => getGraphDataFromNode(nodeMetrics), [nodeMetrics]);

  const nodeNumber = getNodeNumber(cluster.id, nodeMetrics.node_id);
  const nodeUrl = cluster.state?.nodes?.[nodeMetrics.node_id]?.endpoint ?? '';

  return (
    <Box>
      <Box p={3}>
        <Typography variant={'h6'}>Node {nodeNumber}</Typography>
        {nodeUrl && (
          <Box display={'flex'}>
            <CopyToClipboardText text={nodeUrl} label="Endpoint" sxTextField={{ mt: 3, flexGrow: 2 }} />
          </Box>
        )}
      </Box>
      <Box pl={3} pr={3} pb={3}>
        <Typography variant={'h6'}>Usage</Typography>
        <Grid container={true} spacing={3} sx={{ p: 3 }} alignItems={'center'}>
          <Grid item={true} lg={5} md={6} xs={12}>
            <NodeUsageSummaryChart data={data.series} />
          </Grid>
          <Grid item={true} lg={7} md={6} xs={12}>
            <TableContainer>
              <Table aria-label="cluster metrics graph">
                <TableHead sx={{ backgroundColor: theme.palette.background.paper }}>
                  <TableRow sx={{ borderBottom: 1 }}>
                    <TableCell>Type</TableCell>
                    <TableCell align="left">Used</TableCell>
                    <TableCell align="left">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.series.map((item) => (
                    <TableRow key={item.label} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        <Box display="flex" alignItems="center">
                          <Box
                            sx={{
                              border: 3,
                              borderColor: item.color,
                              borderRadius: '50%',
                              height: 16,
                              mr: 1,
                              width: 16,
                            }}
                          />
                          <Typography color="textSecondary" variant="body2">
                            {item.label}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="left">{item.formattedValue}</TableCell>
                      <TableCell align="left">{item.total}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
