import { CircularProgress, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { MouseEvent } from 'react';
import { TIME_FRAMES } from './utils';

type MetricsTimeFrameToggleGroupProps = {
  timeFrameIndex: number;
  onTimeFrameChange: (event: MouseEvent<HTMLElement>, value: number) => void;
  isFetching: boolean;
};

export function MetricsTimeFrameToggleGroup({
  timeFrameIndex,
  onTimeFrameChange,
  isFetching,
}: MetricsTimeFrameToggleGroupProps) {
  return (
    <>
      {isFetching && <CircularProgress size={15} />} &nbsp;
      <ToggleButtonGroup
        size="small"
        aria-label="Small sizes"
        value={timeFrameIndex}
        exclusive={true}
        onChange={onTimeFrameChange}
      >
        {TIME_FRAMES.map((timeFrame, index) => (
          <ToggleButton value={index} disabled={isFetching} key={index}>
            {timeFrame.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </>
  );
}
