import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  useCreateClusterBackupScheduleMutation,
  useUpdateClusterBackupScheduleMutation,
} from '../../../../services/clustersApi';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import {
  alpha,
  // eslint-disable-next-line no-restricted-imports
  useTheme,
} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { addHours, setHours, setMinutes, setSeconds, setMilliseconds, format } from 'date-fns';
import parser from 'cron-parser';
import { BACKUP_FREQUENCIES, BackupFrequencySelect } from './BackupFrequencySelect';
import { daysOfWeek, WeekDaySelect } from './WeekDaySelect';
import DayOfMonthSelect from './DayOfMonthSelect';
import { ClusterSelect } from './ClusterSelect';
import { useGetResourceOptionsQuery } from '../../../../services/bookingApi';
import { AVG_DAYS_PER_MONTH, HOURS_IN_A_DAY, MILLICENTS_TO_DOLLAR_OR_EURO_FACTOR } from '../../constants';
import { RESOURCE_TYPE_SNAPSHOT } from '../constants';
import { useSnackbar } from '../../../../hooks/use-qdrant-snackbar';
import { isClusterHybridCloud } from '../../../../utils/cluster-utils';

const SeeMoreCostInfo = ({ showMoreInfo, toggleShowMoreInfo }) => (
  <Typography color="info.main" variant="body2" sx={{ mt: 2 }}>
    {showMoreInfo && (
      <>
        Backup functionality offers localized storage for preserving data, leveraging the native snapshot features of
        your cloud service provider within the cluster. These snapshots, available in the Cloud Backups system, follow
        an incremental approach. In most cases, subsequent snapshots capture only the changes made since the last one,
        significantly reducing storage needs. For instance, consider a cluster with 5 gigabytes of data and a history of
        3 snapshots. Depending on how the data evolves between snapshots, it might require less than 15 gigabytes of
        total snapshot storage. The degree of incrementality depends on your specific cloud service provider. To
        calculate the expenses associated with these backups, we collect raw metric data from the cloud providers and
        perform calculations to determine the total snapshot size. This calculation takes into account the geographic
        region where the snapshot is stored and the monthly storage usage. The cost for Cloud Backups is assessed on a
        per-gigabyte-per-month basis. Pricing rates can vary between different cloud providers and even among regions
        within the same provider.
      </>
    )}
    <Box
      sx={{
        cursor: 'pointer',
        textDecoration: 'underline',
        ...(showMoreInfo ? { ml: 1 } : {}),
      }}
      component="span"
      onClick={toggleShowMoreInfo}
    >
      See {showMoreInfo ? 'less' : 'more'}
    </Box>
  </Typography>
);

const ONLY_NUM_REGEX = /^\d+$/;
const MAX_DAYS_OF_RETENTION = 365;

/**
 * Backup schedule form
 * @param {{
 *   schedule - existing schedule, if passed the form will send request for editing
 *   defaultCluster? cluster object from API
 *   availableClusters - list cluster objects from API
 *   accountId: string;
 *   actionCallback?: () => void;
 * }} props
 * @returns {React.ReactElement}
 */
const BackupScheduleForm = ({ schedule, defaultCluster, availableClusters, accountId, actionCallback }) => {
  // time of day to run the backup
  const DEFAULT_TIME = setMinutes(setSeconds(setMilliseconds(addHours(new Date(), 1), 0), 0), 0);
  let interval, fields, oldStartTimeHour, oldFrequency;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [seeMoreCostInfo, setSeeMoreCostInfo] = useState(false);
  const [selectedCluster, setSelectedCluster] = useState(() => {
    // When a default cluster is provided the form is fixed to that cluster so it's preselected
    if (defaultCluster) {
      return defaultCluster;
    }

    // When there is no default cluster and it's an edition (schedule is provided) we preselect the schedule's cluster.
    if (schedule && availableClusters) {
      return availableClusters.find(({ id }) => id === schedule.cluster_id);
    }

    return undefined;
  });

  const {
    data: resourceOpts,
    isLoading: loadingResourceOpts,
    isFetching: isFetchingResourceOpts,
  } = useGetResourceOptionsQuery(
    {
      account_id: accountId,
      provider: selectedCluster?.cloud_provider,
      region: selectedCluster?.cloud_region,
      resource_type: RESOURCE_TYPE_SNAPSHOT,
    },
    { skip: !selectedCluster },
  );
  const retrievingResourceOpts = loadingResourceOpts || isFetchingResourceOpts;
  const snapshotResourceOpts = resourceOpts?.find(({ resource_type }) => resource_type === RESOURCE_TYPE_SNAPSHOT);

  /**
   * Get frequency value for the select options from cron string
   * @param {Object} fields - an object containing cron fields (dayOfMonth, dayOfWeek, hour, minute),
   *                          we get it from cron-parser, parser.parseExpression(cronString).fields
   * @returns {string} - frequency value from backupFrequency
   *
   * Fields object example:
   * {
   *  dayOfMonth: [1],
   *  dayOfWeek: [0, 1, 2, 3, 4, 5, 6, 7],
   *  hour: [12],
   *  <...>
   *  }
   *  If value is 0 it means it's not set
   *  So we can check the length of the arrays to determine the frequency
   */
  const getFrequencyFromCron = (fields) => {
    // if we have only one value in the dayOfMonth array
    // it means it will run only once a month,
    // and we don't need to check other fields to predict the frequency
    if (fields.dayOfMonth.length === 1) {
      return 'Monthly';
    }
    // if we have only one value in the dayOfWeek array
    // it means it will run only once a week
    if (fields.dayOfWeek.length === 1) {
      return 'Weekly';
    }
    // if we have 8 values in the dayOfWeek array
    // it means it will run every day
    if (fields.dayOfWeek.length === 8) {
      return 'Daily';
    }
    // in case we have a cron string that doesn't match any of the above
    // it means we have a custom cron string created not from the UI,
    // so we will show the user default value in the dropdown
    return 'every day';
  };

  /**
   * Get day of week value for the initial state
   * @type {function(*, *, null=): *}
   */
  const getInitialDayOfWeekValue = function () {
    return schedule ? daysOfWeek.find((d) => d.cronValue === fields.dayOfWeek[0]).value : daysOfWeek[1].value;
  };

  /**
   * This function is the opposite for getFrequencyFromCron
   * @param {string} frequency - string, one of backupFrequency values ('Monthly', 'Weekly', 'Daily')
   * @param {Date} startTime - start backup hour
   * @param {string} dayOfWeek - day of week, one of daysOfWeek values
   * @param {number} dayOfMonth - day of month
   * @returns {string} - crontab sting with applied frequency and start time
   */
  const buildCronString = (frequency, startTime, dayOfWeek, dayOfMonth = 1) => {
    // parseInt is used to remove leading zeros
    const hour = parseInt(format(startTime, 'HH'));
    const dayOfWeekCronValue = dayOfWeek ? daysOfWeek.find((day) => day.value === dayOfWeek).cronValue : 0;

    switch (frequency) {
      case 'Daily':
        return `0 ${hour} * * *`;
      case 'Weekly':
        return `0 ${hour} * * ${dayOfWeekCronValue}`;
      case 'Monthly':
        return `0 ${hour} ${dayOfMonth} * *`;
      default:
        return `0 ${hour} * * *`;
    }
  };

  // if it is editing an existing schedule
  if (schedule) {
    interval = parser.parseExpression(schedule.cron);
    // deep copy of the fields object
    fields = structuredClone(interval.fields);

    oldStartTimeHour = setHours(new Date(DEFAULT_TIME), fields.hour[0]);
    oldFrequency = getFrequencyFromCron(fields);
  }

  const [startTime, setStartTime] = useState(schedule ? oldStartTimeHour : DEFAULT_TIME);
  const [daysOfRetentionInput, setDaysOfRetentionInput] = useState(
    schedule?.retention ? schedule.retention.toString() : '30',
  );
  const [frequency, setFrequency] = useState(schedule ? oldFrequency : BACKUP_FREQUENCIES.DAILY);
  const [dayOfWeek, setDayOfWeek] = useState(getInitialDayOfWeekValue);
  const [dayOfMonth, setDayOfMonth] = useState(schedule ? fields.dayOfMonth[0] : 1);

  const daysOfRetention = parseInt(daysOfRetentionInput);
  const isRetentionValid =
    ONLY_NUM_REGEX.test(daysOfRetentionInput) && daysOfRetention > 0 && daysOfRetention <= MAX_DAYS_OF_RETENTION;
  const isFormValid = isRetentionValid && startTime && frequency;

  const [createClusterBackupSchedule, { isLoading: createIsLoading }] = useCreateClusterBackupScheduleMutation();
  const [updateClusterBackupSchedule, { isLoading: updateIsLoading }] = useUpdateClusterBackupScheduleMutation();

  /**
   * Handle form submit, will create or update a schedule and show a snackbar
   * @returns {Promise<void>}
   */
  const handleSubmit = async () => {
    const cron = buildCronString(frequency, startTime, dayOfWeek, dayOfMonth);
    const newScheduleObj = {
      cron,
      retention: daysOfRetention,
    };

    try {
      // when editing an existing schedule
      if (schedule) {
        await updateClusterBackupSchedule({
          clusterId: selectedCluster.id,
          accountId,
          scheduleId: schedule.id,
          ...newScheduleObj,
        }).unwrap();
        // when creating a new schedule
      } else {
        await createClusterBackupSchedule({
          clusterId: selectedCluster.id,
          accountId,
          ...newScheduleObj,
        }).unwrap();
      }

      // show snackbar with success message
      enqueueSnackbar(`Backup schedule ${schedule ? 'updated' : 'created'}`, {
        variant: 'success',
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)}>
            <Close />
          </IconButton>
        ),
      });

      if (actionCallback) {
        actionCallback();
      }
    } catch (error) {
      if (window.__QDRANT_CLOUD__.env !== 'production') {
        console.warn(error);
      }
      enqueueSnackbar(`There was an error ${schedule ? 'updating' : 'creating'} the schedule`, { variant: 'error' });
    }
  };

  const decreasingRetention = schedule && isRetentionValid && parseInt(daysOfRetentionInput) < schedule.retention;

  return (
    <form onSubmit={(event) => event.preventDefault()}>
      <div>
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          Set up Backup Schedule
        </Typography>
        <Typography color="textSecondary" sx={{ py: 2 }} variant="body2">
          Setting a backup schedule will enable backups for your cluster.
        </Typography>

        {(!selectedCluster || !isClusterHybridCloud(selectedCluster)) && (
          <Box
            sx={{
              backgroundColor: (theme) => alpha(theme.palette.info.main, 0.08),
              py: 2,
              px: 3,
              mb: 3,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
              }}
            >
              <Typography color="info.main" variant="body2">
                {(!selectedCluster || !snapshotResourceOpts?.unit_int_price_per_hour) &&
                  'Scheduling backups will cause additional charges to your account ' +
                    'that depend on the cloud provider and region.'}
                {snapshotResourceOpts?.unit_int_price_per_hour &&
                  `Based on ${
                    defaultCluster || schedule ? 'this' : 'the selected'
                  } cluster's cloud provider and region, the backup storage price is $${(
                    (snapshotResourceOpts.unit_int_price_per_hour / MILLICENTS_TO_DOLLAR_OR_EURO_FACTOR) *
                    AVG_DAYS_PER_MONTH *
                    HOURS_IN_A_DAY
                  ).toFixed(5)} per GB per month (estimating 30.5 days a month).`}
              </Typography>
              {retrievingResourceOpts && <CircularProgress sx={{ ml: 1 }} size={20} />}
            </Box>
            <SeeMoreCostInfo
              showMoreInfo={seeMoreCostInfo}
              toggleShowMoreInfo={() => setSeeMoreCostInfo((current) => !current)}
            />
          </Box>
        )}

        <Box sx={{ mb: 3 }}></Box>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12} md={6}>
            <ClusterSelect
              selectedCluster={selectedCluster}
              setCluster={setSelectedCluster}
              availableClusters={availableClusters ?? [defaultCluster]}
              disabled={Boolean(defaultCluster) || Boolean(schedule)}
            />
          </Grid>
          <Grid item={true} xs={12} md={frequency === 'Daily' ? 6 : 3}>
            <BackupFrequencySelect frequency={frequency} setFrequency={setFrequency} />
          </Grid>
          {frequency === 'Weekly' && (
            <Grid item={true} xs={12} md={3}>
              <WeekDaySelect dayOfWeek={dayOfWeek} setDayOfWeek={setDayOfWeek} />
            </Grid>
          )}
          {frequency === 'Monthly' && (
            <Grid item={true} xs={12} md={3}>
              <DayOfMonthSelect dayOfMonth={dayOfMonth} setDayOfMonth={setDayOfMonth} />
            </Grid>
          )}
          <Grid item={true} xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileTimePicker
                views={['hours']}
                label="Start Time (UTC)"
                onChange={(newDate) => {
                  setStartTime(newDate);
                }}
                value={startTime}
                sx={{ width: '100%', cursor: 'pointer' }}
                slotProps={{
                  textField: {
                    'data-testid': 'schedule-timepicker',
                  },
                  mobilePaper: {
                    'data-testid': 'schedule-timepicker-dialog',
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item={true} xs={12} md={6}>
            <TextField
              fullWidth={true}
              type={'number'}
              label="Days of Retention"
              name="daysOfRetention"
              className={decreasingRetention ? 'retention-warning' : undefined}
              sx={{
                '&.retention-warning .Mui-error': {
                  color: (theme) => theme.palette.warning.main,
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: (theme) => theme.palette.warning.main,
                  },
                  '& input': {
                    color: (theme) => theme.palette.text.primary,
                  },
                },
              }}
              onChange={(event) => setDaysOfRetentionInput(event.target.value)}
              value={daysOfRetentionInput}
              inputProps={{
                min: 1,
                max: MAX_DAYS_OF_RETENTION,
                'data-testid': 'schedule-retention',
              }}
              required={true}
              error={!isRetentionValid || decreasingRetention}
              helperText={
                !isRetentionValid
                  ? `Must be a round number between 1 and ${MAX_DAYS_OF_RETENTION}`
                  : decreasingRetention
                    ? 'Decreasing retention days could lead to deleting older backups'
                    : ''
              }
            />
          </Grid>
        </Grid>
      </div>
      <Box
        sx={{
          display: 'flex',
          mt: 2,
        }}
      >
        <Box sx={{ flexGrow: 1 }} />
        <Button
          color="primary"
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          disabled={!isFormValid || createIsLoading || updateIsLoading || !selectedCluster || retrievingResourceOpts}
          startIcon={
            createIsLoading || updateIsLoading ? (
              <CircularProgress size={20} color={'background'} />
            ) : (
              <AccessTimeIcon fontSize="small" />
            )
          }
        >
          Set Backup Schedule
        </Button>
      </Box>
    </form>
  );
};

/**
 * BackupScheduleFormDialog component, a dialog wrapper for BackupScheduleForm
 * @param props {{
 *    schedule: - schedule object {cron, retention}, if null will create a new schedule
 *    defaultCluster
 *    availableClusters
 *    accountId
 *    open
 *    setOpen
 * }}
 * @returns {React.ReactElement}
 */
const BackupScheduleFormDialog = ({ schedule, defaultCluster, availableClusters, accountId, open, onClose }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

  return (
    <Dialog open={open} onClose={onClose} fullScreen={fullScreen} fullWidth={true} maxWidth={'lg'}>
      <DialogContent>
        <BackupScheduleForm
          schedule={schedule}
          defaultCluster={defaultCluster}
          availableClusters={availableClusters}
          accountId={accountId}
          actionCallback={onClose}
        />
      </DialogContent>
    </Dialog>
  );
};

// props validation for BackupScheduleForm
BackupScheduleForm.propTypes = {
  schedule: PropTypes.object,
  defaultCluster: PropTypes.object,
  availableClusters: PropTypes.array,
  accountId: PropTypes.string.isRequired,
  actionCallback: PropTypes.func,
};

// props validation for BackupScheduleFormDialog
BackupScheduleFormDialog.propTypes = {
  schedule: PropTypes.object,
  availableClusters: PropTypes.array,
  defaultCluster: PropTypes.object,
  accountId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { BackupScheduleForm, BackupScheduleFormDialog };
