import { ComponentSchema } from '../api-schema-utils';

export type AccountPrivilege = NonNullable<ComponentSchema<'UserAccountOut'>['privileges']>[number];

export const ACCOUNT_PRIVILEGES: Record<string, AccountPrivilege> = {
  PRIVATE_REGION: 'PRIVATE_REGION',
  MAX_NUM_CLUSTERS_20: 'MAX_NUM_CLUSTERS_20',
  MAX_NUM_CLUSTERS_30: 'MAX_NUM_CLUSTERS_30',
  MAX_NUM_CLUSTERS_50: 'MAX_NUM_CLUSTERS_50',
  PROGRAMMATIC_ACCESS: 'PROGRAMMATIC_ACCESS',
} as const;
