import NodesIcon from '@mui/icons-material/AccountTree';
import { Box, Typography } from '@mui/material';
import { formatResourcesOptions, getResourceIcon, getResourceTotal } from './helpers';
import { ResourceType } from '../../utils/booking-utils';
import { Cluster } from '../../utils/cluster-utils';

type ClusterResourceType = Exclude<ResourceType, 'snapshot' | 'complimentary_disk'>;

export const ClusterConfigurationWidget = ({ cluster }: { cluster: Cluster }) => (
  <>
    <Box display="flex" alignItems="center" mb={1} key="nodes">
      <NodesIcon color="secondary" sx={{ verticalAlign: 'bottom', width: '0.7em', height: '0.7em' }} /> &nbsp;
      <Typography component={'span'} variant={'body2'} color="textSecondary">
        {cluster.configuration?.num_nodes} {cluster.configuration?.num_nodes === 1 ? 'NODE' : 'NODES'}
      </Typography>
    </Box>
    {cluster.resources &&
      Object.keys(cluster.resources).map((resourceType) => {
        const resourceInfo = cluster.resources?.[resourceType as ClusterResourceType];
        const resourceTotal = resourceInfo ? getResourceTotal(resourceInfo) : 0;
        return (
          <Box display="flex" alignItems="center" mb={1} key={resourceType}>
            {getResourceIcon(resourceType as ClusterResourceType)}&nbsp;
            <Typography component={'span'} variant={'body2'} color="textSecondary">
              {formatResourcesOptions(
                resourceTotal,
                resourceType as ClusterResourceType,
                cluster.configuration?.num_nodes,
              )}
            </Typography>
          </Box>
        );
      })}
  </>
);
