import { Suspense, lazy } from 'react';
import { StripePaymentInformation } from './PaymentInformationForm';

const LazyComponent = lazy(() =>
  import(/* webpackChunkName: "payment-information-form" */ './PaymentInformationForm').then(
    ({ PaymentInformationForm }) => ({
      default: PaymentInformationForm,
    }),
  ),
);

export const LazyPaymentInformationForm = (props: {
  accountId: string;
  paymentInformation: StripePaymentInformation;
}) => (
  <Suspense>
    <LazyComponent {...props} />
  </Suspense>
);
