import { styled } from '@mui/material';
import { ReactNode, useCallback, useState } from 'react';
import { AuthenticatedDashboardNavbar, AuthenticatedDashboardAppBar } from './AuthenticatedDashboardNavbar';
import { AuthenticatedDashboardSidebar } from './AuthenticatedDashboardSidebar';
import { AuthenticatedErrorBoundary } from './AuthenticatedErrorBoundary';

export const DASHBOARD_LAYOUT_GAP = '64px';

const AuthenticatedDashboardLayout = styled('div', {
  shouldForwardProp: (propName) => propName !== 'noSidebar',
})<{ noSidebar?: boolean }>(({ theme, noSidebar }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: DASHBOARD_LAYOUT_GAP,
  ...(!noSidebar && {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 280,
    },
  }),
}));

const AuthenticatedDashboardContent = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%',
});

export const AuthenticatedDashboardEmpty = ({ children }: { children?: ReactNode }) => (
  <AuthenticatedDashboardLayout noSidebar={true}>
    <AuthenticatedDashboardContent>{children}</AuthenticatedDashboardContent>
    <AuthenticatedDashboardAppBar noSidebar={true} />
  </AuthenticatedDashboardLayout>
);

/**
 * Each authenticated dashboard page should be wrapped in this component.
 * It provides the layout and the sidebar. An error boundary is also provided for the children.
 */
export const AuthenticatedDashboard = ({ children }: { children: ReactNode }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const onOpenSidebar = useCallback(() => {
    setIsSidebarOpen(true);
  }, []);
  const onCloseSidebar = useCallback(() => {
    setIsSidebarOpen(false);
  }, []);

  return (
    <>
      <AuthenticatedDashboardLayout>
        <AuthenticatedDashboardContent>
          <AuthenticatedErrorBoundary>{children}</AuthenticatedErrorBoundary>
        </AuthenticatedDashboardContent>
      </AuthenticatedDashboardLayout>
      <AuthenticatedDashboardNavbar onOpenSidebar={onOpenSidebar} />
      <AuthenticatedDashboardSidebar onClose={onCloseSidebar} open={isSidebarOpen} />
    </>
  );
};
