import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Cluster } from '../../services/clustersApi';
import { ClusterQueryCodeExample } from '../Clusters/ClusterQueryCodeExample';
import { CopyToClipboardText } from '../Common/CopyToClipboardText';

type Props = {
  apiKey: string;
  onClose: () => void;
  fullScreen: boolean;
  selectedClusters: Cluster[];
};

export const NewApiKeyCodeSnippet = ({ apiKey, fullScreen, onClose, selectedClusters }: Props) => (
  <Dialog
    className="_lr-hide"
    open={true}
    onClose={(_, reason) => {
      if (reason === 'backdropClick') {
        onClose();
      }
    }}
    fullWidth={true}
    maxWidth="lg"
    fullScreen={fullScreen}
  >
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <DialogTitle variant="h4" color={(theme) => theme.palette.secondary.main} mt={2}>
        Your API key
      </DialogTitle>
      <DialogActions sx={{ display: fullScreen ? 'block' : 'none' }}>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Box>
    <DialogContent>
      <Box>
        <Box pl={1} pt={2} mb={3}>
          <Typography component={'code'} variant={'body2'}>
            This key gives access to all your clusters, so please make sure to store it safely and do not embed it in
            client applications directly.
          </Typography>
        </Box>

        <Alert variant={'outlined'} severity="error">
          <Typography variant={'body2'}>
            This token can't be accessed any more in full length after closing this dialog
          </Typography>
        </Alert>

        <Box pb={1} mt={3}>
          <Box pl={1} width={'100%'} p={1} display={'flex'}>
            <CopyToClipboardText text={apiKey} useCodeStyle={true} />
          </Box>
        </Box>
        <Box px={0} mx={-2} mt={2}>
          <ClusterQueryCodeExample token={apiKey} clusters={selectedClusters} />
        </Box>
      </Box>
    </DialogContent>
  </Dialog>
);
